import React, { useContext, useEffect, useState } from 'react';
import Filters from 'src/components/form/search/filters-panel/filters';
import Filter from 'src/components/form/search/filters-panel/filter';
import PageHeader from 'src/components/page-header/page-header';
import Cards from 'src/components/form/search/cards/cards';
import PaginatedFooter from 'src/components/paginated-footer/paginated-footer';
import EmptyPlaceholder from './empty-placeholder';
import Vacancy from './vacancy';
import SearchForm from './form/form';
import { WORK_EMPLOYMENT_TYPE } from 'src/constants/filters';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { fireEvent } from 'src/utils/analytics/google';
import { openInNewTab, getNameById, filterValuesById } from 'src/utils/helper';
import * as p from 'src/constants/page-classnames';
import ToggleInline from 'src/components/toggle/toggle-inline';
import css from './job-search.module.scss';
import API from './api/api';

export default function JobSearch() {
  const { msg } = useContext(LanguageSettingsContext);
  const skeletons = [...Array(6).keys()].map((i) => <span key={i} className={css.skeleton} />);
  const [filterValues, setFilterValues] = useState({
    text: '',
    locationId: '',
    employmentTypes: [],
    salary: null,
    showMilitaryVacancies: false
  });
  const [selectedPage, setSelectedPage] = useState(1);
  const [vacancies, isLoading] = useJobSearch(filterValues, selectedPage);

  const showFilters = filterValues.employmentTypes?.length > 0 || !!filterValues.salary;
  const hidePaginatedFooter =
    vacancies.items.length === 0 || vacancies.totalCount === 0 || vacancies.items.length === vacancies.totalCount;

  function handleVacancyClick(vacancy) {
    fireEvent('view_vacancy', {
      title: vacancy.title,
      url: vacancy.url
    });
    openInNewTab(vacancy.url);
  }

  function updateFilterValues(data) {
    setFilterValues({ ...filterValues, ...data });
    setSelectedPage(1);
  }

  return (
    <main className={css.jobSearch}>
      <PageHeader
        className={p.WORK_SEARCH}
        title={msg('workSearchPageTitle')}
        description={msg('workSearchPageSubtitle')}>
        <div className={css.header}>
          <SearchForm
            initialValues={filterValues}
            onReset={() => updateFilterValues({ employmentTypes: [], salary: null })}
            onSubmit={updateFilterValues}
          />
        </div>
      </PageHeader>
      <div className={css.body}>
        {showFilters && (
          <Filters onReset={() => updateFilterValues({ employmentTypes: [], salary: null })}>
            {filterValues.employmentTypes.map((t) => (
              <Filter
                key={`employmentTypes-${t}`}
                name={msg(getNameById(WORK_EMPLOYMENT_TYPE, t))}
                onRemove={() =>
                  updateFilterValues({ employmentTypes: filterValuesById(filterValues.employmentTypes, t) })
                }
              />
            ))}
            {filterValues.salary && (
              <Filter
                key='salary'
                name={`${msg('salaryFilterCurrencyPrefix')} ${filterValues.salary} ${msg('salaryFilterCurrency')}`}
                onRemove={() => updateFilterValues({ salary: null })}
              />
            )}
          </Filters>
        )}
        <div className={css.vacanciesContainer}>
          <ToggleInline
            labelLeft={msg('toggleCivil')}
            labelRight={msg('toggleMilitary')}
            onToggle={(showMilitaryVacancies) =>
              updateFilterValues({ showMilitaryVacancies, employmentTypes: [], salary: null, locationId: '' })
            }
          />
          <Cards className={css.vacancies} loading={isLoading}>
            {!isLoading && vacancies.items.length === 0 && <EmptyPlaceholder />}
            {vacancies.items.map((vacancy, i) => (
              <Vacancy key={i} vacancy={vacancy} isLoading={isLoading} onClick={() => handleVacancyClick(vacancy)} />
            ))}
            {isLoading && skeletons}
            {!hidePaginatedFooter && (
              <div className={css.pagerRow}>
                <PaginatedFooter
                  caption={msg('workSearchPagePaginationMsg')}
                  onClick={() => setSelectedPage(selectedPage + 1)}
                />
              </div>
            )}
          </Cards>
        </div>
      </div>
    </main>
  );
}

function useJobSearch(filters, selectedPage) {
  const pageLimit = 6;
  const [vacancies, setVacancies] = useState({
    totalCount: 0,
    items: []
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const converted = {
      text: filters.text,
      employmentTypes: filters?.employmentTypes?.map((v) => parseInt(v)),
      showMilitaryVacancies: filters.showMilitaryVacancies,
      salary: filters.salary,
      locationId: filters.locationId
    };

    fireEvent('work_form_submit', {
      ...converted,
      employmentTypes: converted.employmentTypes
        ?.map((v) => WORK_EMPLOYMENT_TYPE.find((c) => c.id === v)?.name)
        .join(', ')
    });

    setIsLoading(true);
    API.search(selectedPage, pageLimit, converted)
      .then((response) => {
        setVacancies((prev) => {
          if (selectedPage > 1) {
            const urls = new Set(prev.items.map((v) => v.url));
            return {
              totalCount: response.totalCount,
              items: [...prev.items, ...response.items.filter((v) => !urls.has(v.url))]
            };
          }

          return response;
        });
      })
      .finally(() => setIsLoading(false));
  }, [filters, selectedPage]);

  return [vacancies, isLoading];
}
