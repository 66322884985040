import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmptySearchPlaceholder } from 'src/assets/empty-search-placeholder.svg';
import css from './empty-placeholder.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';

EmptyPlaceholder.propTypes = {
  title: PropTypes.string.isRequired
};

export default function EmptyPlaceholder({ title }) {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.emptySearchPlaceholder}>
      <div className={css.textGroup}>
        <p>{title}</p>
        <ul>
          <li>{msg('globalSearchEmptyPlaceholderMsg1')}</li>
          <li>{msg('globalSearchEmptyPlaceholderMsg2')}</li>
        </ul>
      </div>
      <EmptySearchPlaceholder />
    </div>
  );
}
