import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function BezoplatnePokhovanniaPomerlykhZahyblykhVeteranivViinyZakhysnykivIZakhysnytsUkrainy() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>
        Безоплатне поховання померлих (загиблих) ветеранів війни, захисників і захисниць України, відшкодування витрат
        на встановлення надгробка
      </h3>
      <p>
        Відшкодування витрат на безоплатне поховання осіб, які мають особливі заслуги та особливі трудові заслуги перед
        Батьківщиною (за рахунок держбюджета Мінсоцполітики); учасників бойових дій, постраждалих учасників Революції
        Гідності та осіб з інвалідністю внаслідок війни (за рахунок місцевих бюджетів та місцевих програм). Покриваються
        і витрати на пам’ятник на могилі.
      </p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>Подати документи до органу соціального захисту.</p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>Сім&apos;ї померлих (загиблих) ветеранів війни, захисників і захисниць України.</p>
    </Article>
  );
}
