import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LanguageSettingsContext } from 'src/utils/language-context';
import css from './service-pagination-card.module.scss';

ServicePaginationCard.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default function ServicePaginationCard({ onClick }) {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.servicePaginationCard}>
      <button onClick={onClick}>{msg('servicesPagePaginationMsg')}</button>
    </div>
  );
}
