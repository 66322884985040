import React, { useContext, useId } from 'react';
import PropTypes from 'prop-types';
import Filters from 'src/components/form/filters/filters';
import SearchInput from 'src/components/form/search/filters-form/search-input';
import css from './business-map.module.scss';
import { searchInputValidationSchema } from 'src/components/form/search/filters-form/helper';
import { LanguageSettingsContext } from 'src/utils/language-context';

MapFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.shape({
    text: PropTypes.string
  }).isRequired
};

export default function MapFilters({ onSearch, initialFilters }) {
  const { msg } = useContext(LanguageSettingsContext);

  const inputId = useId();

  return (
    <Filters
      initialFilters={initialFilters}
      onSearch={onSearch}
      validationSchema={searchInputValidationSchema(msg, 'text')}
      fieldsToSubmitOnChange={['text']}
      render={({ errors, _, registerAndClearOnChange }) => (
        <div className={css.filtersGroup}>
          <SearchInput
            id={inputId}
            name='text'
            placeholder={msg('seachServicePlaceholder')}
            error={errors?.text?.message}
            inputProps={registerAndClearOnChange('text')}
          />
        </div>
      )}
    />
  );
}
