export const messages = {
  en: {
    complaintReportError: 'Report an error',
    complaintDescriptionPlaceholder: 'Describe the error you noticed',
    complaintSuccess: 'Complaint sent successfully',
    complaintFail: 'Something went wrong',
    complaintTypeMsg1: 'Grammatical mistakes',
    complaintTypeMsg2: 'The information is not correct',
    complaintTypeMsg3: 'Other'
  },
  default: {
    complaintReportError: 'Повідомити про помилку',
    complaintDescriptionPlaceholder: 'Опишіть зафіксовану Вами помилку',
    complaintSuccess: 'Скарга успішно відправлена',
    complaintFail: 'Щось пішло не так',
    complaintTypeMsg1: 'Граматична помилка',
    complaintTypeMsg2: 'Дані не відповідають дійсності',
    complaintTypeMsg3: 'Інше'
  }
};
