import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function ZabezpechenniaDodatkovymyZasobamyReabilitatsii() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>Забезпечення ДЗР</h3>
      <p>У межах державної програми можна безоплатно отримати:</p>
      <ul>
        <li>Протези (верхніх, нижніх кінцівок, молочної залози) та ортопедичні засоби (ортези, взуття);</li>
        <li>Допоміжні засоби для догляду, захисту та реабілітації;</li>
        <li>Крісла колісні;</li>
        <li>
          Оснащення та меблі для реабілітації (для підйому, переміщення, особистої рухомості, а також подушки
          протипролежневі);
        </li>
        <li>Компенсацію за придбані реабілітаційні засоби;</li>
        <li>Післягарантійний ремонт та техобслуговування реабілітаційних засобів.</li>
      </ul>
      <p>
        Виготовлення індивідуальних протезів та ортопедичних засобів займає від 10 до 35 робочих днів залежно від виробу
        та матеріалів. Доставка ДЗР здійснюється протягом 45 робочих днів із дня отримання підприємством бюджетних
        коштів.
      </p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>Подати заявку через</p>
      <ul>
        <li>Структурний підрозділ з питань соціального захисту населення;</li>
        <li>Центр надання адміністративних послуг;</li>
        <li>Електронний кабінет особи з інвалідністю.</li>
      </ul>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>
        Учасники війни, особи з інвалідністю внаслідок війни, сім&apos;ї померлих (загиблих) захисників і захисниць,
        постраждалі учасники Революції Гідності, вдови і вдівці ветеранів силових структур.
      </p>
    </Article>
  );
}
