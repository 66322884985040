import React, { useCallback, useContext, useEffect, useId, useRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'src/components/form/floating-label/input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import API from '../events-api';
import DateTimePicker from 'src/components/form/floating-label/date-time-picker';
import Modal from 'src/components/common/modal/modal';
import { env } from 'src/constants/env';
import { fireEvent } from 'src/utils/analytics/google';

// styles
import css from './propose-event.module.scss';
import 'src/utils/popup.scss';
import getValidationSchema from './validate';
import { toast } from 'react-toastify';
import { ONLINE } from 'src/constants/locations';
import { LanguageSettingsContext } from 'src/utils/language-context';

ProposeEventPopup.propTypes = {
  trigger: PropTypes.element
};

export default function ProposeEventPopup({ trigger }) {
  const { msg } = useContext(LanguageSettingsContext);
  const validationSchema = getValidationSchema(msg);
  const captchaRef = useRef(null);
  const registerAndClearOnChange = function (inputName) {
    return register(inputName, {
      onChange: () => {
        if (errors[inputName]) {
          clearErrors(inputName);
        }
      }
    });
  };

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      organizer: '',
      url: '',
      scheduledAt: null,
      locationId: '',
      phone: '',
      email: '',
      description: ''
    }
  });
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    control,
    trigger: triggerValidation,
    formState: { errors, isSubmitSuccessful, defaultValues }
  } = methods;
  const values = getValues();
  const isEdited = Object.keys(values).some((key) => values[key] !== defaultValues[key]);

  const resetCallback = useCallback(() => {
    return isSubmitSuccessful ? reset : () => {};
  }, [reset, isSubmitSuccessful]);

  useEffect(resetCallback, [resetCallback]);

  const onFormSubmit = async (data, close) => {
    try {
      const response = await API.proposeEvent({
        ...data,
        scheduledAt: new Date(data['scheduledAt']).getTime()
      });
      if (response.status === 201) {
        toast.success(`${msg('proposeEventSuccess')} ${msg('submitSuccess')}`);
        reset();
        close();
        fireEvent('propose_event_form_submit', data);
      }
    } catch (error) {
      toast.error(msg('submitFail'));
    }
  };

  const titleInputId = useId();
  const organizerInputId = useId();
  const urlInputId = useId();
  const locationInputId = useId();
  const phoneInputId = useId();
  const emailInputId = useId();
  const descriptionInputId = useId();

  return (
    <Modal trigger={trigger} onClose={reset} nested closeOnDocumentClick={!isEdited}>
      {(close) => (
        <div className={css.proposeEvent}>
          <button className={css.close} onClick={close}>
            &times;
          </button>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(async (data) => {
                const token = await captchaRef.current?.executeAsync();
                onFormSubmit({ ...data, captcha: token }, close);
              })}>
              <legend>{msg('proposeEventTitle')}</legend>
              <fieldset>
                <Input
                  label={msg('proposeEventTitlePlaceholder')}
                  id={titleInputId}
                  name='title'
                  type='text'
                  error={errors?.title?.message}
                  required
                  {...registerAndClearOnChange('title')}
                />
                <Input
                  label={msg('eventOrganiserPlaceholder')}
                  id={organizerInputId}
                  name='organizer'
                  type='text'
                  error={errors?.organizer?.message}
                  required
                  {...registerAndClearOnChange('organizer')}
                />
                <Input
                  label={msg('proposeEventUrlPlaceholder')}
                  id={urlInputId}
                  name='url'
                  type='text'
                  error={errors?.url?.message}
                  required
                  {...registerAndClearOnChange('url')}
                />
                <div className={css.group}>
                  <DateTimePicker control={control} triggerValidation={triggerValidation} required name='scheduledAt' />
                  <Input
                    label={msg('formLocationPlaceholder')}
                    id={locationInputId}
                    name='locationId'
                    triggerValidation={triggerValidation}
                    clearErrors={clearErrors}
                    error={errors?.locationId?.message}
                    defaultLocation={ONLINE}
                    required
                    searchSelect
                  />
                </div>
                <div className={css.group}>
                  <Input
                    label={msg('formPhonePlaceholder')}
                    id={phoneInputId}
                    name='phone'
                    type='phone'
                    error={errors?.phone?.message}
                    required
                    {...registerAndClearOnChange('phone')}
                  />
                  <Input
                    label={msg('formEmailPlaceholder')}
                    id={emailInputId}
                    name='email'
                    type='email'
                    error={errors?.email?.message}
                    required
                    {...registerAndClearOnChange('email')}
                  />
                </div>
                <Input
                  label={msg('formDescriptionPlaceholder')}
                  id={descriptionInputId}
                  name='description'
                  multiline
                  error={errors?.description?.message}
                  {...registerAndClearOnChange('description')}
                />
              </fieldset>
              <button type='submit'>{msg('formSubmitCaption')}</button>
            </form>
            <ReCAPTCHA
              ref={captchaRef}
              className={css.captcha}
              sitekey={env.REACT_APP_OBRII_RECAPTCHA_SITE_KEY}
              hl='uk'
              size='invisible'
            />
          </FormProvider>
        </div>
      )}
    </Modal>
  );
}
