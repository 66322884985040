import React from 'react';
import PropTypes from 'prop-types';
import css from './banner.module.scss';
import classNames from 'classnames';

const bannerType = {
  about: 'about',
  general: 'general',
  generalUsers: 'generalUsers',
  generalPartners: 'generalPartners'
};

Banner.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.oneOf(Object.keys(bannerType)).isRequired
};

export { bannerType };
export default function Banner({ children, backgroundImage }) {
  return (
    <div className={classNames(css.banner, css[backgroundImage])}>
      <div className={css.mobileImg} />
      <div className={css.container}>
        <div className={css.textGroup}>{children}</div>
      </div>
    </div>
  );
}
