import * as yup from 'yup';
import * as v from 'src/utils/form/validate';

const maxNameLength = 100;
const maxDescriptionLength = 2000;

export default function getValidationSchema(msg) {
  const validationSchema = yup.object({
    title: v.validateName(yup, msg, msg('formValidationTitleMsg2'), maxNameLength),
    organizer: v.validateName(yup, msg, msg('eventOrganiserPlaceholder'), maxNameLength),
    url: v.validateUrl(yup, msg),
    scheduledAt: v.validateDateTime(yup, msg),
    locationId: yup.string().required(msg('formValidationRequired')),
    phone: v.validatePhone(yup, msg),
    email: v.validateEmail(yup, msg),
    description: v.validateDescription(yup, msg, msg('formValidationDescriptionMsg2'), maxDescriptionLength, false)
  });

  return validationSchema;
}
