import { env } from 'src/constants/env';
const base = 'https://www.googletagmanager.com/gtm.js?id=';

function start() {
  configure(env.REACT_APP_OBRII_GA_MEASUREMENT_ID);
  updateDom(base + env.REACT_APP_OBRII_GA_TRACKING_CODE);
}

function fireEvent(eventName, eventModel) {
  const gtag = getGtag();

  gtag('event', eventName, eventModel);
}

function configure(measurementId) {
  const gtag = getGtag();

  gtag('js', new Date());
  gtag('config', measurementId);
}

function updateDom(url) {
  const gtag = getGtag();

  gtag({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });

  const s = document.createElement('script');
  s.async = true;
  s.src = url;

  document.head.appendChild(s);
}

function getGtag() {
  window.dataLayer = window.dataLayer ?? [];

  return function gtag() {
    window.dataLayer.push(arguments);
  };
}

export default start;
export { fireEvent };
