import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';

export const locationsApiUrl = 'Locations';

function GetLocationsRequest(limit = 6, search = null) {
  return obriyApiAxios.get(locationsApiUrl, { params: { search, limit } }).then((response) => response.data);
}

const API = {
  getLocations: GetLocationsRequest
};

export default API;
