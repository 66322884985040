import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import css from './filters.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';

Filters.propTypes = {
  children: PropTypes.node.isRequired,
  onReset: PropTypes.func
};

export default function Filters({ children, onReset }) {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.filters} role='group'>
      {children}
      <div className={css.resetButton} onClick={() => onReset()}>
        {msg('workSearchPageResetFilters')}
      </div>
    </div>
  );
}
