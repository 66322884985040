import React from 'react';
import css from './uaf-pattern.module.scss';
import { ReactComponent as Pattern } from 'src/assets/uaf-pattern.svg';

export default function UafPattern() {
  return (
    <div className={css.uafPattern}>
      <div className={css.container}>
        <Pattern />
      </div>
    </div>
  );
}
