import React, { useState, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Obriy } from 'src/assets/navbar_obriy_logo.svg';
import { ReactComponent as ObriyEn } from 'src/assets/navbar_obriy_logo_en.svg';

import { ReactComponent as Work } from 'src/assets/services/work.svg';
import { ReactComponent as WorkActive } from 'src/assets/services/work-active.svg';
import { ReactComponent as Education } from 'src/assets/services/education.svg';
import { ReactComponent as EducationActive } from 'src/assets/services/education-active.svg';
import { ReactComponent as Psych } from 'src/assets/services/psych.svg';
import { ReactComponent as PsychActive } from 'src/assets/services/psych-active.svg';
import { ReactComponent as Med } from 'src/assets/services/med.svg';
import { ReactComponent as MedActive } from 'src/assets/services/med-active.svg';
import { ReactComponent as Legal } from 'src/assets/services/legal.svg';
import { ReactComponent as LegalActive } from 'src/assets/services/legal-active.svg';
import { ReactComponent as Info } from 'src/assets/services/info.svg';
import { ReactComponent as InfoActive } from 'src/assets/services/info-active.svg';
import { ReactComponent as Event } from 'src/assets/services/event.svg';
import { ReactComponent as EventActive } from 'src/assets/services/event-active.svg';
import { ReactComponent as BusinessMap } from 'src/assets/services/map.svg';
import { ReactComponent as BusinessMapActive } from 'src/assets/services/map-active.svg';

import * as r from 'src/constants/routes';
import css from './navbar.module.scss';
import Item from 'src/components/layout/navbar/item';
import Dropdown from 'src/components/layout/navbar/dropdown';
import Hamburger from 'src/components/hamburger-menu-icon/hamburger';
import SearchInput from 'src/components/form/search-input/search-input';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { ENGLISH, UKRAINE } from 'src/constants/languages';

export default function Navbar() {
  const { language, msg, link } = useContext(LanguageSettingsContext);
  const [menuDropdownOpened, setOpenMenuDropdown] = useState(false);
  const navigate = useNavigate();
  const menuContainerRef = useRef(null);
  const route = useLocation();
  const menuItems = [
    {
      title: msg(r.WORK_SEARCH.title),
      path: link(r.WORK_SEARCH.path),
      logo: <Work className={css.item} />,
      logoActive: <WorkActive className={css.item} />
    },
    {
      title: msg(r.EDUCATION_COURSES.title),
      path: link(r.EDUCATION_COURSES.path),
      logo: <Education className={css.item} />,
      logoActive: <EducationActive className={css.item} />
    },
    {
      title: msg(r.PSYCHOLOGICAL_HELP.title),
      path: link(r.PSYCHOLOGICAL_HELP.path),
      logo: <Psych className={css.item} />,
      logoActive: <PsychActive className={css.item} />
    },
    {
      title: msg(r.MEDICAL_SERVICES.title),
      path: link(r.MEDICAL_SERVICES.path),
      logo: <Med className={css.item} />,
      logoActive: <MedActive className={css.item} />
    },
    {
      title: msg(r.LEGAL_HELP.title),
      path: link(r.LEGAL_HELP.path),
      logo: <Legal className={css.item} />,
      logoActive: <LegalActive className={css.item} />
    },
    {
      title: msg(r.USEFUL_INFO.title),
      path: link(r.USEFUL_INFO.path),
      logo: <Info className={css.item} />,
      logoActive: <InfoActive className={css.item} />
    },
    {
      title: msg(r.BUSINESS_MAP.title),
      path: link(r.BUSINESS_MAP.path),
      logo: <BusinessMap className={css.item} />,
      logoActive: <BusinessMapActive className={css.item} />
    },
    {
      title: msg(r.EVENTS.title),
      path: link(r.EVENTS.path),
      logo: <Event className={css.item} />,
      logoActive: <EventActive className={css.item} />
    }
  ];

  function handleSearch(value) {
    navigate(link(r.GLOBAL_SEARCH.path), { state: { searchValue: value } });
  }

  return (
    <nav className={css.navbar}>
      <ul>
        <Item to={link(r.GENERAL.path)} title={msg(r.GENERAL.title)} navigationItem={false}>
          {language === UKRAINE && <Obriy />}
          {language === ENGLISH && <ObriyEn />}
        </Item>
        {!route.pathname.startsWith(link(r.GLOBAL_SEARCH.path)) && (
          <li className={css.search}>
            <SearchInput
              id='navbar-search'
              name='navbar-search'
              placeholder={msg('globalSearchPlaceholder')}
              onSearch={handleSearch}
              className={css.navbarSearch}
            />
          </li>
        )}
        <Item to={link(r.WORK.path)} title={msg(r.WORK_SEARCH.title)}>
          {route.pathname.startsWith(link(r.WORK.path)) ? (
            <WorkActive className={css.item} />
          ) : (
            <Work className={css.item} />
          )}
        </Item>
        <Item to={link(r.EDUCATION.path)} title={msg(r.EDUCATION_COURSES.title)}>
          {route.pathname.startsWith(link(r.EDUCATION.path)) ? (
            <EducationActive className={css.item} />
          ) : (
            <Education className={css.item} />
          )}
        </Item>
        <Item to={link(r.PSYCHOLOGICAL_HELP.path)} title={msg(r.PSYCHOLOGICAL_HELP.title)}>
          {route.pathname.startsWith(link(r.PSYCHOLOGICAL_HELP.path)) ? (
            <PsychActive className={css.item} />
          ) : (
            <Psych className={css.item} />
          )}
        </Item>
        <Item to={link(r.MEDICAL_SERVICES.path)} title={msg(r.MEDICAL_SERVICES.title)}>
          {route.pathname.startsWith(link(r.MEDICAL_SERVICES.path)) ? (
            <MedActive className={css.item} />
          ) : (
            <Med className={css.item} />
          )}
        </Item>
        <Item to={link(r.LEGAL_HELP.path)} title={msg(r.LEGAL_HELP.title)}>
          {route.pathname.startsWith(link(r.LEGAL_HELP.path)) ? (
            <LegalActive className={css.item} />
          ) : (
            <Legal className={css.item} />
          )}
        </Item>
        <Item to={link(r.USEFUL_INFO.path)} title={msg(r.USEFUL_INFO.title)}>
          {route.pathname.startsWith(link(r.USEFUL_INFO.path)) ? (
            <InfoActive className={css.item} />
          ) : (
            <Info className={css.item} />
          )}
        </Item>
        <Item to={link(r.VETERAN.path)} title={msg(r.BUSINESS_MAP.title)}>
          {route.pathname.startsWith(link(r.VETERAN.path)) ? (
            <BusinessMapActive className={css.item} />
          ) : (
            <BusinessMap className={css.item} />
          )}
        </Item>
        <Item to={link(r.EVENTS.path)} title={msg(r.EVENTS.title)}>
          {route.pathname.startsWith(link(r.EVENTS.path)) ? (
            <EventActive className={css.item} />
          ) : (
            <Event className={css.item} />
          )}
        </Item>
        <li
          onMouseEnter={() => setOpenMenuDropdown(true)}
          onMouseLeave={() => setOpenMenuDropdown(false)}
          onClick={() => setOpenMenuDropdown(!menuDropdownOpened)}
          className={css.menu}
          ref={menuContainerRef}>
          <Hamburger isOpen={menuDropdownOpened} />
          <Dropdown
            items={menuItems}
            fullScreen
            isOpen={menuDropdownOpened}
            onClick={() => setOpenMenuDropdown(false)}
          />
        </li>
      </ul>
    </nav>
  );
}
