import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Marker as LeafletMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { getMapIcon } from './helper';
import MarkerPopup from './marker-popup';

Marker.propTypes = {
  data: PropTypes.shape({
    coords: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }).isRequired,
    category: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired,
    requirements: PropTypes.string.isRequired,
    url: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired
  }).isRequired,
  onPopupOpen: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired
};

export default function Marker({ data, category, onPopupOpen, onPopupClose }) {
  const markerRef = useRef();

  function handlePopupOpen() {
    if (!markerRef.current) return;
    const largeIcon = getMapIcon(markerRef.current.options.icon.options.iconUrl, 1.5);
    markerRef.current.setIcon(largeIcon);
    onPopupOpen();
  }

  function handlePopupClose() {
    if (!markerRef.current) return;
    const regularIcon = getMapIcon(markerRef.current.options.icon.options.iconUrl);
    markerRef.current.setIcon(regularIcon);
    onPopupClose();
  }

  return (
    <LeafletMarker
      ref={markerRef}
      position={[data.coords.x, data.coords.y]}
      icon={getMapIcon(category.iconUrl)}
      eventHandlers={{
        popupopen: handlePopupOpen,
        popupclose: handlePopupClose
      }}>
      <MarkerPopup data={data} category={category} />
    </LeafletMarker>
  );
}
