import PropTypes from 'prop-types';

TimeSpan.propTypes = {
  utc: PropTypes.number.isRequired
};

export default function TimeSpan({ utc }) {
  const locale = 'uk-UA';
  const localDate = new Date(utc);
  const localString = localDate.toLocaleString(locale);

  return (
    <time title={localString} dateTime={localString}>
      {localDate.toLocaleTimeString(locale, { timeStyle: 'short' })}
    </time>
  );
}
