export const FULLTIME = 0,
  PARTTIME = 1;

export const WORK_EMPLOYMENT_TYPE = [
  {
    id: FULLTIME,
    name: 'employmentTypeFilterFulltime'
  },
  {
    id: PARTTIME,
    name: 'employmentTypeFilterParttime'
  }
];

export const LOBBY_X = 0,
  JOOBLE = 1,
  WORK_UA = 2;

export const WORK_VENDORS = [
  {
    id: LOBBY_X,
    name: 'army.thelobbyx.com'
  },
  {
    id: JOOBLE,
    name: 'ua.jooble.org'
  },
  {
    id: WORK_UA,
    name: 'work.ua'
  }
];
