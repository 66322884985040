import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import './description.scss';

Description.propTypes = {
  description: PropTypes.string.isRequired
};

export default function Description({ description }) {
  const cleanContent = DOMPurify.sanitize(description, {
    ALLOWED_TAGS: ['ul', 'li', 'span', 'p', 'strong', 'em', 'a', 'br'],
    ALLOWED_ATTR: ['href', 'target', 'class'],
    SANITIZE_NAMED_PROPS: true
  });

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });

  return <div className='description' dangerouslySetInnerHTML={{ __html: cleanContent }} />;
}
