import React from 'react';
import PropTypes from 'prop-types';
import css from './paginated-footer.module.scss';

PaginatedFooter.propTypes = {
  caption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function PaginatedFooter({ caption, onClick }) {
  return (
    <div className={css.paginatedFooter}>
      <div className={css.line} />
      <button onClick={onClick}>{caption}</button>
      <div className={css.line} />
    </div>
  );
}
