import React from 'react';
import PropTypes from 'prop-types';
import css from './filter.module.scss';
import { ReactComponent as Close } from 'src/assets/close_cross.svg';

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default function Filter({ name, onRemove }) {
  return (
    <div className={css.filter}>
      <span>{name}</span>
      <div className={css.close} onClick={onRemove} aria-label='remove'>
        <Close />
      </div>
    </div>
  );
}
