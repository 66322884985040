import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';

const eventPropositionsApiUrl = '/EventPropositions';
const eventsApiUrl = '/Events';
const eventsAvailabilitiesApiUrl = `${eventsApiUrl}/availabilities`;

const ApiUrls = {
  eventsApiUrl,
  eventPropositionsApiUrl,
  eventsAvailabilitiesApiUrl
};

const PostEventPropositionRequest = (data) => obriyApiAxios.post(eventPropositionsApiUrl, data);

const GetEventsRequest = (page = 1, query = null) =>
  obriyApiAxios.get(eventsApiUrl, { params: { ...query, page } }).then((response) => response.data);
const GetEventsAvailabilitiesRequest = (data) =>
  obriyApiAxios.get(eventsAvailabilitiesApiUrl, { params: data }).then((response) => response.data);

const API = {
  proposeEvent: PostEventPropositionRequest,
  getEvents: GetEventsRequest,
  getEventsAvailabilities: GetEventsAvailabilitiesRequest
};

export { ApiUrls };
export default API;
