import React, { useId, useContext } from 'react';
import { Link } from 'react-router-dom';
import css from './partners.module.scss';
import AnimatedContainer from 'src/components/animated-container/animated-container';
import { openInNewTab } from 'src/utils/helper';
import { PARTNERS } from 'src/constants/partners';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { PARTNERSHIP } from 'src/constants/routes';

export default function Partners() {
  const partnerKey = useId();
  const { msg, link } = useContext(LanguageSettingsContext);

  return (
    <div className={css.partners}>
      <div className={css.partnersContainer}>
        <header>
          <h2 id='partners'>{msg('partnersTitle')}</h2>
        </header>
        <div className={css.container}>
          <div className={css.logos}>
            {PARTNERS.map((partner, i) => (
              <AnimatedContainer key={partnerKey + i} onClick={() => openInNewTab(partner.url)}>
                {partner.logo}
              </AnimatedContainer>
            ))}
          </div>
          <div className={css.footerContainer}>
            <Link to={link(PARTNERSHIP.path)}>{msg('partnershipRoute')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
