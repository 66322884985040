import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LanguageSettingsContext } from 'src/utils/language-context';
import * as p from 'src/constants/page-classnames';
import PaginatedFooter from 'src/components/paginated-footer/paginated-footer';
import FilterTags from 'src/components/tags/filter-tags';
import EmptyPlaceholder from './empty-placeholder';
import List from './list';
import css from './services.module.scss';
import API from './api/api';

Services.propTypes = {
  filters: PropTypes.shape({
    categoryId: PropTypes.string,
    military: PropTypes.bool,
    locationId: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  className: PropTypes.oneOf([
    p.CAREER_INFO,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.EDUCATION,
    p.VETERAN_LOCATIONS
  ]).isRequired
};

export default function Services({ filters, className }) {
  const { msg } = useContext(LanguageSettingsContext);
  const defaultTag = { id: null, name: msg('tagAllCaption') };

  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedTag, setSelectedTag] = useState(defaultTag.id);

  const skeletons = [...Array(6).keys()].map((i) => <span key={i} className={css.skeleton} />);

  const [isLoading, servicesBlocks, tags] = useServices(filters, selectedTag, selectedPage);

  function handleTagChange(tagId) {
    setSelectedTag(tagId ?? defaultTag.id);
    setSelectedPage(1);
  }

  useRestoreFilterTag(handleTagChange);

  const combinedTags = [defaultTag].concat(tags);

  const hidePaginatedFooter =
    isLoading ||
    servicesBlocks.items.length === 0 ||
    servicesBlocks.totalBlockCount === 0 ||
    servicesBlocks.items.length === servicesBlocks.totalBlockCount;

  return (
    <div className={css.services}>
      <div className={css.body}>
        {tags.length > 0 && (
          <FilterTags
            title={msg('tagsFilterTitle')}
            tags={combinedTags.map((t) => t.id)}
            selectedTag={selectedTag}
            calcName={(tagId) => combinedTags.find((t) => t.id === tagId)?.name}
            onClick={handleTagChange}
            className={className}
          />
        )}
        <List
          key={selectedTag}
          servicesLimit={5}
          servicesBlocks={servicesBlocks.items}
          className={className}
          showCardsPagination
          selectedServiceTag={selectedTag}
        />
        {!isLoading && servicesBlocks.totalServicesCount === 0 && <EmptyPlaceholder />}
        {!hidePaginatedFooter && (
          <div className={css.pagerRow}>
            <PaginatedFooter
              caption={msg('servicesPagePaginationMsg')}
              onClick={() => setSelectedPage(selectedPage + 1)}
            />
          </div>
        )}
      </div>
      {isLoading && <div className={css.skeletons}>{skeletons}</div>}
    </div>
  );
}

function useServices(filters, selectedTag, selectedPage) {
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [servicesBlocks, setServicesBlocks] = useState({
    totalServicesCount: 0,
    totalBlocksCount: 0,
    items: []
  });

  useEffect(() => {
    const data = {
      categoryId: filters.categoryId,
      military: filters.military,
      locationId: filters.locationId,
      text: filters.text
    };

    API.getTags(data).then(setTags);
  }, [filters.categoryId, filters.military, filters.locationId, filters.text]);

  useEffect(() => {
    let active = true;
    setIsLoading(true);

    const data = {
      categoryId: filters.categoryId,
      military: filters.military,
      locationId: filters.locationId,
      text: filters.text,
      tagId: selectedTag,
      limit: 6
    };

    API.getServicesBlocks(selectedPage, data).then((response) => {
      if (!active) return;

      setServicesBlocks((prev) => {
        if (selectedPage > 1) {
          const ids = new Set(prev.items.map((b) => b.id));
          return {
            totalServicesCount: response.totalServicesCount,
            totalBlockCount: response.totalBlockCount,
            items: [...prev.items, ...response.items.filter((v) => !ids.has(v.id))]
          };
        }

        return {
          totalServicesCount: response.totalServicesCount,
          totalBlockCount: response.totalBlockCount,
          items: response.items
        };
      });
      setIsLoading(false);
    });

    return () => {
      active = false;
    };
  }, [selectedTag, selectedPage, filters]);

  return [isLoading, servicesBlocks, tags];
}

function useRestoreFilterTag(setSelectedTag) {
  const page = window.location.pathname.slice(1);

  useEffect(() => {
    const prefs = JSON.parse(window.sessionStorage?.getItem('persisted-preferences'));
    if (prefs?.page !== page) {
      window.sessionStorage?.setItem(
        'persisted-preferences',
        JSON.stringify({
          filterTagId: null,
          scrollPosition: null,
          page
        })
      );

      return;
    }

    if (!prefs?.filterTagId) return;

    window.sessionStorage?.setItem(
      'persisted-preferences',
      JSON.stringify({
        ...prefs,
        filterTagId: null
      })
    );

    setSelectedTag(prefs.filterTagId);
  }, [setSelectedTag, page]);
}
