export const messages = {
  en: {
    proposeEventBtnMsg: 'Propose an event',
    proposeLocationBtnMsg: 'Propose a location',
    proposeServiceBtnMsg: 'Propose a service',
    proposePartnershipBtnMsg: 'Propose a partnership'
  },
  default: {
    proposeEventBtnMsg: 'Запропонувати подію',
    proposeLocationBtnMsg: 'Запропонувати локацію',
    proposeServiceBtnMsg: 'Запропонувати послугу',
    proposePartnershipBtnMsg: 'Запропонувати партнерство'
  }
};
