import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import css from './tooltip-wrapper.module.scss';
import { ReactComponent as TooltipArrow } from 'src/assets/tooltip-arrow.svg';
import classNames from 'classnames';
import { useOutsideClickTracker } from 'src/hooks/shared-hooks';

TooltipWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  wrapper: PropTypes.func.isRequired
};

export default function TooltipWrapper({ text, wrapper }) {
  const containerRef = useRef(null);
  const [hover, setHover] = useState(false);
  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  useOutsideClickTracker(containerRef, () => {
    if (!hover) return;

    setHover(false);
  });

  const tooltip = (
    <div className={classNames(css.tooltip, hover ? css.hover : '')} ref={containerRef}>
      <TooltipArrow />
      <label>{text}</label>
    </div>
  );

  return wrapper(tooltip, onMouseEnter, onMouseLeave);
}
