import React from 'react';
import PropTypes from 'prop-types';
import css from './hamburger.module.scss';
import classNames from 'classnames';

Hamburger.propTypes = {
  isOpen: PropTypes.bool
};

export default function Hamburger({ isOpen = false }) {
  return (
    <div className={classNames(css.hamburger, isOpen ? css.open : css.close)}>
      <span />
      <span />
      <span />
    </div>
  );
}
