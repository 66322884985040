import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function PilhyNaOplatuZhkpPrydbanniaTverdohoPalyvaTaSkraplenohoHazu() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>Пільги на оплату ЖКП, придбання твердого палива та скрапленого газу</h3>
      <p>
        Розмір пільг для комунальних послуг визначається на основі загальної знижки, кількості членів сім’ї, які мають
        право на пільги, та актуальних тарифів. Для пільг на тверде паливо і газ враховується знижка (законодавчо
        встановлена, з урахуванням мінімальних норм забезпечення твердим паливом і скрапленим газом та граничних
        показників їх вартості).
      </p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>
        Потрібно звернутися до Пенсійного фонду, місцевих рад або ЦНАПу з заявою про отримання пільг на оплату ЖКП,
        придбання твердого палива і скрапленого газу (може знадобитися довідка про наявність пічного опалення та/або
        кухонного вогнища на твердому паливі).
      </p>
      <p>
        Якщо є обставини, які впливають на розмір пільг та загалом їх призначення (наприклад, зміна складу членів сім’ї
        або категорії пільговика; переліку ЖКП, умов їх надання тощо), треба подати нову заяву до Пенсійного фонду
        протягом 30 днів.
      </p>
      <p>
        Уповноважені особи місцевої ради або військової адміністрації та ЦНАПів передають заяви та документи Пенсійному
        фонду України, який оновлює базу даних про пільговиків в ЄІССС.
      </p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>
        Ті, хто перебуває на обліку в Єдиному державному автоматизованому реєстрі осіб, які мають право на пільги, або
        їх законні представники.
      </p>
    </Article>
  );
}
