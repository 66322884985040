import React from 'react';
import PropTypes from 'prop-types';
import css from './card.module.scss';
import classNames from 'classnames';

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hoverBorder: PropTypes.oneOf(['left', 'bottom']),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default function Card({ children, className, onClick, hoverBorder, onMouseEnter, onMouseLeave }) {
  const borderHovered = hoverBorder === 'left' ? css.hoverLeft : css.hoverBottom;

  return (
    <div
      className={classNames(css.card, borderHovered, className)}
      onClick={onClick}
      role='listitem'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {children}
    </div>
  );
}
