import { React, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import css from './input.module.scss';
import classNames from 'classnames';
import LocationsInput from '../search-select-input/locations-input';
import { useFormContext } from 'react-hook-form';

const Input = forwardRef(function Input(props, ref) {
  const {
    id,
    name,
    label,
    value: valueFromProps,
    onChange: onChangeFromProps,
    onFocus: onFocusFromProps,
    onBlur: onBlurFromProps,
    error,
    multiline = false,
    searchSelect = false,
    className,
    placeholder,
    ...otherProps
  } = props;

  const isControlled = typeof valueFromProps != 'undefined';
  const hasError = !!error;
  const [isFocused, setIsFocused] = useState(false);
  const { watch } = useFormContext();
  const watchedValue = watch(name);
  const [inputValue, setInputValue] = useState('');

  const onFocus = (e) => {
    setIsFocused(true);
    onFocusFromProps?.(e);
  };

  const onBlur = (e) => {
    setIsFocused(false);
    onBlurFromProps?.(e);
  };

  const onChange = (e) => {
    setInputValue(e.target.value);
    onChangeFromProps?.(e);
  };

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current, []);

  const inputProps = {
    name: name,
    id: id,
    ref: innerRef,
    onBlur: onBlur,
    onFocus: onFocus,
    onChange: onChange,
    placeholder: !watchedValue && isFocused ? placeholder : ''
  };

  if (isControlled) {
    inputProps.value = valueFromProps;
  }

  function renderInput() {
    if (searchSelect) {
      return (
        <LocationsInput
          id={id}
          name={name}
          {...otherProps}
          inputProps={{ ...inputProps, className: css.input }}
          showIcons={false}
        />
      );
    }

    if (multiline) {
      return <textarea {...otherProps} {...inputProps} className={css.textarea} rows={1} />;
    }

    return <input {...otherProps} {...inputProps} className={css.input} />;
  }

  useEffect(() => {
    if (multiline && innerRef.current) {
      innerRef.current.style.height = 'auto';
      const scrollHeight = innerRef.current.scrollHeight;
      innerRef.current.style.height = scrollHeight + 'px';
    }
  }, [multiline, inputValue]);

  return (
    <div
      className={classNames(
        css.container,
        !watchedValue ? css.empty : css.filled,
        isFocused ? css.focused : '',
        hasError ? css.error : '',
        otherProps.required ? css.required : '',
        className
      )}>
      <div className={css.labelsContainer}>
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
        {renderInput()}
        <label className={css.errorMessage} htmlFor={id}>
          {error}
        </label>
      </div>
    </div>
  );
});

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  multiline: PropTypes.bool,
  searchSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  entities: PropTypes.array,
  triggerValidation: PropTypes.func,
  clearErrors: PropTypes.func,
  tabIndex: PropTypes.number
};

export default Input;
