import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function PosttravmatychnyyStresovyyRozladTaPosttravmatychneZrostannia() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Посттравматичний стресовий розлад: як розпізнати та допомогти?</h1>
      <p>
        Впродовж життя людина може зіштовхнутися з різними стресовими подіями: військові дії, аварії, стихійні лиха чи
        важкі фізичні нівечення, насилля, загроза смерті. Вони впливають на наше життя, ушкоджуючи психіку і таким чином
        можуть призвести до посттравматичного стресового розладу (ПТСР). У цій статті я — Світлана Тімофєєва,
        психотерапевтка платформи Rozmova пропоную разом розібратися, що таке ПТСР, чому він виникає, як його розпізнати
        та впоратися з цим розладом.
      </p>
      <h2>Що таке посттравматичний стресовий розлад?</h2>
      <p>Наразі цей термін використовують дуже часто і не завжди правильно, тому пропоную поринути у термінологію.</p>
      <p>
        Посттравматичний стресовий розлад (ПТСР, англ. PTSD, посттравматичний синдром, «комбатантський синдром»,
        «в&apos;єтнамський синдром», «афганський синдром», «східний синдром») — це психічний розлад, різновид неврозу,
        що виникає внаслідок переживання однієї чи кількох подій, котрі ушкоджують психіку.
        <b> Сьогодні однією з найбільш актуальних таких подій для українців є війна.</b> Також до них відносять й інші,
        такі як катастрофи, різні види насилля, важкі ушкодження.
      </p>
      <p>
        Важливо розуміти, що ПТСР не є психічним захворюванням. Це нормальна реакція психіки на ненормальні події.
        Простими словами: спроба організму пережити небезпечну, загрозливу ситуацію.
      </p>
      <h2>Які ситуації можуть викликати ПТСР?</h2>
      <p>
        Кожна людина по-різному переживає ту чи іншу подію в житті. Наша реакція на однакові речі може відрізнятися, і
        для одного вона буде більш травматичною, а для іншого — менш. Випадки розвитку ПТСР можуть бути викликані такими
        найбільш поширеними ситуаціями:
      </p>
      <ul>
        <li>одноразова чи довготривала насильницька психологічна травма у дитинстві чи підлітковому віці,</li>
        <li>
          маніпулятивне виховання в родині, під впливом якого у людини може сформуватися по-особливому влаштована
          структура мислення, почуттів, дій та спілкування,
        </li>
        <li>проблеми з житловою площею, зокрема з відсутністю особистого простору,</li>
        <li>дисоціальна поведінка з боку батьків, їх погана освіченість та невміння підтримати.</li>
        <li>психічні розлади в родинному анамнезі, авторитарне виховання,</li>
        <li>злочинність, бідність.</li>
      </ul>
      <h2>Як розпізнати ПТСР?</h2>
      <p>
        Існує декілька методів виявлення у себе чи у близьких симптомів посттравматичного стресового розладу. Їх можна
        розпізнати на трьох рівнях: думки та емоції, поведінка, реакції. Розберемо детальніше кожен із них.
      </p>
      <p>
        На рівні думок та емоцій людина часто негативно та з підозрою дивиться на світ. У неї відсутня довіра навіть до
        найближчих людей. Вона відсторонюється від суспільства, внаслідок чого виникає відчуття ізольованості та
        самотності. Негативний емоційний стан, який характеризується криками, плачем, зривами переважає над нормальним і
        не дає можливість насолоджуватися позитивними моментами життя. Іншими важливими ознаками можуть бути:
      </p>
      <ul>
        <li>уникання травматично релевантних стимулів: думок, почуттів, нагадувань.</li>
        <li>постійне очікування негативних подій або реакцій від інших,</li>
        <li>звинувачення себе в усьому поганому, що стається в житті,</li>
        <li>втрата зацікавленості тим, що раніше приносило задоволення,</li>
        <li>емоційний дистрес і фізична реактивність після нагадувань про травматичну подію.</li>
      </ul>
      <p>
        На рівні поведінки людина з ПТСР стає замкнутою, агресивною та може свідомо наносити собі біль або шкоду. Вона
        уникає людей і будь-яких розмов, що пов’язані з травмою. Така людина проявляє неготовність пригадувати ключові
        деталі травми, а також надмірно звинувачує як себе, так й інших у будь-яких важких ситуаціях.
      </p>
      <p>
        На рівні реакцій людина може діяти як різко та неочікувано, так і взагалі не діяти. З одного боку, в особи з
        ПТСР може бути постійне відчуття небезпеки, напруги та готовності реагувати будь-якої хвилини. З іншого боку ця
        реакція може бути відсутньою, коли людина не зважає на сильні подразники, звернення або запитання. Найбільш
        поширеними ознаками ПТСР на рівні реакцій також є:
      </p>
      <ul>
        <li>здригання, закривання очей, коли згадують травматичний досвід,</li>
        <li>проблеми зі сном: не можуть заснути, часто прокидаються, турбують нічні жахіття,</li>
        <li>
          збудження та реактивність, які почалися або підсилилися після травми: дратівливість чи агресія, ризикована чи
          деструктивна поведінка, надмірна пильність.
        </li>
        <li>проблеми із зосередженням, важко тримати фокус і концентруватися,</li>
        <li>проблеми з пам’яттю.</li>
      </ul>
      <h2>Які наслідки ПТСР?</h2>
      <p>
        Наші захисники і захисниці знаходяться в умовах, що провокують розвиток ПТСР. Вони бачать та роблять те, що не
        має бачити і робити людина у мирному житті. Завдяки своїй мужності, вони виконують важку роботу. Іноді попри
        наслідки. Однак наслідки важливо усвідомлювати та опрацьовувати.
      </p>
      <p>
        ПТСР може торкнутися всіх сфер життя, але в першу чергу, це погано впливає на соціалізацію та відносини з іншими
        людьми. Часто оточення не може зрозуміти поведінки особи з ПТСР, відсторонюється, адже не знає, як підтримати та
        допомогти. Тому й стосунки з людиною, яка страждає на ПТСР можуть зазнати краху.
      </p>
      <p>
        Наслідки можуть виражатися у легких формах: втома та безсилля, відсутність позитивних емоцій, відчуття щастя та
        задоволення. До важчих випадків відносять постійні спогади з минулого, нав’язливі чи суїцидальні думки,
        тривожний стан, порушення гігієни сну, депресія або навіть спроби вкоротити собі віку. Усі ці проблеми можуть
        псувати стан і життя не тільки людини, а й тих, хто її оточує.
      </p>
      <h2>Як допомогти людині з ПТСР?</h2>
      <p>
        Якщо ви бачите у себе чи у близьких ознаки ПТСР, що тривають понад місяць — необхідно звернутися за допомогою до
        фахівця. Нехтування самопочуттям може призвести тільки до погіршення стану. Коли є проблема, її треба
        опрацьовувати.
      </p>
      <p>
        Для того, щоб зрозуміти, чи потрібна вам професійна допомога, подумайте, як ви себе почуваєте і чи відповідають
        ці відчуття вашому нормальному стану. Якщо ні, тоді поміркуйте, наскільки вони впливають на ваше життя, а саме:
      </p>
      <ol>
        <li>Чи є у вас проблеми на роботі, з сім’єю, друзями?</li>
        <li>Чи маєте ви труднощі зі встановленням тісних відносин із людьми?</li>
        <li>Як часто ви відчуваєте сум?</li>
        <li>Чи маєте думки про самогубство?</li>
        <li>Чи бувають у вас напади агресії та думки про спричинення фізичних пошкоджень іншим?</li>
        <li>Чи переживаєте ви періоди дисоціації?</li>
        <li>
          Можливо, ви вживаєте алкоголь чи психоактивні речовини у надмірних дозах, щоб впоратися з важкими
          переживаннями?
        </li>
      </ol>
      Ці питання допоможуть вам зрозуміти, чи маєте ви симптоми ПТСР та в якій формі. Якщо ви бачите, що ситуація
      критична або може такою стати — краще звернутися до спеціаліста, щоб допомогти впоратися з важкими почуттями.
      <p>
        <b>Авторка статті — Світлана Тімофєєва, психотерапевтка платформи Rozmova</b>
      </p>
    </Article>
  );
}

export default PosttravmatychnyyStresovyyRozladTaPosttravmatychneZrostannia;
