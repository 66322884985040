import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function PsykholohPsykhoterapevtChyPsykhiatr() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Психолог, психотерапевт чи психіатр – з якими питаннями звертатися до цих спеціалістів?</h1>
      <p>
        Кожен з цих спеціалістів має відношення до
        <b> психології – науки, яка вивчає поведінку, думки, почуття людей. </b>
        Однак психолог, психотерапевт та психіатр мають різні рівні відповідальності та освітньої підготовки.
        Розбираємося, з якими питаннями допомагає розібратись кожен з фахівців.
      </p>
      <h2>Психолог – допомагає знайти вихід зі складних життєвих ситуацій</h2>
      <p>
        Психолог – це фахівець в галузі психології, який має вищу освіту за спеціальністю «Психологія». У своїй роботі
        психолог використовує техніки психологічної корекції й дотримується принципів психологічного консультування. Він
        надає допомогу у форматі консультацій, під час яких допомагає знайти рішення проблемних ситуацій та навчитися
        ефективно використовувати свій потенціал та ресурси.
      </p>
      <p>
        <b>Психолог допомагає:</b>
      </p>
      <ul>
        <li>знайти вихід з вікових та життєвих криз</li>
        <li>вирішити проблеми у стосунках, сім’ї, колективі</li>
        <li>пережити розлучення, втрату близької людини</li>
        <li>впоратися з психологічними травмами внаслідок аварії, катастрофи</li>
        <li>впоратися з важкими життєвими ситуаціями, втратою роботи</li>
        <li>впоратися з творчою, кар’єрною, професійною кризами</li>
        <li>впоратися з стражданнями, знайти шлях до сенсу життя, зрозуміти себе</li>
        <li>знайти корінь проблеми та шлях до самостійного її розв’язання</li>
        <li>заспокоїтися та впоратися зі стресом</li>
      </ul>
      <p>
        <b>Як виглядає консультація:</b>
      </p>
      <p>
        Психолог розмовляє з людиною, ставить запитання, уважно вислуховує відповіді та пропонує техніки, які можуть
        поліпшити якість життя людини. Розв’язання певної проблеми займає від однієї до декількох консультацій в
        залежності від запиту.
      </p>
      <hr />
      <p>
        <i>
          Психолог не є лікарем і не повинен мати медичну освіту. Психолог не лікує психічні захворювання, не призначає
          ліків. Психолог може скерувати до психотерапевта та психіатра.
        </i>
      </p>
      <hr />
      <p>
        <b>До психолога варто звернутися, якщо ви впізнаєте себе у цих словах:</b>
      </p>
      <ul>
        <li>Я розумію проблему, таке зі мною вперше і я не знаю, як впоратися з ситуацією самостійно.</li>
        <li>
          У мене кризовий період або складні життєві обставини – розлучення, втрата близької людини, втрата роботи тощо.
          Мені потрібна підтримка, хочу поговорити про те, що мене турбує.
        </li>
        <li>
          У мене постійні конфлікти в особистому житті. Хочу знайти найкращий вихід з ситуації та припинити сварки.
        </li>
        <li>
          У мене все добре. Я хочу покращити якість життя та дізнатися про корисні інструменти та техніки, які
          допоможуть мені з цим.
        </li>
      </ul>
      <h2>Психотерапевт – допомагає впоратися з психічними розладами та складними емоційними станами</h2>
      <p>
        Психотерапевт – це фахівець в галузі психології, який має вищу освіту за спеціальністю «Психологія», а також
        може мати медичну освіту.
      </p>
      <p>
        Для психотерапевта обов’язково мати додаткову психотерапевтичну освіту та освіту у напрямку, в якому працює
        фахівець, підтверджену закладом, визнаним однією або декількома міжнародними організаціями у галузі психології.
        В середньому, навчання на психотерапевта триває від чотирьох до п’яти років і продовжується у вигляді підвищення
        кваліфікації протягом всього часу, поки фахівець працює з пацієнтами.
      </p>
      <p>
        У своїй роботі психотерапевт використовує психотерапевтичні методи – зазвичай фахівець володіє одним або
        декількома методами (гештальт терапія, психоаналіз, клієнт-центрована терапія, арттерапія,
        когнітивно-поведінкова терапія (КПТ), схема-терапія тощо).
      </p>
      <p>
        Робота психотерапевта полягає у застосуванні психотерапевтичних методів для допомоги пацієнтам з психічними
        розладами, важкими випадками психологічної та емоційної криз, при тривожних, невротичних, депресивних станах
        тощо.
      </p>
      <p>
        <b>Психотерапевт допомагає:</b>
      </p>
      <ul>
        <li>подолати тривале відчуття дискомфорту</li>
        <li>подолати вигорання – відсутність бажання працювати, навчатися, апатія</li>
        <li>подолати перепади настрою, емоційні гойдалки, спалахи агресії</li>
        <li>знизити та звільнитися від сильної тривожності, порушень сну, психологічних травм (ПТСР)</li>
        <li>позбутися деструктивних звичок та залежностей (алкоголь, наркотичні речовини, селф-харм)</li>
        <li>навчитися відстоювати власні кордони</li>
        <li>позбутися іпохондрії – нав’язливого відчуття захворювання невиліковною хворобою</li>
        <li>знизити реакцію на тригери, які розвинулися внаслідок психологічних травм</li>
        <li>поліпшити стосунки з самим собою та близькими</li>
        <li>підвищити самооцінку та сприйняття себе</li>
      </ul>
      <p>
        <b>Як виглядає консультація:</b>
      </p>
      <p>
        Психотерапевт проводить з пацієнтом бесіду, під час якої шукає та знаходить причини та фактори, які спровокували
        кризовий стан. Застосовує психотерапевтичні методи для лікування. Тривалість роботи з психотерапевтом залежить
        від методу, в якому працює фахівець (наприклад, 12-16 сесій при когнітивно-поведінковій терапії, один-два роки
        при схема-терапії, декілька років при психоаналізі)
      </p>
      <hr />
      <p>
        <i>
          Психотерапевт без медичної освіти за спеціальністю «Психіатрія» не є лікарем і не призначає ліків.
          Психотерапевт може співпрацювати з психіатром та скеровувати до психіатра пацієнтів.
        </i>
      </p>
      <hr />
      <p>
        <b>До психотерапевта варто звернутися, якщо ви впізнаєте себе у цих словах:</b>
      </p>
      <ul>
        <li>
          Зі мною часто стаються схожі одна на одну неприємні ситуації. Хочу зрозуміти, чому так відбувається й звести
          повторюваність ситуацій до мінімуму.
        </li>
        <li>
          Я маю патерни поведінки, які негативно впливають на якість моїх стосунків, соціального життя, роботу. Хочу
          звільнитися від звичних руйнівних реакцій.
        </li>
        <li>
          Я не відчуваю зв’язку з собою. Речі, які зазвичай приносять задоволення, зараз мені байдужі. Мені складно
          побачити сенс в житті та зрозуміти, ким я є насправді.
        </li>
        <li>
          У мене був травматичний досвід, який впливає на моє життя зараз. Мені потрібна допомога, щоб з цим впоратися.
        </li>
        <li>
          У мене є психічний розлад (депресія, тривожний розлад, РДУГ, біполярний розлад тощо). Мені складно поєднувати
          симптоми з нормальним життям.
        </li>
        <li>Я хворію і це впливає на мій емоційний стан. Мені потрібна підтримка.</li>
        <li>
          Робота з психологом не дала результату, хоча я сумлінно виконував/виконувала всі рекомендації спеціаліста.
        </li>
      </ul>
      <h2>Психіатр – допомагає впоратися з біологічними причинами, які впливають на психічний стан</h2>
      <p>
        Психіатр – це фахівець, який має вищу медичну освіту за спеціальністю «Психіатрія» та має спеціальну
        кваліфікацію в галузі психіатрії. Психіатр спеціалізується на лікуванні психічних розладів та психіатричних
        захворювань. У своїй роботі психіатр вивчає та визначає біологічні причини, які викликають порушення психічного
        здоров’я. Він може призначати медикаментозне лікування й приймати рішення про госпіталізацію пацієнтів.
      </p>
      <p>
        <b>Психіатр допомагає:</b>
      </p>
      <ul>
        <li>виявити хімічний дисбаланс у головному мозку</li>
        <li>оцінити психічні та фізичні наслідки порушень</li>
        <li>призначити курс медикаментозного лікування та госпіталізацію (за необхідності)</li>
        <li>знизити повторюваність депресивних епізодів, суїцидальних думок</li>
        <li>знизити частоту психозів, галюцинацій, тяжких депресивних епізодів</li>
        <li>подолати деструктивні залежності від наркотичних засобів, алкоголю, азартних ігор</li>
        <li>знизити симптоми шизофренії, біполярного розладу</li>
        <li>полегшити деменцію, важкі захворювання, травми</li>
        <li>полегшити білу гарячку та стани зі схожими ознаками та проявами</li>
        <li>виявити ураження головного мозку, які відбилися на психіці</li>
      </ul>
      <p>
        <b>Як виглядає консультація:</b>
      </p>
      <p>
        Психіатр уважно вислуховує скарги. За допомогою питань, складає анамнез й вирішує, на який розлад або
        захворювання варто продіагностувати пацієнта. Якщо пацієнт приходить з запитом діагностуватися, наприклад, на
        депресію або РДУГ, фахівець проводить діагностику. Консультація триває від 30 до 60 хвилин та повторюється раз
        на декілька тижнів або місяців для відстежування ефективності лікування
      </p>
      <hr />
      <p>
        <i>
          Психіатр може призначати ліки. Психіатр може співпрацювати з психотерапевтом та рекомендувати психотерапію
          пацієнтам, як допоміжний метод в лікуванні.
        </i>
      </p>
      <hr />
      <p>
        <b>До психіатра варто звернутися, якщо ви впізнаєте себе у цих словах:</b>
      </p>
      <ul>
        <li>
          У мене є дивні симптоми (наприклад, галюцинації, нав’язливі думки, періоди сильної апатії, маніакальні періоди
          активності, селф-харм тощо)
        </li>
        <li>У мене з’являються суїцидальні думки. Я думаю про те, як скою самогубство або понівечу себе.</li>
        <li>
          Я погано сплю. Мені важко засинати/прокидатися. Сон вночі постійно переривається. Постійно хочу спати вдень.
        </li>
        <li>У мене проблеми з апетитом. Зовсім не можу їсти або навпаки сильно переїдаю.</li>
        <li>У мене постійно змінюється настрій незалежно від зовнішніх обставин.</li>
        <li>
          Мені не приносить задоволення нічого, від чого я отримував/отримувала його раніше. Таке триває понад два
          тижні.
        </li>
        <li>Мене скерував до психіатра мій психолог/психотерапевт.</li>
      </ul>
      Вибираючи, до кого йти – до психолога чи психотерапевта, потрібно об&apos;єктивно оцінити складність ситуації.
      Якщо вам складно визначитись з фахівцем, то для початку можна звернутися до сімейного лікаря. Він проконсультує
      вас та направить до потрібного фахівця.
    </Article>
  );
}

export default PsykholohPsykhoterapevtChyPsykhiatr;
