import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './search-input.module.scss';
import { ReactComponent as SearchIcon } from 'src/assets/search_icon.svg';
import classNames from 'classnames';

SearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default function SearchInput(props) {
  const [value, setValue] = useState(props.initialValue ?? '');

  function handleSearchIconClick() {
    props.onSearch(value);
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleClear() {
    setValue('');
    props.onSearch('');
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      props.onSearch(e.target.value);
    }
  }

  return (
    <div className={classNames(css.inputGroup, props.className ?? '')}>
      <SearchIcon className={css.searchIcon} onClick={handleSearchIconClick} />
      <input
        placeholder={props.placeholder}
        type='search'
        value={value}
        id={props.id}
        name={props.name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {value && (
        <span className={css.clear} onClick={handleClear}>
          ×
        </span>
      )}
    </div>
  );
}
