import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './page-header.module.scss';
import classNames from 'classnames';
import * as p from 'src/constants/page-classnames';
import * as routes from 'src/constants/routes';
import UafPattern from 'src/components/uaf-pattern/uaf-pattern';
import ToggleRoute from 'src/components/toggle/toggle-route';
import ProposeEventPopup from 'src/pages/events/event-proposition/propose-event';
import ProposeLocationPopup from 'src/pages/business-map/propose-location/propose-location';
import ProposeServicePopup from 'src/pages/services/service-proposition/propose-service';
import { LanguageSettingsContext } from 'src/utils/language-context';
import ProposePartnershipPopup from 'src/pages/partnership/propose-partnership/propose-partnership';

PageHeader.propTypes = {
  className: PropTypes.oneOf([
    p.WORK_SEARCH,
    p.CAREER_INFO,
    p.EDUCATION,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.BUSINESS_MAP,
    p.VETERAN_LOCATIONS,
    p.EVENTS,
    p.PARTNERSHIP
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default function PageHeader({ className, title, description, children }) {
  const { msg, link } = useContext(LanguageSettingsContext);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={classNames(css.header, css[className])}>
      <div className={css.container}>
        <div className={css.group}>
          <div className={css.textGroup}>
            {location.pathname.startsWith(link(routes.WORK.path)) && (
              <ToggleRoute
                className={className}
                labelLeft={msg('workSearchPageTitle')}
                pathLeft={link(routes.WORK_SEARCH.path)}
                labelRight={msg('careerMaterialsPageTitle')}
                pathRight={link(routes.CAREER_INFO.path)}
                onToggle={navigate}
              />
            )}
            {location.pathname.startsWith(link(routes.EDUCATION.path)) && (
              <ToggleRoute
                className={className}
                labelLeft={msg('educationCoursesPageTitle')}
                pathLeft={link(routes.EDUCATION_COURSES.path)}
                labelRight={msg('educationMaterialsPageTitle')}
                pathRight={link(routes.EDUCATION_INFO.path)}
                onToggle={navigate}
              />
            )}
            {location.pathname.startsWith(link(routes.VETERAN.path)) && (
              <ToggleRoute
                className={className}
                labelLeft={msg('businessMapPageTitle')}
                pathLeft={link(routes.BUSINESS_MAP.path)}
                labelRight={msg('veteranLocationsPageTitle')}
                pathRight={link(routes.VETERAN_LOCATIONS.path)}
                onToggle={navigate}
              />
            )}
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
          {location.pathname.startsWith(link(routes.EVENTS.path)) && (
            <ProposeEventPopup trigger={<button className={css.proposeButton}>{msg('proposeEventBtnMsg')}</button>} />
          )}
          {location.pathname.startsWith(link(routes.BUSINESS_MAP.path)) && (
            <ProposeLocationPopup
              trigger={<button className={css.proposeButton}>{msg('proposeLocationBtnMsg')}</button>}
            />
          )}
          {location.pathname.startsWith(link(routes.PARTNERSHIP.path)) && (
            <ProposePartnershipPopup
              trigger={<button className={css.proposeButton}>{msg('proposePartnershipBtnMsg')}</button>}
            />
          )}
          {(location.pathname.startsWith(link(routes.CAREER_INFO.path)) ||
            location.pathname.startsWith(link(routes.EDUCATION_INFO.path)) ||
            location.pathname.startsWith(link(routes.PSYCHOLOGICAL_HELP.path)) ||
            location.pathname.startsWith(link(routes.MEDICAL_SERVICES.path)) ||
            location.pathname.startsWith(link(routes.LEGAL_HELP.path)) ||
            location.pathname.startsWith(link(routes.USEFUL_INFO.path)) ||
            location.pathname.startsWith(link(routes.VETERAN_LOCATIONS.path))) && (
            <ProposeServicePopup
              includeLocation={location.pathname.startsWith(link(routes.VETERAN_LOCATIONS.path))}
              trigger={<button className={css.proposeButton}>{msg('proposeServiceBtnMsg')}</button>}
            />
          )}
        </div>
        {children ? <div className={css.group}>{children}</div> : null}
      </div>
      <UafPattern />
    </div>
  );
}
