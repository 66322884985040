import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function RekomendatsiiRodycham() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Рекомендації родичам</h1>
      <ol>
        <li>
          Комунікація:
          <ul>
            <li>
              Переконайтеся, що у військового, який повернувся додому, є можливість якусь частину вільного часу
              проводити з кожним членом сім&apos;ї або близьким другом.
            </li>
            <li>
              Підтримуйте його у стосунках з іншими, але також давайте йому провести час і наодинці з самим собою.
              Проведення часу з товаришами може бути корисним, але необхідно встановити баланс у спілкуванні з друзями
              та родиною
            </li>
            <li>
              Можливо, потрібно буде пробувати різні стилі спілкування. Ваш чоловік/ дружина або син/дочка звикли
              довійськового стилю розмови і «обмеженого інформування». Можливо, військовому доведеться знову відкрити
              для себе важливість обміну інформацією.
            </li>
            <li>
              Те, чи варто запитувати про війну, є неоднозначним питанням, але наполягати чи змушувати людину, що
              повернулася з зони активних бойових дій не варто. Знайте, що ваш рідний може відмовлятися говорити навіть
              про якісь дріб&apos;язкові речі, пов&apos;язані з війною.
            </li>
            <li>
              Важливо дати зрозуміти, що ви готові слухати, якщо це потрібно. Можливо, вам найкраще підійде непрямий
              підхід. Ви можете використовувати ті види діяльності, які спонукатимуть до розмов замість того, щоб,
              наприклад, дивитися телевізор. Такими видами діяльності можуть бути спільні прогулянки, виконання якоїсь
              спільної роботи, спільні спортивні чи інтелектуальні ігри.
            </li>
            <li>
              Висловлюйте впевненість, що вам вдасться впоратися з цією ситуацією (нерозумінням, конфліктами, труднощами
              в комунікації) і ви зможете налагодити спілкування.
            </li>
          </ul>
        </li>
        <li>
          Побут та сімейні ролі:
          <ul>
            <li>
              Будьте готові до того, що домашні ролі в сім&apos;ї можуть змінитися (також можуть змінитися і риси
              характеру військового).
            </li>
            <li>
              Поговоріть про те, хто бажає виконувати ті чи інші домашні обов&apos;язки (тепер це можуть бути зовсім
              інші речі, відмінні від тих, які були до служби чоловіка/дружини в армії).
            </li>
            <li>За потреби йдіть на компроміси. Важливо, щоб обидва були впевнені, що потреби кожного поважаються.</li>
          </ul>
        </li>
      </ol>
      <h2>Не робіть наступного</h2>
      <ul>
        <li>
          Не примушуйте військового говорити. Можливо, він ніколи не буде почуватися досить комфортно, щоб розповісти
          вам про свій бойовий досвід. Якщо ви готові вислухати, дайте йому про це знати прямо: «Мені було б важливо
          дізнатися про твоє життя там і мені хочеться тебе підтримати, але, якщо ти не готовий або не хочеш
          розповідати, я приймаю твоє рішення. Дай мені знати, коли захочеш розповісти».
        </li>
        <li>
          <b>Увага!</b> Ми могли б порадити не зупиняти і не тікати від теми, коли він говорить, але майте на увазі, що
          вам самим може бути важко і боляче слухати його/її розповіді (саме потреба вберегти вас від болю співчуття
          може спонукати його замикатися в собі). Як бути в такій ситуації? Як варіант, будьте чесними та щирими у
          співчутті. Можете сказати щось на кшталт: «Я бачу, як важливо тобі говорити, мені це боляче чути. Я зробила б
          усе, щоб захистити тебе і себе від цього. І я тішуся, що нам вдалося це пережити». Намагайтеся не давати
          оцінок і не говоріть: «А я знаю, як тобі було, я весь час...», «Те, що тобі довелося робити, це жахливо»,
          «Гади, як вони могли, це несправедливо».
        </li>
        <li>Не намагайтеся вказувати, що йому треба робити в наказній формі, це принижує.</li>
        <li>
          Не використовуйте кліше або вирази: «Війна це пекло» або «Якщо ти повернувся, можеш залишити все в минулому,
          забудь». Замість цього краще скажіть йому, що хвилюєтеся за нього, любите і будете поруч стільки, скільки
          потрібно. Не давайте вашій рідній людині порад, попередньо не вислухавши її уважно.
        </li>
        <li>
          Не поспішайте. Для повторного «знайомства» та відновлення комфортного спілкування один з одним потрібен певний
          час.
        </li>
      </ul>
      <h2>Коли потрібно звернутися по допомогу</h2>
      Пам&apos;ятайте, що для повернення до звичного життя потрібен час та прийняття (можливе навіть співчуття,
      емпатія). Однак, труднощі в адаптації, що тривають більше ніж один-два місяці без позитивної динаміки - привід
      звернути на них увагу. Також приводом для хвилювання є, якщо військовий намагатиметься подолати стрес за допомогою
      алкоголю, наркотиків, емоційного та фізичного дистанціювання або самоізоляції, або ж виявляє раптові емоційні
      вибухи. Якщо ви помітили такі речі за своєю близькою людиною, можливо, час звернутися за допомогою до психолога,
      психотерапевта чи лікаря- психіатра, нарколога.
      <h2>На що звернути увагу:</h2>
      <ul>
        <li>
          У сімейних соціальних відносинах трапляються часті та інтенсивні конфлікти, недостатньо розвинена комунікація.
        </li>
        <li>
          Часті дуже сильні депресивні чи агресивні настрої, особливо якщо відчувається небезпека, що військовий може
          завдати шкоди іншим або собі.
        </li>
        <li>Часті нав&apos;язливі думки, спогади чи «картинки» із зони збройного конфлікту.</li>
        <li>Часта надмірна настороженість чи надмірна готовність до дій.</li>
      </ul>
      Визнати проблеми буває дуже важко. Військовий може:
      <ul>
        <li>думати, що впорається самостійно;</li>
        <li>думати, що ніхто йому не допоможе;</li>
        <li>може вірити у те, що проблеми вирішаться власними силами;</li>
        <li>надто соромитися або боятися говорити з кимось про власні труднощі («щоб не вважали психом»).</li>
      </ul>
      Такі емоційні чи психологічні труднощі не є ознаками слабкості, а свідчать про психічні захворювання. Травми,
      рани, в тому числі психологічні, вражають хоробрих і сильних так само як і інших людей. Однак страх того, що «мене
      вважатимуть психом», може бути величезною перешкодою для людей, які дійсно потребують допомоги. Нагадайте вашій
      рідній людині, що пошук розв&apos;язання проблеми - ознака сильної та зрілої людини.
    </Article>
  );
}

export default RekomendatsiiRodycham;
