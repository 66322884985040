import React from 'react';
import PropTypes from 'prop-types';
import css from './animated-container.module.scss';

AnimatedContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func
};

export default function AnimatedContainer({ children, onClick }) {
  return (
    <div className={css.animatedContainer} onClick={onClick}>
      {children}
    </div>
  );
}
