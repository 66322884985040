import React, { forwardRef, useContext } from 'react';
import css from './services.module.scss';
import Card from './card';
import * as r from 'src/constants/routes';
import { ReactComponent as Work } from 'src/assets/services/work.svg';
import { ReactComponent as Education } from 'src/assets/services/education.svg';
import { ReactComponent as PsychologicalHelp } from 'src/assets/services/psych.svg';
import { ReactComponent as MedServices } from 'src/assets/services/med.svg';
import { ReactComponent as LegalHelp } from 'src/assets/services/legal.svg';
import { ReactComponent as UsefulInfo } from 'src/assets/services/info.svg';
import { ReactComponent as BusinessMap } from 'src/assets/services/map.svg';
import { ReactComponent as Events } from 'src/assets/services/event.svg';
import { LanguageSettingsContext } from 'src/utils/language-context';

const Services = forwardRef(function Services(_, ref) {
  const { msg, link } = useContext(LanguageSettingsContext);

  return (
    <div className={css.services} ref={ref}>
      <div className={css.container}>
        <header>
          <h2>{msg('servicesTitle')}</h2>
        </header>
        <div className={css.cards}>
          <Card
            className={css.work}
            icon={<Work />}
            title={msg('workPageTitle')}
            list={[
              {
                title: msg('workSearchPageTitle'),
                url: link(r.WORK_SEARCH.path)
              },
              {
                title: msg('careerMaterialsPageTitle'),
                url: link(r.CAREER_INFO.path)
              }
            ]}
          />
          <Card
            className={css.education}
            icon={<Education />}
            title={msg('educationPageTitle')}
            list={[
              {
                title: msg('educationCoursesPageTitle'),
                url: link(r.EDUCATION_COURSES.path)
              },
              {
                title: msg('educationMaterialsPageTitle'),
                url: link(r.EDUCATION_INFO.path)
              }
            ]}
          />
          <Card
            className={css.psychoHelp}
            icon={<PsychologicalHelp />}
            title={msg('psychologicalHelpPageTitle')}
            list={[
              {
                title: msg('servicesCard3Link1'),
                url: link(r.PSYCHOLOGICAL_HELP.path)
              }
            ]}
          />
          <Card
            className={css.med}
            icon={<MedServices />}
            title={msg('medicalServicesPageTitle')}
            list={[
              {
                title: msg('servicesCard4Link1'),
                url: link(r.MEDICAL_SERVICES.path)
              }
            ]}
          />
          <Card
            className={css.legalHelp}
            icon={<LegalHelp />}
            title={msg('legalHelpPageTitle')}
            list={[
              {
                title: msg('servicesCard5Link1'),
                url: link(r.LEGAL_HELP.path)
              }
            ]}
          />
          <Card
            className={css.usefulInfo}
            icon={<UsefulInfo />}
            title={msg('usefulInfoPageTitle')}
            list={[
              {
                title: msg('servicesCard6Link1'),
                url: link(r.USEFUL_INFO.path)
              }
            ]}
          />
          <Card
            className={css.map}
            icon={<BusinessMap />}
            title={msg('businessMapPageTitle')}
            list={[
              {
                title: msg('mapPageTitle'),
                url: link(r.BUSINESS_MAP.path)
              },
              {
                title: msg('veteranLocationsPageTitle'),
                url: link(r.VETERAN_LOCATIONS.path)
              }
            ]}
          />
          <Card
            className={css.events}
            icon={<Events />}
            title={msg('eventsPageTitle')}
            list={[
              {
                title: msg('eventsPageSubtitle'),
                url: link(r.EVENTS.path)
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
});

export default Services;
