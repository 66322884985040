import React, { useId, useState } from 'react';
import PropTypes from 'prop-types';
import css from './service-cards.module.scss';
import ServiceCard from './service-card';
import * as p from 'src/constants/page-classnames';
import PaginationCard from './service-pagination-card';

ServiceCards.propTypes = {
  onClick: PropTypes.func.isRequired,
  blockTitle: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string,
      imgUrl: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired,
  className: PropTypes.oneOf([
    p.CAREER_INFO,
    p.EDUCATION,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.VETERAN_LOCATIONS
  ]).isRequired,
  showCardsPagination: PropTypes.bool,
  servicesLimit: PropTypes.number.isRequired
};

export default function ServiceCards({ blockTitle, services, className, showCardsPagination, servicesLimit, onClick }) {
  const serviceCardId = useId();
  const [selectedPage, setSelectedPage] = useState(1);
  const totalCount = services.length;

  if (totalCount === 0) {
    return null;
  }

  const offset = selectedPage * servicesLimit;
  const slicedServices = services.slice(0, offset);

  const hidePaginationCard = !showCardsPagination || slicedServices.length === totalCount;

  return (
    <div className={css.container}>
      <div className={css.title}>{blockTitle}</div>
      <div className={css.cards}>
        {slicedServices.map((service) => (
          <ServiceCard key={serviceCardId + service.id} service={service} className={className} onClick={onClick} />
        ))}
        {!hidePaginationCard && (
          <PaginationCard key={serviceCardId} onClick={() => setSelectedPage((prev) => prev + 1)} />
        )}
      </div>
    </div>
  );
}
