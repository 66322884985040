import css from './roadmap.module.scss';
import React, { useContext } from 'react';
import { ReactComponent as Arrow } from 'src/assets/general-arrow.svg';
import { ReactComponent as RegisterProfileIcon } from 'src/assets/register-profile-icon.svg';
import { ReactComponent as ChooseServiceIcon } from 'src/assets/choose-service-icon.svg';
import { ReactComponent as UsePossibilitiesIcon } from 'src/assets/use-possibilies-icon.svg';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function Roadmap() {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.roadmap}>
      <div className={css.container}>
        <h3 className={css.callToAction}>{msg('roadmapTitle')}</h3>
        <Arrow className={css.arrow} />
        <div className={css.first}>
          <RegisterProfileIcon />
          <p>{msg('roadmapMsg1')}</p>
        </div>
        <Arrow className={css.arrow} />
        <div className={css.second}>
          <ChooseServiceIcon />
          <p>{msg('roadmapMsg2')}</p>
        </div>
        <Arrow className={css.arrow} />
        <div className={css.third}>
          <UsePossibilitiesIcon />
          <p>{msg('roadmapMsg3')}</p>
        </div>
      </div>
    </div>
  );
}
