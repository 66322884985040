import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function UniversalniRekomendatsiiDliaBorotbyZiStresom() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Універсальні рекомендації для боротьби зі стресом</h1>
      <ul>
        <li>Виконуйте фізичні вправи (наприклад, прогулянки на свіжому повітрі, йога).</li>
        <li>
          Добре допомагають розслабитись ваші улюблені справи/хобі. Якщо ви вірянин, добре допоможе молитва чи
          відвідання служби.
        </li>
        <li>Не нехтуйте повноцінним харчуванням.</li>
        <li>
          Забезпечте тривалий нічний сон (8-9 годин) або визначте оптимальний графік і намагайтеся його дотримуватися.
        </li>
        <li>
          По можливості, встановіть розпорядок дня – їжте і лягайте спати в один в той самий час, контролюйте час,
          відведений на роботу та відпочинок.
        </li>
        <li>
          Плануйте - записуйте свої справи на день. По можливості, знизьте або відмовтеся від вживання кофеїновмісних
          напоїв (кави, чаю)
        </li>
        <li>Пам&apos;ятайте про особисту гігієну</li>
        <li>
          Уникайте постійного переглядання телевізора\новин в телеграм\соц.мережах! Зовсім відмовитися від новин навряд
          чи вийде, тому виділіть окремий час для цього.
        </li>
        <li>
          Якщо ви вживаєте алкоголь, не зловживайте ним. Важливо не використовувати алкоголь як снодійне, оскільки він
          заважає глибоким фазам сну. Не вживайте алкоголь як спосіб уникнути негативних спогадів чи реакцій, почуттів,
          які мають бути вирішені. Вживання алкоголю з цих причин, як правило, призводить до виникнення залежності та
          серйозніших труднощів у довгостроковій перспективі.
        </li>
      </ul>
      <h2>При сильних емоційних реакціях зробіть два кроки:</h2>
      <ol>
        <li>
          Усвідомте, що ви відчуваєте - подумки проговоріть: «Я відчуваю... (тривогу, паніку, вибухи агресії, агресію)»
        </li>
        <li>
          Зробіть дихальні вправи:
          <ul>
            <li>
              На рахунок 1,2,3,4 повільно вдихніть, при цьому живіт має випинатися вперед, м&apos;язи живота мають бути
              розслабленими, грудна клітка нерухомою.
            </li>
            <li>На наступні чотири рахунки (1,2,3,4) затримайте подих</li>
            <li>Плавно видихніть рахунок (1,2,3,4,5,6), підтягуючи м&apos;язи живота до хребта.</li>
          </ul>
        </li>
      </ol>
      Також рекомендуємо спрощену техніку релаксації. Її можна проводити, наприклад, перед сном або при емоційній
      напрузі.
      <ul>
        <li>
          Сильно стисніть руки в кулаки до появи напруги. Зачекайте 10 секунд, а потім почніть розслабляти м&apos;язи,
          розгинаючи один палець за іншим.
        </li>
        <li>Фокусуйте увагу на відчуттях у руці, коли розслаблюєте м&apos;язи.</li>
      </ul>
    </Article>
  );
}

export default UniversalniRekomendatsiiDliaBorotbyZiStresom;
