import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function YakVidnovytysiaPisliaKontuzii() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Як відновитися після контузії?</h1>
      <p>
        Вітаю, мене звати Світлана Тімофєєва. Я психотерапевтка платформи Rozmova. Понад 30 років займаюсь психологією
        та спостерігаю за поведінкою людей, зокрема з лютого 2022 працюю кризовою психологинею із військовими. У цій
        статті хочу підняти тему важливості лікування контузії — однієї з найбільш поширених і серйозних травм, які
        можуть виникнути в результаті бойових дій.
      </p>
      <h2>Що таке контузія?</h2>
      <p>
        Контузія або акубаротравма (contusio — «забій») — це загальне ураження організму внаслідок механічного впливу, а
        саме повітряної, звукової чи водяної хвилі та удару об землю чи воду. Воно не обов’язково супроводжується
        ушкодження органів або тканин. Характерною ознакою контузії є непритомність, що може тривати від декількох
        хвилин до днів і навіть місяців.
      </p>
      <h2>Симптоми контузії</h2>
      <p>
        Внаслідок бойових дій в Україні люди можуть стикатися з контузією головного мозку, що спричинена вибухами або
        іншими механічними впливами. Особа втрачає свідомість, непритомніє, а опісля відчуває сильний головний біль та
        запаморочення, особливо під час поворотів голови. Такий стан супроводжується нудотою та блюванням незалежно від
        того чи приймала людина їжу. Також можуть виникнути амнезія, порушення слуху та мовлення, адже відбулося
        пошкодження головного мозку.
      </p>
      <p>
        Симптоми контузії часто нагадують ознаки струсу головного мозку, але є відмінності. Зокрема відокремлюють
        контузію головного мозку та очей.
      </p>
      <p>До найбільш поширених симптомів контузії головного мозку відносять:</p>
      <ul>
        <li>непритомність, запаморочення, головний біль,</li>
        <li>порушення дихання та кровообігу,</li>
        <li>параліч (в залежності від того, яка частина мозку уражена),</li>
        <li>кровотечі з вух і носа,</li>
        <li>дзвін або шум у вухах,</li>
        <li>порушення чутливості, мовлення, зору та слуху,</li>
        <li>сплутана свідомість, дезорієнтація,</li>
        <li>проблеми зі сном, пам’яттю та концентрацією уваги,</li>
        <li>синюшність кистей,</li>
        <li>приливи до голови,</li>
        <li>уповільнені реакції.</li>
      </ul>
      <p>
        Симптоми контузії можуть виникати одразу після вибуху або розвинутися згодом, протягом годин або днів. За
        сприятливого перебігу ознаки практично повністю зникають впродовж періоду від 7 до 30 днів.
      </p>
      <h2>Різниця симптомів контузії та інших поранень</h2>
      <p>
        Розуміючи свої симптоми важливо точно переконатися, чи це контузія, чи інше поранення зі схожими симптомами.
      </p>
      <p>Порада: якщо поруч є медик, то краще зверніться за професійною допомогою.</p>
      <p>В іншому випадку виконайте наступні дії:</p>
      <ul>
        <li>перевірте наявність інших поранень або чогось, що може завадити можливості говорити,</li>
        <li>спостерігайте за тілом: як відкриваються очі, який зміст мовлення, рухи лівої та правої сторони окремо,</li>
        <li>використовуючи стимуляції звуку перевірте чи не пошкоджено слух,</li>
        <li>натисніть на кінчик пальця, над ключицею, або під бровою, щоб перевірити реакцію людини на тиск.</li>
      </ul>
      <p>
        Окремо хочу розглянути такий термін, як легка черепно-мозкова травма (лЧМТ, контузія). Її ознаками є втрата
        свідомості не більше, ніж на 30 хвилин після ураження та поява дезорієнтації й амнезії впродовж 24 годин від
        отримання травми.
      </p>
      Особливістю виявлення даного діагнозу є потреба в оцінці свідомості постраждалого одразу після отримання травми.
      Часто це не є можливим в умовах бойових дій. Тому не завжди вдається встановити точний діагноз одразу, і людина
      може почати помічати прояви симптомів контузії.
      <p>
        Опритомнівши, постраждалий може не пам’ятати власне ім’я, який зараз день, останні події та як тут опинився. Це
        яскраві прояви амнезії, яка надалі може перерости в те, що особа не може запам’ятовувати легку інформацію,
        наприклад, що було сказано щойно. Зокрема через це постраждалий може почати проявляти агресію, лаятися,
        безцільно блукати, бути збудженим та дратівливим.
      </p>
      <p>
        Тому необхідно спостерігати за людиною, за тим, як довго у неї існують наведені вище симптоми, щоб правильно
        констатувати хворобу й отримати належне медичне лікування.
      </p>
      <h2>Що робити за наявності симптомів контузії?</h2>
      <p>
        Якщо ви бачите у людини симптоми контузії, важливо знати, яку першу допомогу надавати. Пам’ятайте, що контузія —
        це ушкодження мозку, тому краще забезпечити постраждалому професійну медичну допомогу, як мінімум для
        встановлення діагнозу.
      </p>
      <p>
        Зі свого боку ви можете допомогти людині. Перш за все, одразу після контузії слід накладати холодні компреси на
        голову та забезпечити спокій і тишу наскільки це можливо. Покладіть хворого на бік, аби блювотиння не потрапило
        до дихальних шляхів. Протягом першої доби забезпечте обов’язковий постільний режим у темряві та тиші, щоб
        мінімізувати вплив подразників. Також впродовж наступних днів слід уникати гучних звуків, різких змін положення
        голови, фізичних навантажень та використання гаджетів.
      </p>
      <h2>Наслідки контузії у пізньому періоді</h2>
      <p>
        Знання проблеми вже дає можливість опрацьовувати її наслідки. Усе частіше стикаюся з тим, коли людина не
        розуміє, що з нею відбувається і тому лякається власних симптомів, закривається, не приймає належного лікування.
        Потрібно пам’ятати, що постраждалі від контузії потребують термінового ушпиталення, надання медичної допомоги та
        повного спокою, адже виявлення травми у пізньому періоді має свої наслідки, такі як:
      </p>
      <ol>
        <li>Епілептиформні напади: епілепсія, недовготривалі судомні напади.</li>
        <li>
          Нейродегенерація, тобто захворювання нервової системи. Це відбувається в результаті прогресування загибелі
          нервових клітин, яка призводить до різних неврологічних симптомів, перш за все, до деменції та порушення руху.
        </li>
        <li>Пошкодження головного мозку, горла, гортані, трахеї, очей.</li>
      </ol>
      <p>Щоб запобігти цим наслідкам важливо фіксувати будь-які симптоми: як яскраво виражені, так і незначні.</p>
      <h2>Дії після контузії</h2>
      <p>
        Отримання контузії — це великий стрес для організму. Тому важливо приділяти особливу увагу відновленню після
        травми.
      </p>
      <p>
        Якщо у людини виявили стійкі порушення руху, рекомендована лікувальна фізкультура. При порушеннях мовлення —
        логопедичні заняття. Із загальних правил важливо налагодити режим сну, регулярне харчування, пити достатньо
        води, не вживати алкоголь, напої з кофеїном і солодкі газовані напої.
      </p>
      <p>
        Необхідно спостерігати за головним болем і уникати важких навантажень, щоб не провокувати стан. Можливо, варто
        зробити такі обстеження як КТ чи МРТ, щоб визначити характер й ушкодження головного мозку.
      </p>
      <p>
        Також контузія може спричинити ризик розвитку депресії, тому навіть за наявності легких ознак депресивного стану
        важливо звернутися до психіатра або невролога, щоб отримати належне лікування.
      </p>
      <p>
        <b>
          <i>Авторка статті — Світлана Тімофєєва, психотерапевтка платформи Rozmova</i>
        </b>
      </p>
    </Article>
  );
}

export default YakVidnovytysiaPisliaKontuzii;
