import React, { useContext, useId } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import './select-input.scss';
import { FormControl, MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { UKRAINE } from 'src/constants/languages';

SingleSelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default function SingleSelectInput({ control, options, name }) {
  const { language } = useContext(LanguageSettingsContext);
  const selectInputId = useId();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field }) => (
        <FormControl
          className={classNames(
            'singleSelectInput',
            language === UKRAINE ? '' : language,
            field.value ? 'filled' : ''
          )}>
          <Select id={selectInputId} value={field.value} onChange={field.onChange}>
            {options.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
