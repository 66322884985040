import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './navbar.module.scss';
import TooltipWrapper from 'src/components/tooltip-wrapper/tooltip-wrapper';

Item.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reloadDocument: PropTypes.bool,
  navigationItem: PropTypes.bool,
  showTooltip: PropTypes.bool
};

export default function Item({
  children,
  to,
  title,
  reloadDocument = false,
  navigationItem = true,
  showTooltip = true
}) {
  const linkNode = navigationItem ? (
    <NavLink to={to} reloadDocument={reloadDocument}>
      {children}
    </NavLink>
  ) : (
    <Link to={to} reloadDocument={reloadDocument}>
      {children}
    </Link>
  );

  if (!showTooltip) {
    return <li>{linkNode}</li>;
  }

  return (
    <TooltipWrapper
      text={title}
      wrapper={(tooltip, onMouseEnter, onMouseLeave) => (
        <li className={css.tooltip} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {linkNode}
          {tooltip}
        </li>
      )}
    />
  );
}
