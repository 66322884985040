import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LanguageSettingsContext } from 'src/utils/language-context';
import * as p from 'src/constants/page-classnames';
import { LEFT, RIGHT } from 'src/constants/positions';
import { useResize } from 'src/hooks/shared-hooks';
import { fireEvent } from 'src/utils/analytics/google';
import { openInNewTab } from 'src/utils/helper';
import Banner from './banner/banner';
import ServiceCards from './cards/service-cards';
import css from './list.module.scss';

List.propTypes = {
  selectedServiceTag: PropTypes.string,
  servicesLimit: PropTypes.number.isRequired,
  showCardsPagination: PropTypes.bool,
  servicesBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      blockTitle: PropTypes.string,
      blockType: PropTypes.number.isRequired,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          url: PropTypes.string,
          phone: PropTypes.string,
          imgUrl: PropTypes.string,
          title: PropTypes.string.isRequired,
          description: PropTypes.string,
          locationName: PropTypes.string
        })
      )
    })
  ).isRequired,
  className: PropTypes.oneOf([
    p.CAREER_INFO,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.EDUCATION,
    p.VETERAN_LOCATIONS
  ]).isRequired
};

export default function List({ selectedServiceTag, servicesBlocks, servicesLimit, showCardsPagination, className }) {
  const { link } = useContext(LanguageSettingsContext);
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const page = window.location.pathname.slice(1);

  function onServiceClick(service) {
    fireEvent('view_service', { title: service.title, url: service.url });

    window.sessionStorage?.setItem(
      'persisted-preferences',
      JSON.stringify({
        filterTagId: selectedServiceTag,
        scrollPosition: window.scrollY,
        page
      })
    );

    if (service.phone) {
      openInNewTab(`tel:${service.phone}`);
      return;
    }

    if (service.url.startsWith(baseUrl) || service.url.startsWith('/')) {
      navigate(link(service.url.replace(baseUrl, '')));
    } else {
      openInNewTab(service.url);
    }
  }

  const services = useRenderServices(servicesBlocks, servicesLimit, showCardsPagination, className, onServiceClick);

  useRestoreScroll(page);

  if (services.length === 0) {
    return null;
  }

  return <div className={css.servicesContainer}>{services}</div>;
}

function useRenderServices(servicesBlocks, servicesLimit, showCardsPagination, className, onServiceClick) {
  const dimensions = useResize();

  const tabletMode = dimensions.width < 1000 && dimensions.width > 713;
  let index = 0;
  let columnIndex = 0;
  let leftBannerColumn = [];
  let rightBannerColumn = [];
  let result = [];

  function populateBannerColumns() {
    if (leftBannerColumn.length > 0) {
      result.push(
        <div key={`banner-column-${columnIndex++}`} className={css.bannerGroup}>
          {leftBannerColumn}
        </div>
      );
      leftBannerColumn = [];
    }

    if (rightBannerColumn.length > 0) {
      result.push(
        <div key={`banner-column-${columnIndex++}`} className={css.bannerGroup}>
          {rightBannerColumn}
        </div>
      );
      rightBannerColumn = [];
    }
  }

  for (const servicesBlock of servicesBlocks) {
    if (servicesBlock.services.length === 0) {
      continue;
    }

    if (servicesBlock.blockType === 0) {
      const imagePosition = index++ % 2 === 0 ? LEFT : RIGHT;
      const bannerColumn = tabletMode ? (index % 2 === 0 && rightBannerColumn) || leftBannerColumn : leftBannerColumn;

      bannerColumn.push(
        <Banner
          key={servicesBlock.id}
          service={servicesBlock.services[0]}
          imagePosition={imagePosition}
          className={className}
          onClick={onServiceClick}
        />
      );
    } else {
      populateBannerColumns();
      result.push(
        <ServiceCards
          key={servicesBlock.id}
          blockTitle={servicesBlock.blockTitle}
          url={servicesBlock.url}
          placeholder={servicesBlock.placeholder}
          services={servicesBlock.services}
          className={className}
          servicesLimit={servicesLimit}
          showCardsPagination={showCardsPagination}
          onClick={onServiceClick}
        />
      );
    }
  }

  populateBannerColumns();
  return result;
}

function useRestoreScroll(page) {
  useEffect(() => {
    const prefs = JSON.parse(window.sessionStorage?.getItem('persisted-preferences'));
    if (prefs?.page !== page) {
      window.sessionStorage?.setItem(
        'persisted-preferences',
        JSON.stringify({
          filterTagId: null,
          scrollPosition: null,
          page
        })
      );

      return;
    }

    if (!prefs?.scrollPosition) return;

    window.scrollTo({ top: prefs?.scrollPosition, behavior: 'instant' });
    window.sessionStorage?.setItem(
      'persisted-preferences',
      JSON.stringify({
        ...prefs,
        scrollPosition: null
      })
    );
  }, [page]);
}
