import React, { useId, useContext } from 'react';
import css from './customer.module.scss';
import AnimatedContainer from 'src/components/animated-container/animated-container';
import { ReactComponent as MoLogo } from 'src/assets/general/mo.svg';
import { ReactComponent as GshLogo } from 'src/assets/general/gsh.svg';
import { openInNewTab } from 'src/utils/helper';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function Customer() {
  const customerKey = useId();
  const { msg } = useContext(LanguageSettingsContext);
  const customers = [
    { logo: <MoLogo />, url: 'https://www.mil.gov.ua/' },
    { logo: <GshLogo />, url: 'https://www.zsu.gov.ua/' }
  ];

  return (
    <div className={css.customers}>
      <div className={css.container}>
        <header>
          <h2>{msg('customersTitle1')}</h2>
          <h2>{msg('customersTitle2')}</h2>
        </header>
        <div className={css.customer}>
          {customers.map((customer, i) => (
            <AnimatedContainer key={customerKey + i} onClick={() => openInNewTab(customer.url)}>
              {customer.logo}
            </AnimatedContainer>
          ))}
        </div>
      </div>
    </div>
  );
}
