import UniversalniRekomendatsiiDliaBorotbyZiStresom from './psychological-help/pleso/universalni-rekomendatsii-dlia-borotby-zi-stresom';
import RekomendatsiiRodycham from './psychological-help/pleso/rekomendatsii-rodycham';
import BorotbaZiSmutkomAboHoremPisliaVtraty from './psychological-help/mentoly/borotba-zi-smutkom-abo-horem-pislia-vtraty';
import PosttravmatychnyiStresovyiRozladPTSR from './psychological-help/mentoly/posttravmatychnyi-stresovyi-rozlad-PTSR';
import ProblemyZiSnomYakSobiDopomohty from './psychological-help/mentoly/problemy-zi-snom-yak-sobi-dopomohty';
import PsykholohPsykhoterapevtChyPsykhiatr from './psychological-help/mentoly/psykholoh-psykhoterapevt-chy-psykhiatr';
import YakAdaptuvatysDoPovsiakdennohoZhyttiaPisliaPovernenniaDodomu from './psychological-help/mentoly/yak-adaptuvatys-do-povsiakdennoho-zhyttia-pislia-povernennia-dodomu';
import PosttravmatychnyyStresovyyRozladTaPosttravmatychneZrostannia from './psychological-help/rozmova/posttravmatychnyy-stresovyy-rozlad-ta-posttravmatychne-zrostannia';
import RozmovaProblemyZiSnomYakSobiDopomohty from './psychological-help/rozmova/rozmova-problemy-zi-snom.-Yak-sobi-dopomohty';
import SuitsydalniDumkyChomuVynykaiutTaYakSpravytys from './psychological-help/rozmova/suitsydalni-dumky-chomu-vynykaiut-ta-yak-spravytys';
import YakVidnovytysiaPisliaKontuzii from './psychological-help/rozmova/yak-vidnovytysia-pislia-kontuzii';
import OsobuZInvalidnistiuVnaslidokViiny from './education/mesu/pilhovi-umovy-vstupu-do-vnz/osobu-z-invalidnistiu-vnaslidok-viiny';
import DitiamUchasnykivBoiovykhDii from './education/mesu/pilhovi-umovy-vstupu-do-vnz/ditiam-uchasnykiv-boiovykh-dii';
import UchasnykivBoiovykhDii from './education/mesu/pilhovi-umovy-vstupu-do-vnz/uchasnykiv-boiovykh-dii';
import ZabezpechenniaDodatkovymyZasobamyReabilitatsii from './useful-info/mspu/zabezpechennia-dodatkovymy-zasobamy-reabilitatsii';
import OtrymanniaNapravlenniaDoReabilitatsiinykhUstanov from './useful-info/mspu/otrymannia-napravlennia-do-reabilitatsiinykh-ustanov';
import VziattiaNaOblikDliaZabezpechenniaSanatornoKurortnymLikuvanniamPutivkamy from './useful-info/mspu/vziattia-na-oblik-dlia-zabezpechennia-sanatorno-kurortnym-likuvanniam-putivkamy';
import PilhyNaOplatuZhkpPrydbanniaTverdohoPalyvaTaSkraplenohoHazu from './useful-info/mspu/pilhy-na-oplatu-zhkp-prydbannia-tverdoho-palyva-ta-skraplenoho-hazu';
import BezoplatneOderzhanniaMedykamentivZaRetseptamyLikarivTaPershocherhoveZuboprotezuvannia from './useful-info/mspu/bezoplatne-oderzhannia-medykamentiv-za-retseptamy-likariv-ta-pershocherhove-zuboprotezuvannia';
import BezoplatnePokhovanniaPomerlykhZahyblykhVeteranivViinyZakhysnykivIZakhysnytsUkrainy from './useful-info/mspu/bezoplatne-pokhovannia-pomerlykh-zahyblykh-veteraniv-viiny-zakhysnykiv-i-zakhysnyts-ukrainy';
import HroshovaKompensatsiiaNaPalneRemontTaTransportneObsluhovuvannia from './useful-info/mspu/hroshova-kompensatsiia-na-palne-remont-ta-transportne-obsluhovuvannia';
import OtrymanniaAvtomobiliaDliaOsibZInvalidnistiu from './useful-info/mspu/otrymannia-avtomobilia-dlia-osib-z-invalidnistiu';
import BezoplatnyiProizdTransportom from './useful-info/mspu/bezoplatnyi-proizd-transportom';
import PilhyYakiMozhutOtrymatyVeteranyViiny from './useful-info/mspu/pilhy-yaki-mozhut-otrymaty-veterany-viiny';

const articlePaths = {
  UniversalniRekomendatsiiDliaBorotbyZiStresom: `universalni-rekomendatsii-dlia-borotby-zi-stresom`,
  RekomendatsiiRodycham: `rekomendatsii-rodycham`,
  BorotbaZiSmutkomAboHoremPisliaVtraty: `borotba-zi-smutkom-abo-horem-pislia-vtraty`,
  PosttravmatychnyiStresovyiRozladPtsr: `posttravmatychnyi-stresovyi-rozlad-PTSR`,
  ProblemyZiSnomYakSobiDopomohty: `problemy-zi-snom-yak-sobi-dopomohty`,
  PsykholohPsykhoterapevtChyPsykhiatr: `psykholoh-psykhoterapevt-chy-psykhiatr`,
  YakAdaptuvatysDoPovsiakdennohoZhyttiaPisliaPovernenniaDodomu: `yak-adaptuvatys-do-povsiakdennoho-zhyttia-pislia-povernennia-dodomu`,
  PosttravmatychnyyStresovyyRozladTaPosttravmatychneZrostannia: `posttravmatychnyyy-stresovyy-rozlad-ta-posttravmatychne-zrostannia`,
  RozmovaProblemyZiSnomYakSobiDopomohty: `rozmova-problemy-zi-snom.-Yak-sobi-dopomohty`,
  SuitsydalniDumkyChomuVynykaiutTaYakSpravytys: `suitsydalni-dumky-chomu-vynykaiut-ta-yak-spravytys`,
  YakVidnovytysiaPisliaKontuzii: `yak-vidnovytysia-pislia-kontuzii`,
  OsobuZInvalidnistiuVnaslidokViiny: `pilhovi-umovy-vstupu-do-vnz-dlia-osobu-z-invalidnistiu-vnaslidok-viiny`,
  DitiamUchasnykivBoiovykhDii: `pilhovi-umovy-vstupu-do-vnz-ditiam-uchasnykiv-boiovykh-dii`,
  UchasnykivBoiovykhDii: `pilhovi-umovy-vstupu-do-vnz-dlia-ubd-uchasnykiv-boiovykh-dii`,
  ZabezpechenniaDodatkovymyZasobamyReabilitatsii: `zabezpechennia-dodatkovymy-zasobamy-reabilitatsii`,
  OtrymanniaNapravlenniaDoReabilitatsiinykhUstanov: `otrymannia-napravlennia-do-reabilitatsiinykh-ustanov`,
  VziattiaNaOblikDliaZabezpechenniaSanatornoKurortnymLikuvanniamPutivkamy: `vziattia-na-oblik-dlia-zabezpechennia-sanatorno-kurortnym-likuvanniam-putivkamy`,
  PilhyNaOplatuZhkpPrydbanniaTverdohoPalyvaTaSkraplenohoHazu: `pilhy-na-oplatu-zhkp-prydbannia-tverdoho-palyva-ta-skraplenoho-hazu`,
  BezoplatneOderzhanniaMedykamentivZaRetseptamyLikarivTaPershocherhoveZuboprotezuvannia: `bezoplatne-oderzhannia-medykamentiv-za-retseptamy-likariv-ta-pershocherhove-zuboprotezuvannia`,
  BezoplatnePokhovanniaPomerlykhZahyblykhVeteranivViinyZakhysnykivIZakhysnytsUkrainy: `bezoplatne-pokhovannia-pomerlykh-zahyblykh-veteraniv-viiny-zakhysnykiv-i-zakhysnyts-ukrainy`,
  HroshovaKompensatsiiaNaPalneRemontTaTransportneObsluhovuvannia: `hroshova-kompensatsiia-na-palne-remont-ta-transportne-obsluhovuvannia`,
  OtrymanniaAvtomobiliaDliaOsibZInvalidnistiu: `otrymannia-avtomobilia-dlia-osib-z-invalidnistiu`,
  BezoplatnyiProizdTransportom: `bezoplatnyi-proizd-transportom`,
  PilhyYakiMozhutOtrymatyVeteranyViiny: `pilhy-yaki-mozhut-otrymaty-veterany-viiny`
};

export { articlePaths };
export {
  UniversalniRekomendatsiiDliaBorotbyZiStresom,
  RekomendatsiiRodycham,
  BorotbaZiSmutkomAboHoremPisliaVtraty,
  PosttravmatychnyiStresovyiRozladPTSR,
  ProblemyZiSnomYakSobiDopomohty,
  PsykholohPsykhoterapevtChyPsykhiatr,
  YakAdaptuvatysDoPovsiakdennohoZhyttiaPisliaPovernenniaDodomu,
  PosttravmatychnyyStresovyyRozladTaPosttravmatychneZrostannia,
  RozmovaProblemyZiSnomYakSobiDopomohty,
  SuitsydalniDumkyChomuVynykaiutTaYakSpravytys,
  YakVidnovytysiaPisliaKontuzii,
  OsobuZInvalidnistiuVnaslidokViiny,
  DitiamUchasnykivBoiovykhDii,
  UchasnykivBoiovykhDii,
  ZabezpechenniaDodatkovymyZasobamyReabilitatsii,
  OtrymanniaNapravlenniaDoReabilitatsiinykhUstanov,
  VziattiaNaOblikDliaZabezpechenniaSanatornoKurortnymLikuvanniamPutivkamy,
  PilhyNaOplatuZhkpPrydbanniaTverdohoPalyvaTaSkraplenohoHazu,
  BezoplatneOderzhanniaMedykamentivZaRetseptamyLikarivTaPershocherhoveZuboprotezuvannia,
  BezoplatnePokhovanniaPomerlykhZahyblykhVeteranivViinyZakhysnykivIZakhysnytsUkrainy,
  HroshovaKompensatsiiaNaPalneRemontTaTransportneObsluhovuvannia,
  OtrymanniaAvtomobiliaDliaOsibZInvalidnistiu,
  BezoplatnyiProizdTransportom,
  PilhyYakiMozhutOtrymatyVeteranyViiny
};
