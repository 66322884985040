import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import css from './not-found.module.scss';
import { useUpdateMeta } from 'src/hooks/shared-hooks';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { GENERAL } from 'src/constants/routes';

const meta = {
  title: 'Помилка 404 - сторінку не знайдено | Обрій',
  description:
    'Вибачте, але сторінку, яку ви шукали, не знайдено. Будь ласка, перевірте правильність URL або перейдіть на головну сторінку Обрію, щоб знайти корисну інформацію для ветеранів.'
};

export default function NotFound() {
  const { msg, link } = useContext(LanguageSettingsContext);
  useUpdateMeta(meta);

  return (
    <main className={css.container}>
      <div className={css.message}>
        <h1>404</h1>
        <h2>{msg('pageNotFoundTitle')}</h2>
        <h3>
          {msg('pageNotFoundSubTitle')}{' '}
          <Link className={css.hyperlink} to={link(GENERAL.path)}>
            {msg('pageNotFoundLink')}
          </Link>
        </h3>
      </div>
    </main>
  );
}
