import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import SearchInput from 'src/components/form/search-input/search-input';
import EmptyPlaceholder from './empty-placeholder';
import FilterTags from 'src/components/tags/filter-tags';
import Cards from 'src/components/form/search/cards/cards';
import Course from 'src/pages/courses/course';
import PaginatedFooter from 'src/components/paginated-footer/paginated-footer';
import { ReactComponent as Work } from 'src/assets/services/work.svg';
import { ReactComponent as Education } from 'src/assets/services/education.svg';
import { ReactComponent as PsychologicalHelp } from 'src/assets/services/psych.svg';
import { ReactComponent as MedServices } from 'src/assets/services/med.svg';
import { ReactComponent as LegalHelp } from 'src/assets/services/legal.svg';
import { ReactComponent as UsefulInfo } from 'src/assets/services/info.svg';
import { ReactComponent as BusinessMap } from 'src/assets/services/map.svg';
import * as r from 'src/constants/routes';
import EducationAPI from 'src/pages/courses/api/api';
import ServicesApi from 'src/pages/services/api/api';
import css from './global-search.module.scss';
import * as p from 'src/constants/page-classnames';
import { LanguageSettingsContext } from 'src/utils/language-context';
import List from 'src/pages/services/list';

export default function GlobalSearch() {
  const { msg, link, language } = useContext(LanguageSettingsContext);
  const navigate = useNavigate();
  const state = useLocation().state;
  const defaultTagName = { id: -1, name: msg('tagAllCaption'), count: 0 };
  const initialData = useMemo(
    () => ({
      courses: { totalCount: 0, items: [] },
      educationInfos: { totalCount: 0, items: [] },
      careerInfos: { totalCount: 0, items: [] },
      psychologicalHelps: { totalCount: 0, items: [] },
      legalHelps: { totalCount: 0, items: [] },
      usefulInfos: { totalCount: 0, items: [] },
      medicalServices: { totalCount: 0, items: [] },
      vetLocations: { totalCount: 0, items: [] }
    }),
    []
  );

  const skeletons = [...Array(6).keys()].map((i) => <span key={i} className={css.skeleton} />);

  const [searchData, setSearchData] = useState({
    tag: defaultTagName.id,
    filters: { text: state?.searchValue }
  });
  const [categories, setCategories] = useState([]);
  const [results, setResults] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);

  const [tags, setTags] = useState([
    { id: 0, name: 'educationCoursesPageTitle', count: 0 },
    { id: 1, name: 'educationMaterialsPageTitle', count: 0 },
    { id: 2, name: 'careerMaterialsPageTitle', count: 0 },
    { id: 3, name: 'psychologicalHelpPageTitle', count: 0 },
    { id: 4, name: 'legalHelpPageTitle', count: 0 },
    { id: 5, name: 'usefulInfoPageTitle', count: 0 },
    { id: 6, name: 'medicalServicesPageTitle', count: 0 },
    { id: 7, name: 'veteranLocationsPageTitle', count: 0 }
  ]);
  const totalResultsCount =
    results.courses.totalCount +
    results.careerInfos.totalCount +
    results.educationInfos.totalCount +
    results.legalHelps.totalCount +
    results.medicalServices.totalCount +
    results.psychologicalHelps.totalCount +
    results.usefulInfos.totalCount +
    results.vetLocations.totalCount;

  useEffect(() => {
    ServicesApi.getCategories().then(setCategories);
  }, [language]);

  useEffect(() => {
    if (searchData.filters.text?.length === 0) {
      setResults(initialData);
      return;
    }
  }, [searchData.filters, initialData]);

  useEffect(() => {
    if (searchData.filters.text?.length === 0) return;

    setIsLoading(true);
    const educationInfoId = categories.find((c) => c.name === msg(r.EDUCATION_INFO.title))?.id;
    const careerInfoId = categories.find((c) => c.name === msg(r.CAREER_INFO.title))?.id;
    const psychologicalHelpId = categories.find((c) => c.name === msg(r.PSYCHOLOGICAL_HELP.title))?.id;
    const legalHelpId = categories.find((c) => c.name === msg(r.LEGAL_HELP.title))?.id;
    const usefulInfoId = categories.find((c) => c.name === msg(r.USEFUL_INFO.title))?.id;
    const medicalServiceId = categories.find((c) => c.name === msg(r.MEDICAL_SERVICES.title))?.id;
    const vetServiceId = categories.find((c) => c.name === msg(r.VETERAN_LOCATIONS.title))?.id;

    Promise.all([
      EducationAPI.search(1, { limit: 3, text: searchData.filters.text }),
      educationInfoId
        ? ServicesApi.getServicesBlocks(1, { limit: 2, text: searchData.filters.text, categoryId: educationInfoId })
        : undefined,
      careerInfoId
        ? ServicesApi.getServicesBlocks(1, { limit: 2, text: searchData.filters.text, categoryId: careerInfoId })
        : undefined,
      psychologicalHelpId
        ? ServicesApi.getServicesBlocks(1, { limit: 2, text: searchData.filters.text, categoryId: psychologicalHelpId })
        : undefined,
      legalHelpId
        ? ServicesApi.getServicesBlocks(1, { limit: 2, text: searchData.filters.text, categoryId: legalHelpId })
        : undefined,
      usefulInfoId
        ? ServicesApi.getServicesBlocks(1, { limit: 2, text: searchData.filters.text, categoryId: usefulInfoId })
        : undefined,
      medicalServiceId
        ? ServicesApi.getServicesBlocks(1, { limit: 2, text: searchData.filters.text, categoryId: medicalServiceId })
        : undefined,
      vetServiceId
        ? ServicesApi.getServicesBlocks(1, { limit: 2, text: searchData.filters.text, categoryId: vetServiceId })
        : undefined
    ]).then(
      ([
        courses,
        educationInfos,
        careerInfos,
        psychologicalHelps,
        legalHelps,
        usefulInfos,
        medicalServices,
        vetLocations
      ]) => {
        setResults((results) => ({
          ...results,
          courses: courses ?? results.courses,
          educationInfos: educationInfos
            ? { totalCount: educationInfos.totalServicesCount, items: educationInfos.items }
            : results.educationInfos,
          careerInfos: careerInfos
            ? { totalCount: careerInfos.totalServicesCount, items: careerInfos.items }
            : results.careerInfos,
          psychologicalHelps: psychologicalHelps
            ? { totalCount: psychologicalHelps.totalServicesCount, items: psychologicalHelps.items }
            : results.psychologicalHelps,
          legalHelps: legalHelps
            ? { totalCount: legalHelps.totalServicesCount, items: legalHelps.items }
            : results.legalHelps,
          usefulInfos: usefulInfos
            ? { totalCount: usefulInfos.totalServicesCount, items: usefulInfos.items }
            : results.usefulInfos,
          medicalServices: medicalServices
            ? { totalCount: medicalServices.totalServicesCount, items: medicalServices.items }
            : results.medicalServices,
          vetLocations: vetLocations
            ? { totalCount: vetLocations.totalServicesCount, items: vetLocations.items }
            : results.vetLocations
        }));
        setTags((tags) =>
          tags.map((t) => {
            switch (t.id) {
              case 0: {
                return { ...t, count: courses.totalCount };
              }
              case 1: {
                return { ...t, count: educationInfos?.totalServicesCount ?? 0 };
              }
              case 2: {
                return { ...t, count: careerInfos?.totalServicesCount ?? 0 };
              }
              case 3: {
                return { ...t, count: psychologicalHelps?.totalServicesCount ?? 0 };
              }
              case 4: {
                return { ...t, count: legalHelps?.totalServicesCount ?? 0 };
              }
              case 5: {
                return { ...t, count: usefulInfos?.totalServicesCount ?? 0 };
              }
              case 6: {
                return { ...t, count: medicalServices?.totalServicesCount ?? 0 };
              }
              case 7: {
                return { ...t, count: vetLocations?.totalServicesCount ?? 0 };
              }
              default: {
                return t;
              }
            }
          })
        );
      }
    );
    setIsLoading(false);
  }, [searchData.filters, categories, msg, language]);

  function handleSearch(value) {
    setSearchData({ ...searchData, filters: { text: value } });
  }

  function handleTagChange(tag) {
    setSearchData({ tag, filters: { ...searchData.filters } });
  }

  return (
    <main className={css.globalSearchPage}>
      <div className={css.container}>
        <div className={css.filters}>
          <div className={css.searchInputGroup}>
            <SearchInput
              id='global-search'
              name='global-search'
              placeholder={msg('globalSearchPlaceholder')}
              initialValue={searchData.filters.text}
              onSearch={handleSearch}
              className={css.globalSearch}
            />
            {!isLoading && (
              <p>
                {msg('globalSearchResultsMsg')}: {totalResultsCount}
              </p>
            )}
          </div>
          {!isLoading && totalResultsCount > 0 && (
            <FilterTags
              title={msg('globalSearchFilterTagTitle')}
              tags={[defaultTagName.id].concat(tags.filter((t) => t.count > 0).map((t) => t.id))}
              selectedTag={searchData.tag}
              onClick={handleTagChange}
              calcName={(t) => {
                if (t === defaultTagName.id) {
                  return `${defaultTagName.name}: ${totalResultsCount}`;
                }
                const p = tags.find((p) => p.id === t);
                return `${msg(p.name)}: ${p.count}`;
              }}
            />
          )}
        </div>
        {!isLoading && totalResultsCount === 0 && (
          <EmptyPlaceholder
            title={
              searchData.filters.text?.length > 0
                ? msg('globalSearchEmptyPlaceholderTitle1')
                : msg('globalSearchEmptyPlaceholderTitle2')
            }
          />
        )}
        {!isLoading && totalResultsCount > 0 && (
          <div className={css.list}>
            {(searchData.tag === -1 || searchData.tag === 0) && results.courses.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.education)}>
                  <div className={css.icon}>
                    <Education />
                  </div>
                  <p>
                    {msg('educationPageTitle')}: {msg('educationCoursesPageTitle')}
                  </p>
                </div>
                <Cards className={css.courses}>
                  {results.courses.items.map((course) => (
                    <Course key={course.id} course={course} />
                  ))}
                </Cards>
                {results.courses.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.courses.totalCount}`}
                    onClick={() =>
                      navigate(link(r.EDUCATION_COURSES.path), { state: { text: searchData.filters.text } })
                    }
                  />
                )}
              </div>
            )}
            {(searchData.tag === -1 || searchData.tag === 1) && results.educationInfos.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.education)}>
                  <div className={css.icon}>
                    <Education />
                  </div>
                  <p>
                    {msg('educationPageTitle')}: {msg('educationMaterialsPageTitle')}
                  </p>
                </div>
                <List servicesLimit={3} servicesBlocks={results.educationInfos.items} className={p.EDUCATION} />
                {results.educationInfos.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.educationInfos.totalCount}`}
                    onClick={() => navigate(link(r.EDUCATION_INFO.path), { state: { text: searchData.filters.text } })}
                  />
                )}
              </div>
            )}
            {(searchData.tag === -1 || searchData.tag === 2) && results.careerInfos.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.work)}>
                  <div className={css.icon}>
                    <Work />
                  </div>
                  <p>
                    {msg('workPageTitle')}: {msg('careerMaterialsPageTitle')}
                  </p>
                </div>
                <List servicesLimit={3} servicesBlocks={results.careerInfos.items} className={p.CAREER_INFO} />
                {results.careerInfos.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.careerInfos.totalCount}`}
                    onClick={() => navigate(link(r.CAREER_INFO.path), { state: { text: searchData.filters.text } })}
                  />
                )}
              </div>
            )}
            {(searchData.tag === -1 || searchData.tag === 3) && results.psychologicalHelps.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.psych)}>
                  <div className={css.icon}>
                    <PsychologicalHelp />
                  </div>
                  <p>{msg('psychologicalHelpPageTitle')}</p>
                </div>
                <List
                  servicesLimit={3}
                  servicesBlocks={results.psychologicalHelps.items}
                  className={p.PSYCHOLOGICAL_HELP}
                />
                {results.psychologicalHelps.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.psychologicalHelps.totalCount}`}
                    onClick={() =>
                      navigate(link(r.PSYCHOLOGICAL_HELP.path), { state: { text: searchData.filters.text } })
                    }
                  />
                )}
              </div>
            )}
            {(searchData.tag === -1 || searchData.tag === 4) && results.legalHelps.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.legal)}>
                  <div className={css.icon}>
                    <LegalHelp />
                  </div>
                  <p>{msg('legalHelpPageTitle')}</p>
                </div>
                <List servicesLimit={3} servicesBlocks={results.legalHelps.items} className={p.LEGAL_HELP} />
                {results.legalHelps.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.legalHelps.totalCount}`}
                    onClick={() => navigate(link(r.LEGAL_HELP.path), { state: { text: searchData.filters.text } })}
                  />
                )}
              </div>
            )}
            {(searchData.tag === -1 || searchData.tag === 5) && results.usefulInfos.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.usefulInfo)}>
                  <div className={css.icon}>
                    <UsefulInfo />
                  </div>
                  <p>{msg('usefulInfoPageTitle')}</p>
                </div>
                <List servicesLimit={3} servicesBlocks={results.usefulInfos.items} className={p.USEFUL_INFO} />
                {results.usefulInfos.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.usefulInfos.totalCount}`}
                    onClick={() => navigate(link(r.USEFUL_INFO.path), { state: { text: searchData.filters.text } })}
                  />
                )}
              </div>
            )}
            {(searchData.tag === -1 || searchData.tag === 6) && results.medicalServices.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.med)}>
                  <div className={css.icon}>
                    <MedServices />
                  </div>
                  <p>{msg('medicalServicesPageTitle')}</p>
                </div>
                <List servicesLimit={3} servicesBlocks={results.medicalServices.items} className={p.MEDICAL_SERVICES} />
                {results.medicalServices.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.medicalServices.totalCount}`}
                    onClick={() =>
                      navigate(link(r.MEDICAL_SERVICES.path), { state: { text: searchData.filters.text } })
                    }
                  />
                )}
              </div>
            )}
            {(searchData.tag === -1 || searchData.tag === 7) && results.vetLocations.totalCount > 0 && (
              <div className={css.searchResultsGroup}>
                <div className={classNames(css.header, css.vet)}>
                  <div className={css.icon}>
                    <BusinessMap />
                  </div>
                  <p>{msg('veteranLocationsPageTitle')}</p>
                </div>
                <List servicesLimit={3} servicesBlocks={results.vetLocations.items} className={p.VETERAN_LOCATIONS} />
                {results.vetLocations.totalCount > 3 && (
                  <PaginatedFooter
                    caption={`${msg('globalSearchPaginationMsg')}: ${results.vetLocations.totalCount}`}
                    onClick={() =>
                      navigate(link(r.VETERAN_LOCATIONS.path), { state: { text: searchData.filters.text } })
                    }
                  />
                )}
              </div>
            )}
          </div>
        )}
        {isLoading && <div className={css.skeletons}>{skeletons}</div>}
      </div>
    </main>
  );
}
