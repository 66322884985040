import React from 'react';
import PropTypes from 'prop-types';
import css from './card-body.module.scss';
import classNames from 'classnames';

CardBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  overflowEllipsis: PropTypes.bool
};

export default function CardBody({ children, className, overflowEllipsis }) {
  return (
    <div className={classNames(css.cardBody, className)}>
      <div className={overflowEllipsis ? css.containerEllipsis : css.container}>{children}</div>
    </div>
  );
}
