const accessibilityModeKey = 'AccessibilityMode';
const accessibilityModeFontSizeKey = 'AccessibilityModeFontSize';
const languageKey = 'ObriiLanguage';

export function persistAccessibility(accessibilityMode, fontSize) {
  window.localStorage?.setItem(accessibilityModeKey, accessibilityMode);
  window.localStorage?.setItem(accessibilityModeFontSizeKey, fontSize);
}

export function readAccessibility(minFontSize) {
  const isAccessibilityMode = window.localStorage?.getItem(accessibilityModeKey) === 'true';
  const fontSize = Number.parseInt(window.localStorage?.getItem(accessibilityModeFontSizeKey) ?? '');

  return {
    isAccessibilityMode,
    fontSize: isNaN(fontSize) ? minFontSize : fontSize
  };
}

export function setHtmlFontSize(fontSize) {
  const html = document.getElementsByTagName('html')[0];
  html.style.fontSize = fontSize + 'pt';
}

export function persistLanguage(language) {
  window.sessionStorage?.setItem(languageKey, language);
}

export function readLanguage() {
  return {
    language: window.sessionStorage?.getItem(languageKey)
  };
}
