function getNameById(values, id) {
  return values.find((v) => v.id === parseInt(id))?.name;
}

function filterValuesById(values, id) {
  return values.filter((v) => v !== id);
}

function openInNewTab(url) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

function openInSameTab(url) {
  window.open(url, '_self');
}

export { getNameById, filterValuesById, openInNewTab, openInSameTab };
