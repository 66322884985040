export const messages = {
  en: {
    weekDayShort0: 'mo',
    weekDayShort1: 'tu',
    weekDayShort2: 'we',
    weekDayShort3: 'th',
    weekDayShort4: 'fr',
    weekDayShort5: 'sa',
    weekDayShort6: 'su',
    weekDayFull0: 'Monday',
    weekDayFull1: 'Tuesday',
    weekDayFull2: 'Wednesday',
    weekDayFull3: 'Thursday',
    weekDayFull4: 'Friday',
    weekDayFull5: 'Saturday',
    weekDayFull6: 'Sunday',
    monthGcase0: 'January',
    monthGcase1: 'February',
    monthGcase2: 'March',
    monthGcase3: 'April',
    monthGcase4: 'May',
    monthGcase5: 'June',
    monthGcase6: 'July',
    monthGcase7: 'August',
    monthGcase8: 'September',
    monthGcase9: 'October',
    monthGcase10: 'November',
    monthGcase11: 'December',
    month0: 'January',
    month1: 'February',
    month2: 'March',
    month3: 'April',
    month4: 'May',
    month5: 'June',
    month6: 'July',
    month7: 'August',
    month8: 'September',
    month9: 'October',
    month10: 'November',
    month11: 'December',
    today: 'Today',
    tomorrow: 'Tomorrow',
    pickerYear: 'Select a year',
    pickerMonth: 'Select a month',
    eventsEmptyPlaceholderTitle: "It's currently empty",
    eventsEmptyPlaceholderSubtitle: 'Come back a little later, something will definitely appear'
  },
  default: {
    weekDayShort0: 'пн',
    weekDayShort1: 'вт',
    weekDayShort2: 'ср',
    weekDayShort3: 'чт',
    weekDayShort4: 'пт',
    weekDayShort5: 'сб',
    weekDayShort6: 'нд',
    weekDayFull0: 'Понеділок',
    weekDayFull1: 'Вівторок',
    weekDayFull2: 'Середа',
    weekDayFull3: 'Четвер',
    weekDayFull4: "П'ятниця",
    weekDayFull5: 'Субота',
    weekDayFull6: 'Неділя',
    monthGcase0: 'Січня',
    monthGcase1: 'Лютого',
    monthGcase2: 'Березня',
    monthGcase3: 'Квітня',
    monthGcase4: 'Травня',
    monthGcase5: 'Червня',
    monthGcase6: 'Липня',
    monthGcase7: 'Серпня',
    monthGcase8: 'Вересня',
    monthGcase9: 'Жовтня',
    monthGcase10: 'Листопада',
    monthGcase11: 'Грудня',
    month0: 'Січень',
    month1: 'Лютий',
    month2: 'Березень',
    month3: 'Квітень',
    month4: 'Травень',
    month5: 'Червень',
    month6: 'Липень',
    month7: 'Серпень',
    month8: 'Вересень',
    month9: 'Жовтень',
    month10: 'Листопад',
    month11: 'Грудень',
    today: 'Сьогодні',
    tomorrow: 'Завтра',
    pickerYear: 'Обрати рік',
    pickerMonth: 'Обрати місяць',
    eventsEmptyPlaceholderTitle: 'Наразі тут пусто',
    eventsEmptyPlaceholderSubtitle: 'Завітайте трохи згодом, щось обов’язково з’явиться'
  }
};
