import React from 'react';
import PropTypes from 'prop-types';
import css from './card-header.module.scss';
import classNames from 'classnames';

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default function CardHeader({ children, className }) {
  return <div className={classNames(css.cardHeader, className)}>{children}</div>;
}
