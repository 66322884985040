import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toggle from 'src/components/toggle/toggle';
import { MONTHS } from 'src/constants/calendar';
import css from './picker-popup.module.scss';
import { EVENTS } from 'src/constants/page-classnames';
import { useOutsideClickTracker } from 'src/hooks/shared-hooks';
import { ReactComponent as CalendarArrowDown } from 'src/assets/calendar-arrow-down.svg';
import { LanguageSettingsContext } from 'src/utils/language-context';

PickerPopup.propTypes = {
  currentYear: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired,
  currentMonth: PropTypes.number.isRequired,
  selectedMonth: PropTypes.number.isRequired,
  onYearSelect: PropTypes.func.isRequired,
  onMonthSelect: PropTypes.func.isRequired
};

export default function PickerPopup({
  currentYear,
  selectedYear,
  currentMonth,
  selectedMonth,
  onYearSelect,
  onMonthSelect
}) {
  const { msg } = useContext(LanguageSettingsContext);
  const containerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [monthView, setMonthView] = useState(false);
  const yearsRange = Array.from({ length: 2100 - currentYear }, (_, i) => currentYear + i);

  useOutsideClickTracker(containerRef, () => {
    if (showPicker) {
      onClose();
    }
  });

  function handleSelectYear(year) {
    if (year < currentYear || (year === currentYear && selectedYear === year)) return;

    onClose();
    onYearSelect(year);
  }

  function handleSelectMonth(month) {
    if (selectedYear === currentYear && (month < currentMonth || selectedMonth === month)) return;

    onClose();
    onMonthSelect(month);
  }

  function onClose() {
    setShowPicker(false);
    setMonthView(false);
  }

  function onPickerClick() {
    if (showPicker) {
      onClose();
      return;
    }
    setShowPicker(true);
  }

  return (
    <div ref={containerRef} className={css.pickerPopupContainer}>
      <p onClick={onPickerClick} className={classNames(css.calendarPicker, showPicker ? css.pickerVisible : '')}>
        {msg(MONTHS[selectedMonth])} {selectedYear} <CalendarArrowDown />
      </p>
      {showPicker && (
        <div className={css.pickerPopup}>
          <div className={css.innerContainer}>
            {!monthView && (
              <div className={css.years}>
                <div className={css.container}>
                  {yearsRange.map((year) => (
                    <p
                      key={year}
                      className={selectedYear === year ? css.selected : ''}
                      onClick={() => handleSelectYear(year)}>
                      {year}
                    </p>
                  ))}
                </div>
              </div>
            )}
            {monthView && (
              <div className={css.months}>
                <div className={css.container}>
                  {MONTHS.map((month, i) => (
                    <p
                      key={i}
                      className={classNames(
                        selectedMonth === i ? css.selected : '',
                        currentMonth > i && selectedYear === currentYear ? css.disabled : ''
                      )}
                      onClick={() => handleSelectMonth(i)}>
                      {msg(month)}
                    </p>
                  ))}
                </div>
              </div>
            )}
            <div className={css.footer}>
              <Toggle
                className={EVENTS}
                onToggleLeft={() => setMonthView(false)}
                onToggleRight={() => setMonthView(true)}
                labelLeft={msg('pickerYear')}
                labelRight={msg('pickerMonth')}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
