import React, { useId, useContext } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { uk } from 'date-fns/locale';
import { ukUA } from '@mui/x-date-pickers/locales';
import './date-time-picker.scss';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { LanguageSettingsContext } from 'src/utils/language-context';

DateTimePicker.propTypes = {
  control: PropTypes.object.isRequired,
  triggerValidation: PropTypes.func.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired
};

export default function DateTimePicker({ control, triggerValidation, name, required = false }) {
  const datetimeInputId = useId();
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={uk}
      localeText={ukUA.components.MuiLocalizationProvider.defaultProps.localeText}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <MuiDateTimePicker
            {...field}
            label={msg('dateTimePickerLabel')}
            format='dd.MM.yyyy HH:mm'
            ampm={false}
            disablePast
            showDaysOutsideCurrentMonth
            value={field.value}
            onChange={(date) => field.onChange(date)}
            onAccept={() => triggerValidation(name)}
            slotProps={{
              textField: {
                id: datetimeInputId,
                error: !!error,
                helperText: error?.message,
                className: 'dateTimePicker',
                required: required,
                onBlur: () => triggerValidation(name)
              },
              tabs: {
                hidden: false
              },
              actionBar: ({ wrapperVariant }) => ({
                actions: wrapperVariant === 'mobile' ? ['cancel', 'accept'] : []
              })
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
