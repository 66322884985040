import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { EDUCATION_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function OsobuZInvalidnistiuVnaslidokViiny() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(EDUCATION_INFO.path)}>
      <h1>Пільгові умови вступу до ВНЗ - для особи з інвалідністю внаслідок війни</h1>
      <p>
        <b>Державна підтримка передбачає:</b>
      </p>
      <ol>
        <li>
          Повну або часткову оплату навчання у разі зарахування на місце державного (регіонального) замовлення
          відповідно до умов та правил прийому;
        </li>
        <li>Соціальні стипендії;</li>
        <li>Безоплатне забезпечення підручниками;</li>
        <li>Пільгові довгострокові кредити для здобуття освіти;</li>
        <li>Безоплатне проживання в гуртожитку.</li>
      </ol>
      <p>
        <b>Процедура вступу</b>
      </p>
      <ol>
        <li>Особа здає вступні випробування (в окремих випадках проходить співбесіду) на рівні з усіма;</li>
        <li>
          Якщо особа не вступила самостійно на місце державного чи регіонального замовлення – потрібно написати заяву на
          ім’я ректора з проханням перевести на вакантне місце державного або регіонального замовлення.
        </li>
      </ol>
      <p>
        Якщо в університеті за спеціальністю, на яку ви бажаєте вступити немає бюджетних місць, тоді обирайте іншу
        спеціальність за якою такі місця передбачені.
      </p>
      <p>
        Особи з інвалідністю внаслідок війни НЕ ПЕРЕВОДЯТЬСЯ на бюджетні місця, вони ПОВИННІ САМОСТІЙНО ВСТУПИТИ, тоді
        держава буде повністю компенсувати вартість навчання. Особи з інвалідністю внаслідок війни вступають
        першочергово на бюджетні місця.
      </p>
      <p>
        Здобути певний освітньо-кваліфікаційний рівень за кошти державного та/або місцевого бюджету громадяни України
        можуть лише один раз, проте ветерани можуть отримати іншу спеціальність на основі здобутого раніше освітнього
        (освітньо-кваліфікаційного) рівня в порядку державної програми професійної адаптації шляхом перекваліфікації
        (див. розділ нижче). Тобто особа може здобути другу спеціальність безкоштовно.
      </p>
      <p>
        <b>Вступні іспити та співбесіда</b>
      </p>
      <p>Особи з інвалідністю внаслідок війни проходять вступні випробування у формі співбесіди.</p>
      <ul>
        <li>Пільги поширюються при вступі на бакалаврат і магістратуру;</li>
        <li>Навчатися за кошти державного та/або місцевого бюджету громадяни України можуть лише один раз.</li>
      </ul>
      <p>
        Соціальна стипендія надається тільки для тих осіб, які вже зараховані на навчання на денну форму за державним чи
        регіональним замовленням.
      </p>
      <p>Проживання в гуртожитках для осіб з інвалідністю безоплатно.</p>
      <p>Якщо особа має право на дві стипендії: академічну і соціальну, то потрібно вибрати один вид.</p>
      <p>
        <b>Отримання пільги</b>
      </p>
      <p>Для отримання зазначеної пільги необхідно особисто подати до навчально закладу:</p>
      <ul>
        <li>Заяву, в котрій вказати конкретний вид державної цільової допомоги, на яку ви претендуєте;</li>
        <li>Оригінали та завірені копії документів, що підтверджують право на отримання пільги.</li>
      </ul>
      <p>
        <b>Необхідні документи</b>
      </p>
      <p>
        Документи, які підтверджують право на одержання державної цільової підтримки - посвідчення особи з інвалідністю
        внаслідок війни.
      </p>
      <p>
        <b>У разі відмови</b>
      </p>
      <ol>
        <li>Звернутися безпосередньо до ректора (директора) навчального закладу;</li>
        <li>Звернутися до Міністерства освіти та науки України зі скаргою.</li>
      </ol>
      <p>
        <b>Використані матеріали:</b>
      </p>
      <ol>
        <li>
          <a
            target='_blank'
            href='https://legal100.org.ua/wp-content/uploads/2020/07/2020_pilgi_na_zdobuttya_osviti.pdf'
            rel='noreferrer'>
            https://legal100.org.ua/wp-content/uploads/2020/07/2020_pilgi_na_zdobuttya_osviti.pdf
          </a>
          ;
        </li>
        <li>
          Зібрана інформація командою студентів та викладачів Київського національного університету імені Тараса
          Шевченка;
        </li>
        <li>На основі: ч.7 ст.12; п.28 ч.1 ст.13 ЗУ «Про статус ветеранів війни, гарантії їх соціального захисту»;</li>
        <li>
          Порядок надання підтримки затверджений постановою Кабінету Міністрів України від 23 листопада 2016 р. № 975
          Порядок прийому до ЗВО у 2020 році затверджений Наказ МОН від 11.10.2019 року №1285 «Про затвердження Умов
          прийому на навчання до закладів вищої освіти України в 2020 році».
        </li>
      </ol>
    </Article>
  );
}
