import { useEffect, useRef, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as routes from 'src/constants/routes';
import { env } from 'src/constants/env';

export function useOutsideClickTracker(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export function useDebounce() {
  const TIMEOUT = 300,
    currentTimeout = useRef();

  const debounce = useCallback(function debounce(func) {
    if (currentTimeout.current !== undefined) {
      window.clearTimeout(currentTimeout.current);
    }

    currentTimeout.current = window.setTimeout(func, TIMEOUT);
  }, []);

  return debounce;
}

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function useResize() {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  const handleResize = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
}

export function useUpdateRoutesMeta() {
  const location = useLocation();

  const metaGoogleSiteVerification = document.querySelector('meta[name="google-site-verification"]');
  metaGoogleSiteVerification.content = env.REACT_APP_OBRII_GOOGLE_SITE_VERIFICATION ?? '';

  useEffect(() => {
    const meta = routes.routes.find((r) => r.path === location.pathname)?.meta;

    if (!meta) return;

    updateMetaInfo(meta);
  }, [location.pathname]);
}

export function useUpdateMeta(meta) {
  useEffect(() => {
    updateMetaInfo(meta);
  }, [meta]);
}

export function updateMetaInfo(meta) {
  document.title = meta.title;
  const metaDescription = document.querySelector('meta[name="description"]');
  metaDescription.content = meta.description;

  if (meta.openGraph) {
    const title = document.querySelector('meta[property="og:title"]');
    title.content = meta.title;

    const image = document.querySelector('meta[property="og:image"]');
    image.content = window.location.origin + meta.openGraph.img;

    const description = document.querySelector('meta[property="og:description"]');
    description.content = meta.description;

    const url = document.querySelector('meta[property="og:url"]');
    url.content = window.location.href;

    const type = document.querySelector('meta[property="og:type"]');
    type.content = meta.openGraph.type;
  }
}
