import React from 'react';
import ReactDOM from 'react-dom/client';
import { LanguageSettings } from './utils/language-context';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './hooks/shared-hooks';
import { ToastContainer } from 'react-toastify';
import Layout from 'src/components/layout/layout';
import 'react-toastify/dist/ReactToastify.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <ToastContainer autoClose={2000} hideProgressBar={true} />
    <ScrollToTop />
    <LanguageSettings>
      <Layout>
        <App />
      </Layout>
    </LanguageSettings>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
