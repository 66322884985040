import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import 'src/utils/popup.scss';

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  nested: PropTypes.bool,
  closeOnDocumentClick: PropTypes.bool,
  isOpen: PropTypes.bool
};

export default function Modal({ children, trigger, onClose, nested, closeOnDocumentClick, isOpen }) {
  if (isOpen) {
    handleOpen();
  }

  function handleClose() {
    const scrollArrow = document.getElementById('scroll-arrow');
    if (scrollArrow) {
      scrollArrow.style.display = 'flex';
    }
    onClose?.();
  }

  function handleOpen() {
    const scrollArrow = document.getElementById('scroll-arrow');
    if (scrollArrow) {
      scrollArrow.style.display = 'none';
    }
  }

  return (
    <Popup
      modal
      lockScroll
      open={isOpen}
      closeOnDocumentClick={closeOnDocumentClick}
      trigger={trigger}
      onClose={handleClose}
      onOpen={handleOpen}
      overlayStyle={{ overflowY: 'auto', zIndex: '1001' }}
      nested={nested}>
      {children}
    </Popup>
  );
}
