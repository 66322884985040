import React, { useContext, useState } from 'react';
import { LanguageSettingsContext } from 'src/utils/language-context';
import PageHeader from 'src/components/page-header/page-header';
import FilterTags from 'src/components/tags/filter-tags';
import { PARTNERSHIP } from 'src/constants/page-classnames';
import { CATEGORIZED_PARTNERS, TAGS } from 'src/constants/partners';
import css from './partnership.module.scss';

export default function Partnership() {
  const { msg } = useContext(LanguageSettingsContext);
  const defaultTagName = 'tagAllCaption';
  const [selectedTag, setSelectedTag] = useState(defaultTagName);

  return (
    <main className={css.partnershipContainer}>
      <PageHeader
        className={PARTNERSHIP}
        title={msg('partnershipPageTitle')}
        description={msg('partnershipPageSubtitle')}
      />
      <div className={css.list}>
        <FilterTags
          title={msg('partnershipFilterTagTitle')}
          tags={[defaultTagName].concat(TAGS)}
          selectedTag={selectedTag}
          onClick={setSelectedTag}
          calcName={msg}
        />
        {CATEGORIZED_PARTNERS.filter((c) =>
          c.logos.some((l) => selectedTag === defaultTagName || l.tag === selectedTag)
        ).map((c) => (
          <div key={c.category} className={css.partnersGroup}>
            <div className={css.header}>
              <p>{msg(c.category)}</p>
            </div>
            <div className={css.logos}>
              {c.logos.filter((l) => selectedTag === defaultTagName || l.tag === selectedTag).map((logo) => logo.logo)}
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}
