import { useEffect, useState, useRef } from 'react';
import { useDebounce } from 'src/hooks/shared-hooks';
import API from 'src/pages/locations-api/api';

export default function useLocations(watchedLocation, defaultLocation) {
  const locationsLimit = 5;
  const [defaultLocations, setDefaultLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const previousLocationRef = useRef(null);
  const debounce = useDebounce();

  useEffect(() => {
    if (previousLocationRef.current !== watchedLocation) {
      debounce(() =>
        API.getLocations(locationsLimit, watchedLocation).then((result) => {
          const locations = result ?? [];

          if (!watchedLocation) {
            const defaultLocations = [defaultLocation].concat(locations);
            setDefaultLocations(defaultLocations);
            setLocations(defaultLocations);
          } else {
            setLocations(locations);
          }
        })
      );
    }
    previousLocationRef.current = watchedLocation;
  }, [watchedLocation, debounce, defaultLocation]);

  return [setLocations, locations, defaultLocations];
}
