import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './toggle.module.scss';
import * as p from 'src/constants/page-classnames';

Toggle.propTypes = {
  className: PropTypes.oneOf([
    p.WORK_SEARCH,
    p.CAREER_INFO,
    p.EDUCATION,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.BUSINESS_MAP,
    p.VETERAN_LOCATIONS,
    p.EVENTS
  ]),
  onToggleLeft: PropTypes.func.isRequired,
  onToggleRight: PropTypes.func.isRequired,
  labelLeft: PropTypes.string.isRequired,
  labelRight: PropTypes.string.isRequired
};

export default function Toggle({ onToggleLeft, onToggleRight, labelLeft, labelRight, className }) {
  const [value, setValue] = useState(true);

  function handleToggle(v) {
    if (value === v) return;

    setValue(v);
    v ? onToggleLeft() : onToggleRight();
  }

  return (
    <div className={classNames(css.toggle, css[className])}>
      <button onClick={() => handleToggle(true)} className={classNames(css.left, value ? css.active : '')}>
        <p>{labelLeft}</p>
      </button>
      <button onClick={() => handleToggle(false)} className={classNames(css.right, !value ? css.active : '')}>
        <p>{labelRight}</p>
      </button>
    </div>
  );
}
