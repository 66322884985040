import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from './footer/footer';
import Navbar from './navbar/navbar';
import Disclaimer from 'src/components/disclaimer/disclaimer';
import Bar from 'src/components/common/header/bar';
import { LanguageSettingsContext } from 'src/utils/language-context';
import * as routes from 'src/constants/routes';
import css from './layout.module.scss';
import ScrollArrow from '../scroll-arrow/scroll-arrow';

Layout.propTypes = {
  children: PropTypes.node
};

export default function Layout({ children }) {
  const { link } = useContext(LanguageSettingsContext);
  const location = useLocation();
  const showDisclaimer =
    location.pathname.startsWith(link(routes.CAREER_INFO.path)) ||
    location.pathname.startsWith(link(routes.EDUCATION.path)) ||
    location.pathname.startsWith(link(routes.PSYCHOLOGICAL_HELP.path)) ||
    location.pathname.startsWith(link(routes.MEDICAL_SERVICES.path)) ||
    location.pathname.startsWith(link(routes.LEGAL_HELP.path)) ||
    location.pathname.startsWith(link(routes.USEFUL_INFO.path)) ||
    location.pathname.startsWith(link(routes.BUSINESS_MAP.path)) ||
    location.pathname.startsWith(link(routes.EVENTS.path));

  return (
    <div className={css.layout}>
      <Bar />
      <Navbar />
      <div className={css.content}>
        {children}
        <Footer />
        {showDisclaimer && <Disclaimer />}
        <ScrollArrow />
      </div>
    </div>
  );
}
