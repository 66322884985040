import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './toggle-inline.module.scss';

ToggleInline.propTypes = {
  onToggle: PropTypes.func.isRequired,
  labelLeft: PropTypes.string.isRequired,
  labelRight: PropTypes.string.isRequired
};

export default function ToggleInline({ onToggle, labelLeft, labelRight }) {
  const [value, setValue] = useState(false);

  function handleToggle(val) {
    if (val === value) return;

    setValue(val);
    onToggle(val);
  }

  return (
    <div className={css.toggle}>
      <button onClick={() => handleToggle(false)} className={!value ? css.active : ''}>
        <p>{labelLeft}</p>
      </button>
      <button onClick={() => handleToggle(true)} className={value ? css.active : ''}>
        <p>{labelRight}</p>
      </button>
    </div>
  );
}
