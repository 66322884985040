import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';
import qs from 'qs';

const workApiUrl = '/WorkVacancies';

const ApiUrls = {
  workApiUrl
};

function GetSearchRequest(page = 1, limit = 6, query = null) {
  return obriyApiAxios
    .get(workApiUrl, {
      params: { ...query, page, limit },
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { allowDots: true });
        }
      }
    })
    .then((value) => value.data);
}

const API = {
  search: GetSearchRequest
};

export { ApiUrls };
export default API;
