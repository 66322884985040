import axios from 'axios';
import { toast } from 'react-toastify';
import { readLanguage } from 'src/components/common/header/helper';

const create = (baseUrl) => {
  const obriyAxios = axios.create({
    baseURL: baseUrl,
    headers: {
      post: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      patch: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      put: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      common: {
        'X-OBRIY-API-VERSION': '1.0'
      }
    }
  });

  obriyAxios.clearBaseURL = () => (obriyAxios.defaults.baseURL = '');

  obriyAxios.interceptors.request.use((request) => {
    const language = readLanguage();

    if (language?.language) {
      request.headers.set('User-Language', language.language);
    }

    return request;
  });

  obriyAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) {
        toast.error('Сталась помилка зʼєднання. Спробуйте ще раз.');
      } else {
        toast.error(error);
        return Promise.reject(error);
      }
    }
  );

  return obriyAxios;
};

const obriyApiAxios = create(process.env?.REACT_APP_API_BASE_URL);

export { obriyApiAxios };
