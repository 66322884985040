import React, { useContext } from 'react';
import css from './empty-placeholder.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function EmptyPlaceholder() {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.container}>
      <h2>{msg('eventsEmptyPlaceholderTitle')}</h2>
      <p>{msg('eventsEmptyPlaceholderSubtitle')}</p>
    </div>
  );
}
