import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function PilhyYakiMozhutOtrymatyVeteranyViiny() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>
        Пільги, які можуть отримати ветерани війни, учасники бойових дій, учасники війни, особи з інвалідністю внаслідок
        війни, особи, які мають особливі заслуги перед Батьківщиною, члени сім’ї загиблого:
      </h3>
      <p>
        <b>Медична і фармацевтична допомога:</b>
      </p>
      <ul>
        <li>
          Безоплатне отримання ліків, лікарських засобів, імунобіологічних препаратів та виробів медичного призначення
          за рецептами лікарів;
        </li>
        <li>Безоплатне зубопротезування (за винятком дорогоцінних матеріалів);</li>
        <li>Санаторно-курортне лікування або компенсація витрат на нього.</li>
      </ul>
      <p>
        <b>Транспорт:</b>
      </p>
      <ul>
        <li>Безплатний проїзд у різних видах транспорту, включаючи міський, автобусний, залізничний та водний.</li>
      </ul>
      <p>
        <b>Житло і будівництво:</b>
      </p>
      <ul>
        <li>
          Знижка на оплату ЖКП та скрапленим балонним газом для побутових потреб в межах середніх норм споживання;
        </li>
        <li>
          Знижка вартості палива (в межах встановлених для продажу населенню норм) для осіб, які проживають у будинках
          без центрального опалення;
        </li>
        <li>Позачерговий безплатний капітальний ремонт житла та його першочерговий поточний ремонт;</li>
        <li>Щорічний медичний огляд та обстеження, виконання плану медичних і соціальних заходів;</li>
        <li>Пріоритетне обслуговування в лікарнях та аптеках; першочергова госпіталізація;</li>
        <li>
          Першочергове забезпечення: житловою площею осіб, які потребують поліпшення житлових умов, земельними ділянками
          (для індивідуального житлового будівництва, садівництва і городництва), ремонтом житла, паливом;
        </li>
        <li>
          Позики на будівництво, реконструкцію або капітальний ремонт житлових будинків і подвірних будівель, приєднання
          їх до інженерних мереж, комунікацій, а також позики на будівництво або придбання дачних будинків і благоустрій
          садових ділянок;
        </li>
        <li>
          Першочергове право на вступ до житлових кооперативів та з будівництва й експлуатації колективних гаражів, до
          садівницьких товариств, на придбання матеріалів для індивідуального будівництва і садових будинків,
          техобслуговування та стоянку транспортних засобів;
        </li>
        <li>Позачергове безплатне встановлення квартирних телефонів та користування всіма послугами зв&apos;язку.</li>
      </ul>
      <p>
        <b>Робота:</b>
      </p>
      <ul>
        <li>
          Використання чергової відпустки у зручний для них час; одержання додаткової відпустки без збереження
          заробітної плати строком до двох тижнів на рік.
        </li>
      </ul>
      <p>
        <b>Соціальні послуги та підтримка:</b>
      </p>
      <ul>
        <li>Пріоритетне обслуговування у закладах громадського харчування; службами ЖКГ, у міжміському транспорті;</li>
        <li>Позачергове отримання соціальних послуг із догляду;</li>
        <li>Достроковий вихід на пенсію за віком та підвищення пенсій.</li>
      </ul>
      <h3>
        Виплати, які можуть отримати ветерани війни, учасники бойових дій, учасники війни, особи з інвалідністю
        внаслідок війни, особи, які мають особливі заслуги перед Батьківщиною, члени сім’ї загиблого:
      </h3>
      <ul>
        <li>
          Щомісячна грошова виплата військовим та ветеранам за особливі заслуги та участь у захисті суверенітету і
          територіальної цілісності України;
        </li>
        <li>Соціальна допомога військовим та ветеранам за особливі заслуги перед Батьківщиною;</li>
        <li>
          Щомісячна грошова виплата членам сім&apos;ї загиблих військових, які мають особливі заслуги перед
          Батьківщиною;
        </li>
        <li>Виплата допомоги у зв’язку з тимчасовою непрацездатністю;</li>
        <li>Одноразова грошова винагорода тим, кому присвоєно звання Героя України;</li>
        <li>Одноразова виплата до дня Незалежності України ветеранам війни та жертвам нацистських переслідувань.</li>
      </ul>
    </Article>
  );
}
