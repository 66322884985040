import Leaflet from 'leaflet';

export function getMapIcon(iconUrl, iconSize = 1) {
  return Leaflet.icon({
    iconUrl,
    iconSize: [32 * iconSize, 32 * iconSize],
    iconAnchor: [16 * iconSize, 32 * iconSize],
    popupAnchor: [0, -44],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });
}
