import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';

const feedbackApiUrl = '/Feedback';

const PostFeedbackRequest = (data) => obriyApiAxios.post(feedbackApiUrl, data);

const API = {
  feedback: PostFeedbackRequest
};

export default API;
