import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import css from './locations-input.module.scss';
import SearchSelectInput from 'src/components/form/search-select-input/search-select-input';
import { ReactComponent as LocationIcon } from 'src/assets/location.svg';
import { ReactComponent as ArrowDown } from 'src/assets/arrow-down.svg';
import useLocations from './hooks';
import classNames from 'classnames';

LocationsInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  triggerValidation: PropTypes.func,
  clearErrors: PropTypes.func,
  defaultLocation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  showIcons: PropTypes.bool,
  inputProps: PropTypes.object
};

export default function LocationsInput({
  id,
  name,
  placeholder,
  required,
  triggerValidation,
  clearErrors,
  defaultLocation,
  showIcons = true,
  inputProps
}) {
  const { field, fieldState } = useController({ name });
  const [location, setLocation] = useState('');
  const [setLocations, locations, defaultLocations] = useLocations(location, defaultLocation);
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setLocations(defaultLocations);
    setIsOpen(true);
  }

  function handleClose() {
    setLocations([]);
    setIsOpen(false);
  }

  return (
    <SearchSelectInput
      id={id}
      name='location'
      placeholder={placeholder}
      entities={locations}
      onOpen={handleOpen}
      onClose={handleClose}
      onBlur={() => triggerValidation?.(name)}
      onSelect={(item) => {
        field.onChange(item?.id);
        triggerValidation?.(name);
      }}
      onChange={(e) => {
        setLocation(e.target.value);
        if (e.target.value?.trim().length === 0) {
          field.onChange('');
        }

        if (fieldState.error) {
          clearErrors?.(name);
        }
      }}
      inputWrapper={(input) => (
        <div className={css.inputGroup} tabIndex={1}>
          {showIcons && <LocationIcon className={css.locationIcon} />}
          {input}
          {showIcons && <ArrowDown className={classNames(css.arrowIcon, isOpen ? css.upArrow : css.downArrow)} />}
        </div>
      )}
      inputProps={inputProps}
      required={required}
    />
  );
}
