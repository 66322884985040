import * as yup from 'yup';
import * as v from 'src/utils/form/validate';

const maxNameLength = 100;
const maxProposalLength = 2000;
export default function getValidationSchema(msg) {
  const validationSchema = yup.object({
    name: v.validateName(yup, msg, msg('formValidationTitleMsg2'), maxNameLength),
    phone: v.validatePhone(yup, msg),
    email: v.validateEmail(yup, msg),
    company: v.validateName(yup, msg, msg('formValidationCompany'), maxNameLength),
    proposal: v.validateDescription(yup, msg, msg('formValidationDescriptionMsg3'), maxProposalLength, false)
  });

  return validationSchema;
}
