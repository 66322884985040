import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';

const locationPropositionsApiUrl = '/BusinessMapLocationPropositions';
const mapApiUrl = '/BusinessMap';

const ApiUrls = {
  mapApiUrl,
  locationPropositionsApiUrl
};

const PostMapLocationPropositionRequest = (data) => obriyApiAxios.post(locationPropositionsApiUrl, data);

const GetBusinessMap = (data) => obriyApiAxios.get(mapApiUrl, { params: data }).then((response) => response.data);

const API = {
  proposeMapLocation: PostMapLocationPropositionRequest,
  getBusinessMap: GetBusinessMap
};

export { ApiUrls };
export default API;
