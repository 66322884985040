import React from 'react';
import PropTypes from 'prop-types';
import css from './project-value.module.scss';

ProjectValue.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default function ProjectValue({ children, icon, title }) {
  return (
    <div className={css.container}>
      <header>
        {icon}
        <h3>{title}</h3>
      </header>
      {children}
    </div>
  );
}
