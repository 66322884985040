import React, { useContext, useRef } from 'react';
import Banner, { bannerType } from 'src/components/banner/banner';
import Roadmap from './roadmap/roadmap';
import ProjectValues from './project-values/project-values';
import Services from './services/services';
import Partners from './partners/partners';
import Customer from './customer/customer';
import { ReactComponent as MoLogo } from 'src/assets/banner-mo-logo.svg';
import css from './general.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';
import ProposePartnershipPopup from 'src/pages/partnership/propose-partnership/propose-partnership';
import Feedback from 'src/pages/feedback/feedback';
import { ReactComponent as LinkArrow } from 'src/assets/services-arrow-btn-2.svg';

export default function General() {
  const { msg } = useContext(LanguageSettingsContext);
  const servicesRef = useRef(null);

  function handleScrollTo() {
    if (!servicesRef.current) return;

    window.scrollTo({ top: servicesRef.current.offsetTop, behavior: 'instant' });
  }

  return (
    <main className={css.general}>
      <Banner backgroundImage={bannerType.general}>
        <h1>{msg('titleBanner1')}</h1>
        <p className={css.subTitle}>{msg('subtitleBanner1')}</p>
        <button onClick={handleScrollTo} className={css.button}>
          <p>{msg('linkBanner1')}</p>
          <LinkArrow className={css.arrow} />
        </button>
      </Banner>
      <Roadmap />
      <ProjectValues />
      <Banner backgroundImage={bannerType.about}>
        <h1>{msg('titleBanner2')}</h1>
        <p className={css.subTitle}>{msg('subtitleBanner2')}</p>
      </Banner>
      <Services ref={servicesRef} />
      <Banner backgroundImage={bannerType.generalUsers}>
        <MoLogo />
        <h1>{msg('titleBanner3')}</h1>
        <p className={css.subTitle}>{msg('subtitleBanner3')}</p>
        <Feedback
          trigger={
            <button className={css.button}>
              <p>{msg('linkBanner3')}</p>
              <LinkArrow className={css.arrow} />
            </button>
          }
        />
      </Banner>
      <Customer />
      <Banner backgroundImage={bannerType.generalPartners}>
        <h1>{msg('titleBanner4')}</h1>
        <ul className={css.subList}>
          <li>{msg('subtitleBanner4msg1')}</li>
          <li>{msg('subtitleBanner4msg2')}</li>
          <li>{msg('subtitleBanner4msg3')}</li>
        </ul>
        <ProposePartnershipPopup
          trigger={
            <button className={css.button}>
              <p>{msg('proposePartnershipBtnMsg')}</p>
              <LinkArrow className={css.arrow} />
            </button>
          }
        />
      </Banner>
      <Partners />
    </main>
  );
}
