import { useEffect, useContext } from 'react';
import { Routes, Route, Navigate, Outlet, useParams } from 'react-router-dom';
import General from './pages/general/general';
import NotFound from './pages/not-found/not-found';
import JobSearch from './pages/job-search/job-search';
import Courses from './pages/courses/courses';
import ServicesPage from './pages/services/services-page';
import Events from './pages/events/events';
import BusinessMap from './pages/business-map/business-map';
import GlobalSearch from './pages/global-search/global-search';
import Partnership from './pages/partnership/partnership';
import * as p from 'src/constants/page-classnames';
import * as a from './pages/articles/articles';
import { useUpdateRoutesMeta } from './hooks/shared-hooks';
import startGoogleAnalytics from './utils/analytics/google';
import * as routes from './constants/routes';
import { LanguageSettingsContext } from './utils/language-context';

const LanguageWrapper = () => {
  const { lang } = useParams();

  return !lang ? <Outlet /> : <Navigate to='/404' />;
};

function App() {
  const { msg } = useContext(LanguageSettingsContext);

  useUpdateRoutesMeta();
  useEffect(() => {
    startGoogleAnalytics();
  }, []);

  return (
    <Routes>
      <Route path={':lang?'} element={<LanguageWrapper />}>
        <Route path={routes.GENERAL.relativePath} element={<General />} />
        <Route path={routes.GLOBAL_SEARCH.relativePath} element={<GlobalSearch />} />
        <Route path={routes.PARTNERSHIP.relativePath} element={<Partnership />} />

        <Route path={routes.EVENTS.relativePath} element={<Events />} />

        <Route path={routes.WORK.relativePath} element={<Outlet />}>
          <Route index element={<Navigate to={routes.WORK_SEARCH.relativePath} replace />} />
          <Route path={routes.WORK_SEARCH.relativePath} element={<JobSearch />} />
          <Route
            path={routes.CAREER_INFO.relativePath}
            element={<ServicesPage pageName={msg(routes.CAREER_INFO.title)} className={p.CAREER_INFO} />}
          />
        </Route>

        <Route path={routes.EDUCATION.relativePath} element={<Outlet />}>
          <Route index element={<Navigate to={routes.EDUCATION_COURSES.relativePath} replace />} />
          <Route path={routes.EDUCATION_COURSES.relativePath} element={<Courses />} />
          <Route path={routes.EDUCATION_INFO.relativePath} element={<Outlet />}>
            <Route
              index
              element={<ServicesPage pageName={msg(routes.EDUCATION_INFO.title)} className={p.EDUCATION} />}
            />
            <Route
              path={a.articlePaths.OsobuZInvalidnistiuVnaslidokViiny}
              element={<a.OsobuZInvalidnistiuVnaslidokViiny />}
            />
            <Route path={a.articlePaths.DitiamUchasnykivBoiovykhDii} element={<a.DitiamUchasnykivBoiovykhDii />} />
            <Route path={a.articlePaths.UchasnykivBoiovykhDii} element={<a.UchasnykivBoiovykhDii />} />
          </Route>
        </Route>

        <Route path={routes.VETERAN.relativePath} element={<Outlet />}>
          <Route index element={<Navigate to={routes.BUSINESS_MAP.relativePath} replace />} />
          <Route path={routes.BUSINESS_MAP.relativePath} element={<BusinessMap />} />
          <Route
            path={routes.VETERAN_LOCATIONS.relativePath}
            element={<ServicesPage pageName={msg(routes.VETERAN_LOCATIONS.title)} className={p.VETERAN_LOCATIONS} />}
          />
        </Route>
        <Route path={routes.PSYCHOLOGICAL_HELP.relativePath} element={<Outlet />}>
          <Route
            index
            element={<ServicesPage pageName={msg(routes.PSYCHOLOGICAL_HELP.title)} className={p.PSYCHOLOGICAL_HELP} />}
          />
          <Route
            path={a.articlePaths.UniversalniRekomendatsiiDliaBorotbyZiStresom}
            element={<a.UniversalniRekomendatsiiDliaBorotbyZiStresom />}
          />
          <Route path={a.articlePaths.RekomendatsiiRodycham} element={<a.RekomendatsiiRodycham />} />
          <Route
            path={a.articlePaths.BorotbaZiSmutkomAboHoremPisliaVtraty}
            element={<a.BorotbaZiSmutkomAboHoremPisliaVtraty />}
          />
          <Route
            path={a.articlePaths.PosttravmatychnyiStresovyiRozladPtsr}
            element={<a.PosttravmatychnyiStresovyiRozladPTSR />}
          />
          <Route path={a.articlePaths.ProblemyZiSnomYakSobiDopomohty} element={<a.ProblemyZiSnomYakSobiDopomohty />} />
          <Route
            path={a.articlePaths.PsykholohPsykhoterapevtChyPsykhiatr}
            element={<a.PsykholohPsykhoterapevtChyPsykhiatr />}
          />
          <Route
            path={a.articlePaths.YakAdaptuvatysDoPovsiakdennohoZhyttiaPisliaPovernenniaDodomu}
            element={<a.YakAdaptuvatysDoPovsiakdennohoZhyttiaPisliaPovernenniaDodomu />}
          />
          <Route
            path={a.articlePaths.PosttravmatychnyyStresovyyRozladTaPosttravmatychneZrostannia}
            element={<a.PosttravmatychnyyStresovyyRozladTaPosttravmatychneZrostannia />}
          />
          <Route
            path={a.articlePaths.RozmovaProblemyZiSnomYakSobiDopomohty}
            element={<a.RozmovaProblemyZiSnomYakSobiDopomohty />}
          />
          <Route
            path={a.articlePaths.SuitsydalniDumkyChomuVynykaiutTaYakSpravytys}
            element={<a.SuitsydalniDumkyChomuVynykaiutTaYakSpravytys />}
          />
          <Route path={a.articlePaths.YakVidnovytysiaPisliaKontuzii} element={<a.YakVidnovytysiaPisliaKontuzii />} />
        </Route>
        <Route
          path={routes.MEDICAL_SERVICES.relativePath}
          element={<ServicesPage pageName={msg(routes.MEDICAL_SERVICES.title)} className={p.MEDICAL_SERVICES} />}
        />
        <Route
          path={routes.LEGAL_HELP.relativePath}
          element={<ServicesPage pageName={msg(routes.LEGAL_HELP.title)} className={p.LEGAL_HELP} />}
        />
        <Route path={routes.USEFUL_INFO.relativePath} element={<Outlet />}>
          <Route index element={<ServicesPage pageName={msg(routes.USEFUL_INFO.title)} className={p.USEFUL_INFO} />} />
          <Route
            path={a.articlePaths.ZabezpechenniaDodatkovymyZasobamyReabilitatsii}
            element={<a.ZabezpechenniaDodatkovymyZasobamyReabilitatsii />}
          />
          <Route
            path={a.articlePaths.OtrymanniaNapravlenniaDoReabilitatsiinykhUstanov}
            element={<a.OtrymanniaNapravlenniaDoReabilitatsiinykhUstanov />}
          />
          <Route
            path={a.articlePaths.VziattiaNaOblikDliaZabezpechenniaSanatornoKurortnymLikuvanniamPutivkamy}
            element={<a.VziattiaNaOblikDliaZabezpechenniaSanatornoKurortnymLikuvanniamPutivkamy />}
          />
          <Route
            path={a.articlePaths.PilhyNaOplatuZhkpPrydbanniaTverdohoPalyvaTaSkraplenohoHazu}
            element={<a.PilhyNaOplatuZhkpPrydbanniaTverdohoPalyvaTaSkraplenohoHazu />}
          />
          <Route
            path={a.articlePaths.BezoplatneOderzhanniaMedykamentivZaRetseptamyLikarivTaPershocherhoveZuboprotezuvannia}
            element={<a.BezoplatneOderzhanniaMedykamentivZaRetseptamyLikarivTaPershocherhoveZuboprotezuvannia />}
          />
          <Route
            path={a.articlePaths.BezoplatnePokhovanniaPomerlykhZahyblykhVeteranivViinyZakhysnykivIZakhysnytsUkrainy}
            element={<a.BezoplatnePokhovanniaPomerlykhZahyblykhVeteranivViinyZakhysnykivIZakhysnytsUkrainy />}
          />
          <Route
            path={a.articlePaths.HroshovaKompensatsiiaNaPalneRemontTaTransportneObsluhovuvannia}
            element={<a.HroshovaKompensatsiiaNaPalneRemontTaTransportneObsluhovuvannia />}
          />
          <Route
            path={a.articlePaths.OtrymanniaAvtomobiliaDliaOsibZInvalidnistiu}
            element={<a.OtrymanniaAvtomobiliaDliaOsibZInvalidnistiu />}
          />
          <Route path={a.articlePaths.BezoplatnyiProizdTransportom} element={<a.BezoplatnyiProizdTransportom />} />
          <Route
            path={a.articlePaths.PilhyYakiMozhutOtrymatyVeteranyViiny}
            element={<a.PilhyYakiMozhutOtrymatyVeteranyViiny />}
          />
        </Route>
      </Route>
      <Route path='404' element={<NotFound />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;
