import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import css from './calendar.module.scss';
import { WEEK_DAYS } from 'src/constants/calendar';
import classNames from 'classnames';
import { ReactComponent as CalendarArrow } from 'src/assets/calendar-arrow.svg';
import PickerPopup from './picker-popup';
import API from '../events-api';
import { LanguageSettingsContext } from 'src/utils/language-context';

EventsCalendar.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  onDateSelect: PropTypes.func.isRequired
};

export default function EventsCalendar({ currentDate, onDateSelect }) {
  const { msg } = useContext(LanguageSettingsContext);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const [selectedDay, setSelectedDay] = useState(day);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [selectedYear, setSelectedYear] = useState(year);

  const rows = useGenerateCalendar(selectedYear, selectedMonth, currentDate);

  function handleSelectDay(date) {
    const selectedDay = date.getDate();
    const dateFrom = date.getTime();
    const m = date.getMonth();

    if (m < month && date.getFullYear() === year) return;

    if (m < selectedMonth) {
      date.getFullYear() === selectedYear ? handleSelectPrevMonth(selectedMonth) : handleSelectNextMonth(selectedMonth);
      setSelectedDay(selectedDay);
      onDateSelect({ dateFrom });
      return;
    }

    if (m > selectedMonth) {
      date.getFullYear() === selectedYear ? handleSelectNextMonth(selectedMonth) : handleSelectPrevMonth(selectedMonth);
      setSelectedDay(selectedDay);
      onDateSelect({ dateFrom });
      return;
    }

    if (selectedDay < day && m === month) return;

    setSelectedDay(selectedDay);
    onDateSelect({ dateFrom });
  }

  function handleSelectNextMonth(m) {
    setSelectedDay(1);

    if (m === 11) {
      const y = selectedYear + 1;
      setSelectedYear(y);
      setSelectedMonth(0);
      onDateSelect({ dateFrom: new Date(y, 0, 1).getTime() });
      return;
    }

    const nextMonth = m + 1;
    setSelectedMonth(nextMonth);
    onDateSelect({ dateFrom: new Date(selectedYear, nextMonth, 1).getTime() });
  }

  function handleSelectPrevMonth(m) {
    if (m === month && selectedYear === year) return;

    setSelectedDay(1);
    if (m === 0) {
      const y = selectedYear - 1;
      setSelectedYear(y);
      setSelectedMonth(11);
      onDateSelect({ dateFrom: new Date(y, 11, 1).getTime() });
      return;
    }

    const prevMonth = selectedMonth - 1;
    setSelectedMonth(prevMonth);

    onDateSelect({
      dateFrom: new Date(
        selectedYear,
        prevMonth,
        prevMonth === month && selectedYear === year ? day : selectedDay
      ).getTime()
    });
    if (prevMonth === month && selectedYear === year) {
      setSelectedDay(day);
    }
  }

  function handleSelectMonth(m) {
    if (month > m && selectedYear === year) return;

    setSelectedMonth(m);
    const d = selectedMonth !== m && month === m ? day : 1;
    setSelectedDay(d);
    onDateSelect({ dateFrom: new Date(selectedYear, m, d).getTime() });
  }

  function handleSelectYear(y) {
    if (selectedYear === y) return;

    setSelectedYear(y);
    const m = year === y ? month : 0;
    setSelectedMonth(m);
    const d = year === y ? day : 1;
    setSelectedDay(d);
    onDateSelect({ dateFrom: new Date(y, m, d).getTime() });
  }

  return (
    <div className={css.eventsCalendar}>
      <div className={css.navigation}>
        <button
          onClick={() => handleSelectPrevMonth(selectedMonth)}
          className={classNames(css.arrowLeft, selectedMonth === month ? css.disabled : '')}>
          <CalendarArrow />
        </button>
        <PickerPopup
          currentYear={year}
          currentMonth={month}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          onYearSelect={handleSelectYear}
          onMonthSelect={handleSelectMonth}
        />
        <button onClick={() => handleSelectNextMonth(selectedMonth)} className={css.arrowRight}>
          <CalendarArrow />
        </button>
      </div>
      <div className={css.container}>
        <div className={css.header}>
          {WEEK_DAYS.map((weekDay) => (
            <div key={weekDay} className={css.cell}>
              <p>{msg(weekDay)}</p>
            </div>
          ))}
        </div>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className={css.row}>
            {row.map((cell, dateIndex) => (
              <div
                key={dateIndex}
                className={classNames(
                  css.cell,
                  cell.eventsAvailable ? css.eventsAvailable : '',
                  (cell.date.getDate() < day && cell.date.getMonth() === month && cell.date.getFullYear() === year) ||
                    (cell.date.getMonth() < month && cell.date.getFullYear() === year)
                    ? css.disabled
                    : '',
                  cell.date.getMonth() < selectedMonth ? css.previous : '',
                  cell.date.getMonth() > selectedMonth ? css.future : '',
                  cell.date.getDate() === day && cell.date.getMonth() === month && cell.date.getFullYear() === year
                    ? css.current
                    : '',
                  cell.date.getDate() === selectedDay && cell.date.getMonth() === selectedMonth ? css.selected : ''
                )}
                onClick={() => handleSelectDay(cell.date)}>
                <p>{cell.date.getDate()}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function useGenerateCalendar(selectedYear, selectedMonth, currentDate) {
  const [eventsAvailabilities, setEventsAvailabilities] = useState([]);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const dates = getDates(selectedYear, selectedMonth);

  const dateFrom = dates.some((_) => _.getDate() === day && _.getMonth() === month && _.getFullYear() === year)
    ? currentDate.getTime()
    : dates[0].getTime();
  const prevDateFrom = useRef(null);
  const dateTo = dates[dates.length - 1].getTime();
  const prevDateTo = useRef(null);

  useEffect(() => {
    if (prevDateFrom.current === dateFrom && prevDateTo.current === dateTo) return;

    API.getEventsAvailabilities({ dateFrom, dateTo }).then(setEventsAvailabilities);

    prevDateFrom.current = dateFrom;
    prevDateTo.current = dateTo;
  }, [dateFrom, dateTo]);

  const rows = populateCalendarRows(dates, eventsAvailabilities);

  return rows;
}

function getDates(year, month) {
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  const daysInMonth = lastDayOfMonth.getDate();
  const startDay = (firstDayOfMonth.getDay() + 6) % 7; // Adjust to start from Monday
  const endDay = (lastDayOfMonth.getDay() + 6) % 7;

  const prevMonthDays = startDay;
  const nextMonthDays = 6 - endDay;

  const dates = [];

  // Previous month's dates
  for (let i = prevMonthDays; i > 0; i--) {
    dates.push(new Date(year, month, -i + 1));
  }

  // Current month's dates
  for (let i = 1; i <= daysInMonth; i++) {
    dates.push(new Date(year, month, i));
  }

  // Next month's dates
  for (let i = 1; i <= nextMonthDays; i++) {
    dates.push(new Date(year, month + 1, i));
  }

  return dates;
}

function populateCalendarRows(dates, eventsAvailabilities) {
  const rows = [];
  let cells = [];

  dates.forEach((date, index) => {
    if (index % 7 === 0 && cells.length > 0) {
      rows.push(cells);
      cells = [];
    }

    cells.push({ date, eventsAvailable: eventsAvailabilities.some((d) => d.scheduledAt === date.getTime()) });
  });

  if (cells.length > 0) {
    rows.push(cells);
  }

  return rows;
}
