import css from './item.module.scss';
import { ReactComponent as LocationIcon } from 'src/assets/location.svg';
import { ReactComponent as OrganizerIcon } from 'src/assets/organizer.svg';
import PropTypes from 'prop-types';
import TimeSpan from 'src/components/time-span/time-span';
import Complaint from 'src/pages/complaint/complaint';
import { openInNewTab } from 'src/utils/helper';
import { fireEvent } from 'src/utils/analytics/google';
import { useContext } from 'react';
import { LanguageSettingsContext } from 'src/utils/language-context';

Item.propTypes = {
  event: PropTypes.shape({
    scheduledAt: PropTypes.number.isRequired,
    pictureUrl: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    organizer: PropTypes.string
  }).isRequired
};

export default function Item({ event }) {
  const { msg } = useContext(LanguageSettingsContext);
  function handleEventClick() {
    fireEvent('view_event', { title: event.title, url: event.url });
    openInNewTab(event.url);
  }

  return (
    <Complaint
      targetTitle={event.title}
      wrapper={(complaint, onMouseEnter, onMouseLeave) => (
        <div className={css.eventItem} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {complaint}
          <article>
            <picture placeholder={event.title}>
              <img src={event.pictureUrl} alt={event.title} />
            </picture>
            <header>
              <span>{event.title}</span>
              <hr />
            </header>
            <footer>
              {event.organizer && (
                <div className={css.organizer}>
                  <OrganizerIcon />
                  <p>
                    {msg('eventOrganiserPlaceholder')}: {event.organizer}
                  </p>
                </div>
              )}
              <div className={css.location}>
                <LocationIcon />
                <span>{event.locationName}</span>
                <div className={css.time}>
                  <TimeSpan utc={event.scheduledAt} />
                </div>
              </div>
            </footer>
          </article>
          <button type='button' onClick={handleEventClick}>
            {msg('eventLinkMsg')}
          </button>
        </div>
      )}
    />
  );
}
