import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function RozmovaProblemyZiSnomYakSobiDopomohty() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Проблеми зі сном. Як можна собі допомогти?</h1>
      <p>
        Події останніх 1.5 років порушили стабільність у всіх сферах нашого життя, зокрема в задоволенні таких базових
        потреб як регулярність сну, харчування, безпека та спілкування.
      </p>
      <p>
        Мене звати Світлана Тімофєєва, і я — психотерапевтка платформи Rozmova. Мій досвід роботи у сфері складає понад
        30 років. Зокрема, з лютого 2022 працюю кризовим психологом із військовими.
      </p>
      <p>
        За теперішніх обставин українцям, а особливо військовим, важко дотримуватися відповідної гігієни сну. Тому у цій
        статті пропоную розібратися, чому здоровий сон важливий, які можуть бути наслідки недосипу та як налагодити
        режим сну, враховуючи реалії сьогодення.
      </p>
      <h2>Здоровий сон: який він?</h2>
      <p>
        Вважається, що ми маємо спати 8 годин на добу не прокидаючись. Тобто засинаємо о 23:00, спимо без перешкод і
        прокидаємося о 7:00 виспаними та бадьорими. Ідеально, чи не так? Проте не все так просто, і ви знаєте чому.
      </p>
      <p>
        Війна, щоденні тривоги, прильоти, втрати — на жаль, це реалії нашого сьогодення. Наше завдання навчитися жити в
        них і задовольняти свої базові потреби наскільки це можливо. Тому, повертаючись до теми сну, перш за все ми
        маємо усвідомити його важливість.
      </p>
      <h2>Наслідки недосипу</h2>
      <p>
        Недосип, а саме сон тривалістю менше за 6-7 годин на добу, має негативний вплив на наше фізичне та психічне
        здоров&apos;я, а також щоденне функціонування та якість життя. Постійний недосип:
      </p>
      <ul>
        <li>руйнує нашу імунну систему більше, ніж у 2 рази, збільшуючи ризик захворювання на рак,</li>
        <li>є ключовим фактором визначення, чи піддаємося ми ризику хвороби Альцгеймера,</li>
        <li>може порушити рівень цукру в крові до переддіабетного стану,</li>
        <li>
          збільшує ймовірність зачоповування та витончення коронарних артерій, що призводить до серцево-судинних
          захворювань, інсульту і застійної серцевої недостатності,
        </li>
        <li>уповільнює нашу фізичну активність; викликає агресію, дратівливість, сльозливість,</li>
        <li>
          сприяє розвитку всіх серйозних психічних станів, включаючи депресію, тривогу та навіть суїцидальні нахили.
        </li>
      </ul>
      Найпоширенішою ознакою недосипу є відчуття втоми та потяг до вживання більшої кількості їжі. Усе тому, що в цей
      час у нашому організмі відбувається два процеси одночасно: збільшується концентрація гормону, який відповідає за
      почуття голоду та пригнічується парний гормон, який сигналізує про ситість. Ось і виходить, що ми ніби вже поїли,
      але все одно хочеться більше. Це до речі одна з причин набору ваги як для дорослих, так і для дітей. Навіть якщо
      ми сядемо на дієту, але не будемо висипатися, спроба, швидше за все, зазнає невдачі. Причина — велика частка
      втраченої ваги буде припадати на м&apos;язову масу тіла, а не на жирову. Недостатня кількість сну може бути
      тимчасовою. Звичайно, у такому випадку шкода організму не настільки масштабна, але ми все одно будемо пожинати
      плоди у вигляді втоми, поганого настрою та загального самопочуття.
      <h2>Як почати висипатися?</h2>
      Недосип може бути результатом як зовнішніх, так і внутрішніх факторів. На жаль, на зовнішні ми або взагалі не
      маємо впливу, або лише мінімальний. Тоді як повпливати на внутрішні нам під силу. Ось декілька порад:
      <ul>
        <li>За дві години до сну бажано не вживати їжу та не пити багато рідини.</li>
        <li>
          Перед сном не рекомендовані активні фізичні навантаження. Краще зробити розслабляючі процедури або легкі
          вправи, розтяжку.
        </li>
        <li>Бажано мати затемнене приміщення для сну.</li>
        <li>Забезпечте собі зручне та безпечне приладдя для сну. Навіть у польових умовах, наскільки це можливо.</li>
        <li>Попросіть, щоб вас підстрахували під час сну. Можна чергуватися з тим, кому довіряєте.</li>
      </ul>
      Розуміючи, що 80% часу ви перебуваєте у небезпеці, важливо хоч на деякий час дати собі перепочити. В іншому разі
      психіка зробить це за нас у найбільш невідповідний момент.
      <h2>Здоровий сон під час війни</h2>
      <p>
        Наразі наші захисники та захисниці знаходяться в таких умовах, де здоровий сон певною мірою — фантастика.
        Звичайно, військові не скаржаться, вони звикають, але не варто недооцінювати наслідки недосипання. Постійне
        виснаження, стрес розхитують нервову систему. Людина стає неуважною, робить помилки, які можуть коштувати їй
        дуже дорого.
      </p>
      <p>
        Не думаю, що існує єдиний варіант розв’язання проблеми з повноцінним сном під час війни та бойових дій, але
        потрібно потурбуватися про свій психоемоційний стан наскільки це можливо. Знайти підтримку людей, яким можна
        довіряти, допомагати один одному, цікавитися станом тих, хто поруч і за потреби завжди звертатися до фахівців.
      </p>
      <p>
        Іноді спеціалісти радять лікування, що є нормальним. Психіці потрібно перепочити, щоб мати міцне здоров’я,
        імунітет, гарну пам’ять, а також бути в змозі опрацьовувати навантаження та вирішувати складні завдання.
      </p>
      <p>
        <b>
          <i>Авторка статті — Світлана Тімофєєва, психотерапевтка платформи Rozmova</i>
        </b>
      </p>
    </Article>
  );
}

export default RozmovaProblemyZiSnomYakSobiDopomohty;
