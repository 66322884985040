import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function VziattiaNaOblikDliaZabezpechenniaSanatornoKurortnymLikuvanniamPutivkamy() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>Взяття на облік для забезпечення санаторно-курортним лікуванням (путівками)</h3>
      <p>
        За інформацією про виділення санаторних путівок потрібно звернутися до місцевих органів самоврядування – на
        сьогодні відповідні програми фінансуються за рахунок місцевих програм.
      </p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>Подати заяву до ЦНАП/тергромади/органу соцзахисту та медичну довідку (форма -0-70-0).</p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>
        Ветерани війни та особи, на яких поширюється дія ЗУ «Про статус ветеранів війни, гарантії їх соціального
        захисту» та «Про жертви нацистських переслідувань».
      </p>
    </Article>
  );
}
