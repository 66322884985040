import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, useContext } from 'react';
import { useResize } from 'src/hooks/shared-hooks';
import { LanguageSettingsContext } from 'src/utils/language-context';
import css from './accessibility-settings.module.scss';
import { ReactComponent as SwitchToDisabledMode } from 'src/assets/accessibility-switch-to-disabled.svg';
import { ReactComponent as SwitchToStandardMode } from 'src/assets/accessibility-switch-to-standard.svg';
import { fireEvent } from 'src/utils/analytics/google';
import { persistAccessibility, readAccessibility, setHtmlFontSize } from '../helper';

function AccessibilitySettings() {
  const { msg } = useContext(LanguageSettingsContext);
  const [isAccessibilityModeOn, setIsAccessibilityModeOn] = useState(false);
  const [selectedFontSizeIndex, setSelectedFontSizeIndex] = useState(0);
  const dimensions = useResize();
  const desktopMode = dimensions.width > 1000;

  const switchAccessibilityModeOn = useCallback((fontSize) => {
    const html = document.getElementsByTagName('html')[0];
    html.style.filter = 'grayscale(1)';
    html.setAttribute('accessibility-mode', true);
    fireEvent('accessibility_mode_on');
    setHtmlFontSize(fontSize);

    persistAccessibility(true, fontSize !== undefined ? fontSize : minFontSize);
  }, []);

  const minFontSize = 12;
  const maxFontSize = 18;
  const allowedFontSizes = useMemo(() => [minFontSize, 14, maxFontSize], []);

  useLayoutEffect(() => {
    const persistedAccessibility = readAccessibility(minFontSize);

    if (persistedAccessibility.isAccessibilityMode) {
      setIsAccessibilityModeOn(persistedAccessibility.isAccessibilityMode);

      const fontIndex = allowedFontSizes.indexOf(persistedAccessibility.fontSize);
      setSelectedFontSizeIndex(fontIndex);
      switchAccessibilityModeOn(persistedAccessibility.fontSize);
    }
  }, [allowedFontSizes, switchAccessibilityModeOn]);

  useEffect(() => {
    if (desktopMode || !isAccessibilityModeOn || selectedFontSizeIndex === 0) return;

    setSelectedFontSizeIndex(0);
    setHtmlFontSize(minFontSize);
    persistAccessibility(true, minFontSize);
  }, [desktopMode, isAccessibilityModeOn, selectedFontSizeIndex]);

  function setCheckedFontSize(checkedIndex, checkPredicate) {
    if (checkPredicate(checkedIndex)) return;

    const fontSize = allowedFontSizes[checkedIndex];
    setHtmlFontSize(fontSize);
    setSelectedFontSizeIndex(checkedIndex);
    persistAccessibility(true, fontSize);
  }

  function handleFontSizeSmallerOnClick() {
    setCheckedFontSize(selectedFontSizeIndex - 1, (index) => index < 0 || !desktopMode);
  }

  function handleFontSizeLargerOnClick() {
    setCheckedFontSize(selectedFontSizeIndex + 1, (index) => index > allowedFontSizes.length - 1 || !desktopMode);
  }

  function handleSwitchToggleClick() {
    const newMode = !isAccessibilityModeOn;

    if (isAccessibilityModeOn) {
      switchAccessibilityModeOff();
    } else {
      switchAccessibilityModeOn(minFontSize);
    }

    setIsAccessibilityModeOn(newMode);
  }

  function switchAccessibilityModeOff() {
    const html = document.getElementsByTagName('html')[0];
    html.removeAttribute('style');
    html.removeAttribute('accessibility-mode');
    setSelectedFontSizeIndex(0);

    persistAccessibility(false, minFontSize);
  }

  return (
    <div className={css.container}>
      {!isAccessibilityModeOn && (
        <button className={css.switch} onClick={handleSwitchToggleClick}>
          <div className={css.svgContainer}>
            <SwitchToDisabledMode />
          </div>
          {msg('accessibilityMsg1')}
        </button>
      )}
      {isAccessibilityModeOn && (
        <div className={css.accessibilityMode}>
          {desktopMode && (
            <div className={css.controls}>
              <button className={css.smaller} onClick={handleFontSizeSmallerOnClick}>
                A↓
              </button>
              <button className={css.larger} onClick={handleFontSizeLargerOnClick}>
                A↑
              </button>
            </div>
          )}
          <button className={css.switch} onClick={handleSwitchToggleClick}>
            <div className={css.svgContainer}>
              <SwitchToStandardMode />
            </div>
            {msg('accessibilityMsg2')}
          </button>
        </div>
      )}
    </div>
  );
}

export default AccessibilitySettings;
