import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function PosttravmatychnyiStresovyiRozladPTSR() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Посттравматичний стресовий розлад (ПТСР)</h1>
      <p>
        Посттравматичний стресовий розлад (ПТСР) – це хронічне порушення психічного стану, який може виникнути після
        травматичної події.
      </p>
      <p>
        ПТСР спричиняють сильні стресові події, до яких найчастіше відносяться війна, переживання втрат, стихійні лиха,
        серйозні проблеми зі здоров’ям, загроза життю, переживання бойових дій. При цьому рівень дотичності до стресової
        події, яка спричинила ПТСР, може бути різним – наприклад, ПТСР може виникнути як внаслідок особистого
        переживання загрози, так і внаслідок перебування в ролі свідка; через потерпання від надмірних страждань чи
        смерті близьких та через зіткнення з нестерпними деталями події, яка травмувала.
      </p>
      <hr />
      <b>Важливо!</b>
      <p>
        <i>
          Багато людей, які пережили подію, яка травмувала, деякий час залишаються під впливом пов&apos;язаних з нею
          негативних емоцій, думок і спогадів. Протягом першого місяця після події може розвинутись
          <b> гостра стресова реакція</b>, симптоми якої дуже схожі на симптоми ПТСР.
        </i>
      </p>
      <p>
        <i>
          Симптоми з’являються протягом чотирьох тижнів після травматичної п одії та тривають щонайменше три дні. Людина
          переживає повторювальні спогади про травматичну подію, намагається уникати всього, що нагадує про травму,
          спостерігається легка збудливість. Проте,
          <b> на відміну від ПТСР, така гостра стресова реакція триває не довше одного місяця</b>. Якщо подібні
          негативні реакції не припиняються та заважають повсякденному життю, це може бути причиною підозрювати
          наявність ПТСР.
        </i>
      </p>
      <hr />
      <p>
        Часто ПТСР виникає у людей, які переживають або пережили бойові та воєнні дії. З ним стикаються як військові,
        так і цивільні мешканці. Цей розлад проявляється в тому, що людина знову і знову, дуже яскраво, переживає подію,
        що травмує – так, ніби вона відбувається прямо зараз.
      </p>
      <h2>Які симптоми у ПТСР?</h2>
      <p>
        Для посттравматичного стресового розладу характерні чотири групи симптомів. Вони можуть проявлятися як всі
        одночасно, так і можуть бути більш вираженими симптоми однієї з груп, серед яких{' '}
        <b>
          симптоми повторного переживання, симптоми уникнення, симптоми настрою, симптоми настороженості та
          реактивності.
        </b>
      </p>
      <p>
        <b>Симптоми повторного переживання – проявляються у повернені до травматичної події через думки та відчуття:</b>
      </p>
      <ul>
        <li>
          Спогади – неприємні спогади про подію, також відомі як нав&apos;язливі думки, викликають почуття сум&apos;яття
          та занепокоєння.
        </li>
        <li>Сни – тривожні сни чи жахи, пов&apos;язані з подією.</li>
        <li>
          Дисоціативні реакції – відчуття чи дія, ніби травматична подія відбувається знову (відомі як флешбеки). В
          екстремальних ситуаціях людина може не усвідомлювати, що відбувається довкола.
        </li>
        <li>
          Фізичний і психологічний дистрес – відчуття сильного стресу і фізичного збудження (наприклад, прискорене
          дихання, прискорений пульс) за обставин, які нагадують про подію.
        </li>
      </ul>
      <p>
        <b>
          Симптоми уникнення – проявляються через уникнення будь-чого, що пов’язане або може бути пов’язане з
          травматичною подією:
        </b>
      </p>
      <ul>
        <li>Дисоціативна амнезія – нездатність згадати деталі травматичної події.</li>
        <li>
          Усунення – відчуття відчуженості або відсутність почуття близькості з людьми, з якими раніше були близькі.
        </li>
        <li>Уникнення розмов і думок про те, що сталося. Небажання говорити або думати про травматичну подію.</li>
        <li>
          Уникнення асоціацій – уникнення спогадів, думок, почуттів, речей, людей і місць, пов&apos;язаних з
          травматичною подією. Включно з уникненням новин, пов’язаних з подією, які транслюються на телебаченні та в
          інших ЗМІ, через провокування тривоги та занепокоєння.
        </li>
      </ul>
      <p>
        <b>
          Симптоми настрою – проявляються в емоційних гойдалках та деструктивних думках, впливає на поведінку та
          усвідомлення себе:
        </b>
      </p>
      <ul>
        <li>Негативні переконання та очікування – негативні думки про себе, інших людей чи навколишній світ.</li>
        <li>Звинувачення – звинувачення себе чи інших людей у події, що відбулася, або її наслідках.</li>
        <li>Негативні емоції – постійне відчуття страху, жаху, гніву, вини чи сорому.</li>
        <li>
          Втрата інтересу до діяльності – відмова від участі у заняттях або втрата інтересу до речей, якими регулярно
          займалися або які раніше приносили задоволення.
        </li>
        <li>Нездатність відчувати позитивні емоції – нездатність відчувати щастя, задоволення чи любов.</li>
      </ul>
      <p>
        <b>Симптоми настороженості та реактивності – проявляються в зміні поведінки та реакціях на буденні речі:</b>
      </p>
      <ul>
        <li>Надмірність – надмірна поінформованість про те, що відбувається навколо, нездатність розслабитися.</li>
        <li>
          Легка збудливість і тремтіння – надмірна реакція на галас або рухи, які нагадують про травматичну подію.
        </li>
        <li>Труднощі з концентрацією уваги.</li>
        <li>Проблеми зі сном – проблеми із засинанням та сном (сон може бути поверхневим, можуть снитися жахи).</li>
        <li>
          Дратівливість – спалах словесної або фізичної агресії до людей або речей. Ці спалахи можуть бути викликані
          переживанням чогось, що нагадує про подію, що травмує.
        </li>
        <li>Необережність – прагнення займатися небезпечними чи саморуйнівними речами.</li>
      </ul>
      <hr />
      <b>Важливо!</b>
      <p>
        <i>
          Діагноз ПТСР встановлює лікар-психіатр! За наявності симптомів ПТСР радимо звернутися до свого сімейного
          лікаря, який скерує вас до профільного фахівця.
        </i>
      </p>
      <hr />
      <h2>Які фактори поглиблюють ПТСР?</h2>Є основні три причини, які часто не дають можливості самостійного позбутися
      ПТСР:
      <ul>
        <li>
          <b>Неперероблена «травматична» пам&apos;ять</b>, яка часто буває фрагментарною, активною, «замороженою», коли
          є відчуття, що травма тільки сталася. Таким чином тривожна реакція підтримується в головному мозку на рівні
          мигдалеподібного тіла, що і створює відчуття травматичної події, яка знову і знову повторюється. Відповідно
          відбувається змішання минулих подій та сьогодення
        </li>
        <li>
          <b>Проблемна інтерпретація травматичних подій</b>, які заважають відновленню після травми: «Я у всьому винен»,
          «Я слабак», «Мені не можна довіряти», «Я нещасний, ніде не безпечно» тощо.
        </li>
        <li>
          <b>Зміна у поведінці</b> після травматичної події: захисна поведінка у вигляді уникнення певних місць, людей,
          ситуацій або для підтвердження проблемних інтерпретацій.
        </li>
      </ul>
      <h2>Які існують методи лікування ПТСР?</h2>
      Існують різні методи лікування ПТСР, серед яких травмофокусовані методи психотерапії та лікування медикаментами.
      <h3>Психотерапія</h3>
      <b>Психотерапія</b> для лікування ПТСР фокусується на травматичному досвіді, а не на минулому житті пацієнта.
      Оскільки зазвичай, маючи травматичний досвід, ми уникаємо думок та всього, що може про нього нагадувати,
      психотерапія при ПТСР якраз скерована на згадування травми, щоб повноцінно її прожити. Це допомагає спонукати
      мозок обробити та прийняти травматичну подію та зафіксувати її як згадку, замість розглядати як поточну загрозу і
      знову і знову проживати неприємні відчуття. Спосіб боротьби з пам’яттю полягає в тому, щоб навмисно думати про
      подію та запам’ятовувати її, що дозволяє мозку обробити її та зберегти як пам’ять. Опрацьовувати травматичну
      подію, як правило, неприємно, саме тому потрібна допомога психотерапевта.
      <p>
        <b>Психотерапевт допоможе:</b>
      </p>
      <ul>
        <li>
          <b>Прийняти наявний досвід</b> – навчитися приймати той факт, що, хоча ви не можете змінити те, що сталося, ви
          можете по-іншому думати про подію, навколишній світ і своє життя.
        </li>
        <li>
          <b>Прийняти спогади про подію</b> – згадуючи про те, що сталося, ви не відчуватимете страху або занепокоєння.
          Ви зможете думати про те, що сталося, коли самі вирішите, а не через нав&apos;язливі думки чи спогади.
        </li>
        <li>
          <b>Пояснювати свої переживання словами</b> – промовляючи вголос те, що сталося, ваш розум зможе відсунути
          спогади та зайнятися іншими речами, змістити фокус на реальне життя.
        </li>
        <li>
          <b>Відчути безпеку</b> – психотерапевт допоможе навчитися краще контролювати свої почуття. Це дозволить вам
          відчути себе у більшій безпеці та усуне необхідність уникати спогадів.
        </li>
      </ul>
      <i>
        Будь-яку психотерапію повинен проводити належним чином навчений та акредитований фахівець. Сеанси проводить один
        і той же терапевт 1-2 рази на тиждень загальною тривалістю не менше 8-12 тижнів.
      </i>
      Для терапії ПТСР найчастіше рекомендованими є два психотерапевтичних підходи:
      <ul>
        <li>Травмофокусована когнітивно-поведінкова терапія</li>
        <li>Метод десенсибілізації та репроцесуалізації рухом очей (EMDR).</li>
      </ul>
      <p>
        <b>Травмофокусована когнітивно-поведінкова терапія</b> – це структурована короткострокова модель надання
        допомоги, яка охоплює різні схеми втручання, спрямовані на переосмислення та повторне переживання травматичних
        подій.
      </p>
      <p>
        <b>Десенсибілізація та репроцесуалізація рухом очей (EMDR)</b> - техніка, яка використовує рухи очей, щоб
        допомогти мозку обробити травматичні спогади. В процесі треба згадати подію, що травмує, і думки та відчуття,
        які вона викликає. В процесі згадування треба робити рухи очима. Така «двостороння стимуляція» знижує емоційне
        навантаження, яке відбувається у зв&apos;язку з травматичним спогадом, і допомагає впоратися з травмою.
      </p>
      <h3>Лікування медикаментами</h3>
      <p>
        Психотерапія є основним (і найефективнішим) методом лікування ПТСР, але за необхідності може бути додане
        фармакологічне лікування антидепресантами для корекції порушень сну, підвищеної тривоги, дратівливості.
      </p>
      <p>
        Медикаментозне лікування також може допомогти тим, хто відмовляється від розмовної терапії або не може легко
        отримати доступ до неї. Рішення про необхідність медикаментозного лікування приймає лікар психіатр під час
        діагностики.
      </p>
      <h3>
        Немає можливості звернутись по допомогу? <br /> Самодопомога при ПТСР
      </h3>
      <p>
        Для того, щоб мати змогу будь-якої миті допомогти собі впоратися з симптомами ПТСР, використовуйте{' '}
        <b>методи стабілізації</b> — психологічні техніки, які допомагають опанувати емоції, пов’язані зі стресом,
        розвинути навички регуляції емоцій, виявити та залучити ресурси самодопомоги, які у вас є. Самостійне
        використання технік стабілізації (ТС) є
        <b>
          високоефективним засобом для полегшення симптомів як при роботі з людьми зі встановленим діагнозом ПТСР, так і
          з тими, хто демонструє межові ознаки та знаходиться у групі ризику.
        </b>
      </p>
      <b>Приклади технік стабілізації, які можна використовувати самостійно:</b>
      <ol>
        <li>
          <b>Антистресове дихання 4:8:</b>
          <ul>
            <li>Уповільніть дихання.</li>
            <li>
              Зробіть вдих, рахуючи від одного до чотирьох (1–2–3–4) — затримайте дихання на дві секунди (пауза на 1–2)
              — зробіть видих, рахуючи від одного до восьми (1–2–3–4–5–6–7–8).
            </li>
            <li>
              Важливо, щоб видих був удвічі довшим за вдих. Робіть цю вправу від десяти разів поспіль. Можна уявити, що
              ви «видихаєте» напругу.
            </li>
          </ul>
          <i>
            Страх, тривога або паніка провокують швидке і поверхневе дихання, через яке наше тіло починає панікувати від
            недостатньої кількості кисню. Це викликає запаморочення, тремтіння та ще більшу паніку. Повільне та глибоке
            дихання зупинить паніку та її фізичні симптоми.
          </i>
        </li>
        <li>
          <b>Техніка заземлення:</b>
          <ul>
            <li>
              Зорієнтуйте себе в просторі й часі, ставлячи собі деякі або всі питання:
              <ol>
                <li>Де я?</li>
                <li>Який сьогодні день?</li>
                <li>Яке сьогодні число?</li>
                <li>Який сьогодні місяць?</li>
                <li>Який рік?</li>
                <li>Скільки мені років?</li>
                <li> Яка зараз пора року?</li>
              </ol>
            </li>
          </ul>
          <i>
            У стані гострого ПТСР людина може забувати інформацію, а подібні питання здатні повернути її у реальність.
            Повернутися в «тут і зараз» та змістити фокус. Ця техніка особливо корисна під час флешбеків або після
            снів-жахіть.
          </i>
        </li>
        <li>
          <b>Стабілізаційна техніка в уяві – вправа «Безпечне місце».</b>
          <ul>
            <li>
              Уявіть безпечне місце. Змалюйте собі місце, де вам добре й безпечно, спробуйте відчути, де ви сидите, що
              бачите, який запах чуєте, що приємного на смак відчуваєте, яка звучить музика.
            </li>
          </ul>
          <p>
            Коли трапляється травма, відчуття безпеки та довіри страждає першим. З’являється відчуття небезпеки, страху
            і тривоги. Одним із ключових моментів для повернення до безпечного стану є відчуття безпеки та комфорту у
            своєму тілі. Наявність безпечного місця є однією з найважливіших речей, які вам потрібні, щоб подолати
            травму.
          </p>
          <p>
            Тож, якщо у вас вже є таке місце, чудово – подумайте про нього! Якщо ні, спробуйте відповісти на ці питання,
            щоб створити або знайти своє безпечне місце.
          </p>
          <ul>
            <li>Що змушує вас відчувати себе в безпеці?</li>
            <li>Чи є місце, де ви відчуваєте себе в безпеці? Опишіть його</li>
            <li>Згадайте той час, коли ви почували себе в небезпеці. Що допомогло тоді відновити відчуття безпеки?</li>
          </ul>
        </li>
      </ol>
      <h3>Самодопомога у повсякденному житті</h3>
      Щоб допомогти собі подолати симптоми ПТСР, впевніться, що у вашому повсякденні присутні:
      <ul>
        <li>
          <b>Спілкування.</b> Підтримуйте спілкування з тими, кому довіряєте. Дуже цілющим є розмови з людьми, які
          пережили подібний до вашого травматичний досвід. Проводьте час з іншими людьми, це дасть відчуття підтримки та
          потрібності.
        </li>
        <li>
          <b>Рутина.</b> Відновіть рутину, яка була у вас колись або створіть нову. Поверніться на роботу, на навчання,
          у колектив. Спробуйте нове хобі. Проживання максимально нормального життя, наскільки це можливо, подарує
          відчуття заземлення та спокою.
        </li>
        <li>
          <b>Спорт і харчування.</b> Обов’язково дотримуйтеся норм раціонального харчування. Навіть за відсутності
          апетиту, їжте. Дотримуйтеся фізичної активності, щоб бути достатньо втомленими перед сном.
        </li>
        <li>
          <b>Позитивне мислення.</b> Зосередження на думці про те, що зрештою вам стане краще, буде корисним для вашого
          одужання. Можна підготувати спеціальні копінг-картки (
          <i>КПТ-метод для відпрацювання раціонального мислення</i>) на випадок загострення ПТСР. Наприклад: «Я зараз
          знаходжусь у безпеці, це лише спогад – це було у минулому, а не відбувається зараз. Цей спогад пройде».
        </li>
        <li>
          <b>Повертайтеся туди, де сталася травматична подія, лише коли ви відчуєте, що можете це зробити. </b>
          Поговоріть зі своїм терапевтом або лікарем, якщо ви плануєте це зробити, щоб вони могли підтримати вас на
          цьому етапі.
        </li>
      </ul>
    </Article>
  );
}

export default PosttravmatychnyiStresovyiRozladPTSR;
