import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import imageSrc from 'src/assets/problemy-zi-snom.webp';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function ProblemyZiSnomYakSobiDopomohty() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Проблеми зі сном. Як собі допомогти?</h1>
      <p>
        Сон та психічне здоров’я тісно пов’язані. Часто люди, які тривалий час перебували в умовах сильного стресу або
        мають психічні проблеми – наприклад, гостру стресову реакцію чи ПТСР – стикаються з проблемами зі сном.
        Розповідаємо, що таке проблеми зі сном, чому є зв’язок між сном та психічним здоров’ям та що можна зробити, щоб
        покращити якість сну.
      </p>
      <p>
        Кожен інколи має проблеми зі сном – це нормальне й поширене явище. Трапляються ночі, коли важко заснути, сняться
        погані чи надто яскраві сни, трапляються регулярні пробудження серед ночі без очевидної для цього причини. Ці
        проблеми заважають спати та відпочивати, проте часто є короткостроковими та вирішуються самі собою протягом
        кількох днів або тижнів.
      </p>
      <p>
        Однак коли проблеми зі сном тривають тижнями, місяцями або роками, варто комплексно підійти до розв’язання цієї
        проблеми. Поганий сон в довгостроковій перспективі впливає на якість повсякденного життя – може вплинути на
        рівень енергії, настрій та здатність зосереджуватися. Через це під загрозою опиняються стосунки та соціальне
        життя, здатність виконувати повсякденні завдання – наприклад, навчання, робота та інші справи.
      </p>
      <p>
        <b>До найпоширеніших проблем зі сном належать:</b>
      </p>
      <ul>
        <li>
          <b>Безсоння</b>
        </li>
        Симптомом безсоння є важке засинання та проблеми з повторним зануренням в сон, якщо ви прокидаєтеся вночі. Так,
        ви можете лягати дуже пізно або прокидатися дуже рано, мати тривожний або поверхневий сон, прокидатися кілька
        разів за ніч. Наслідком безсоння є відсутність бадьорості, відчуття постійної втоми.
        <li>
          <b>Кошмари та нічні жахи</b>
        </li>
        Кошмари — це інтенсивні, страшні сни, які змушують прокинутися, залишаючи відчуття наляканості. Такі сни часто
        пов’язані з подіями чи досвідом у житті. Нічні жахи схожі на кошмари, але вони виникають під час глибокого сну.
        Такі сни викликають глибоке почуття страху, прискорене серцебиття та потовиділення. Під час нічних жахів ви
        можете кричати, смикатися або плакати уві сні. Часто людям важко прокинутися від нічного жаху, а після
        пробудження важко згадати, що сталося. Кошмари та нічні жахи можуть вплинути на якість сну та спричинити
        загальне занепокоєння щодо засипання, що може призвести до безсоння.
        <li>
          <b>Сонний параліч</b>
        </li>
        Сонний параліч – це коли прокинувшись вночі ви не можете рухатися чи говорити, оскільки у м’язах все ще є
        гормони сну. Зазвичай цей стан триває від декількох секунд до декількох хвилин. Хоча сонний параліч не несе
        жодної шкоди, він може сильно налякати та викликати тривогу перед наступним засинанням.
        <li>
          <b>Ходьба уві сні</b>
        </li>
        Ходьба уві сні передбачає пробудження вночі та ходьбу або пересування у стані сну. Іноді людина у такому стані
        може виконувати такі дії, як прибирання або миття посуду. Ходьба уві сні зазвичай не є проблемою, однак може
        становити фізичну загрозу сновиді: можна порізатися, перечепитися, спіткнутися, потурбувати близьких, не
        усвідомлюючи цього. Також ходьба уві сні може порушити сон і вплинути на рівень енергії наступного дня.
      </ul>
      <h2>Що викликає проблеми зі сном?</h2>
      Проблеми зі сном є наслідком багатьох причин. Найпоширенішими з них є:
      <ul>
        <li>
          <b>Поганий режим сну</b> – ви лягаєте надто рано чи пізно, або неправильно розслабляєтеся перед сном.
        </li>
        <li>
          <b>Погане середовище для сну</b> – некомфортні умови для сну, де занадто багато світла чи шуму, недостатньо
          свіжого повітря, незручне спальне місце тощо.
        </li>
        <li>
          <b>Зміни в режимі сну</b> – робота в нічні зміни або сон у новому місці може бути проблемою, якщо ваше тіло
          складно пристосовується до змін.
        </li>
        <li>
          <b>Некорисні психологічні асоціації</b> – розвиток тривоги або фобії щодо відходу до сну після періоду
          безсоння чи кошмарів або асоціювання спальні з місцем для активності, а не відпочинку.
        </li>
        <li>
          <b>Фізична хвороба</b> – фізичне відчуття дискомфорту або біль, фізичний стан сну (наприклад, хропіння або
          апное – проблеми з диханням під час сну), гормональний або неврологічний розлад (наприклад, гіперактивність
          щитоподібної залози).
        </li>
        <li>
          <b>Алкоголь, наркотики та стимулятори, ліки, включно з кофеїном та нікотином</b> – проблеми зі сном можуть
          бути побічним ефектом від певних ліків, таких як ліки проти епілепсії, астми або антидепресанти.
        </li>
        <li>
          <b>Стрес та тривожність</b> – почуття нервозності з приводу конкретної проблеми може викликати проблеми зі
          сном. Серед таких причин може бути війна, втрата близької людини, проблеми в стосунках, екстремальна подія
          тощо.
        </li>
        <li>
          <b>Травма</b> – важко заснути після травматичної події, такої як нещасний випадок або втрата.
        </li>
        <li>
          <b>Проблеми з психічним здоров&apos;ям</b> – ПТСР, депресія тощо.
        </li>
      </ul>
      Часто причиною проблем зі сном є комплекс факторів, які впливають на поведінку та змушують сформувати нову звичку
      через створення нового асоціативного сполучення. Наприклад:
      <ul>
        <li>
          Людина має сімейну історію безсоння та/або певні біологічні та особистісні фактори, які підвищують ймовірність
          розвитку безсоння або інших проблем зі сном.
        </li>
        <li>
          Відбувається стресова подія – наприклад, війна, народження дитини, втрата роботи тощо, – і в людини
          починаються проблеми з засинанням.
        </li>
        <li>
          Людина змінює свій графік, намагаючись впоратися з проблемами сну: затримується в ліжку вранці та дрімає
          протягом дня через поганий сон вночі.
        </li>
        <li>
          Стресові фактори зникають, але людина звикає думати про сон, як про складний процес, який не приносить
          відчуття спокою та розслаблення. Людина може хвилюватися протягом дня, що не зможе добре поспати вночі, а
          також відчуває вплив поганого сну на здоров’я та якість життя.
        </li>
        <li>
          Думки, емоції та поведінка під час сну стають звичками. Нова поведінка, думки та емоції, пов’язані зі сном
          стають основною причиною тривалого безсоння, навіть коли первинна стресова подія більше немає впливу на життя
          та настрій.
        </li>
      </ul>
      <h2>Як собі допомогти?</h2>
      Проблеми зі сном часто виникають через особисті обставини та специфічні події в житті людини. Тому, щоб налагодити
      режим сну, варто виявити власні причини проблем зі сном та практикувати різні техніки налагодження сну, щоб знайти
      ту, яка працює саме у вашому випадку. Першим кроком до налагодження сну є ведення спеціального щоденника, який
      допомагає виявити причини погіршення якості відпочинку вночі.
      <h3>Щоденник сну</h3>
      Стабільне ведення щоденника сну деякий час допоможе виявити певні звички та патерни поведінки, які впливають на
      ваш сон. Простежуючи закономірності та повторення у своїх записах, ви зможете помітити що саме спричинило ваші
      проблеми зі сном і почнете вирішувати їх. Щоденник сну може містити наступну інформацію:
      <ul>
        <li>о котрій годині ви лягаєте спати та о котрій годині встаєте</li>
        <li>яку загальну кількість годин сну маєте щоночі та оцінка його якості від 1 до 5</li>
        <li>скільки разів ви прокидаєтеся вночі</li>
        <li>як довго ви налаштовуєтеся на засинання і що ви робите, коли не можете заснути</li>
        <li>чи сняться вам кошмари, нічні жахи, чи відбувається сонний параліч, чи є сновидство</li>
        <li>чи спите ви вдень і скільки триває денний сон</li>
        <li>як ліки ви приймаєте, включаючи дозу та час вживання</li>
        <li>кількість кофеїну, алкоголю або нікотину, які ви вживаєте протягом дня</li>
        <li>кількість фізичної активності протягом дня</li>
        <li>ваш раціон протягом дня разом з напоями та випитою водою</li>
        <li>ваш загальний стан та настрій, включно з тривогою та нав’язливими думками</li>
      </ul>
      Ви можете створити власний щоденник сну в зошиті або скористатися
      <a href='https://drive.google.com/file/d/1PHeXdTLTAngsI6SEa17RPMNIBH2D1QN9/view?usp=sharing'> шаблоном</a>. Ведіть
      щоденник сну щонайменше один тиждень. Після цього терміну ви можете почати аналізувати інформацію та фіксувати
      відповіді на такі питання:
      <ul>
        <li>Скільки часу ви спите щоночі?</li>
        <li>Як часто ви прокидаєтеся вночі?</li>
        <li>Вам важко повернутися до сну, коли прокидаєтеся серед ночі?</li>
        <li>Чи пов’язана якість вашого сну з кількістю випитих попереднього дня напоїв з кофеїном?</li>
      </ul>
      <h2>Що робити далі?</h2>
      <ol>
        <li>
          <b>Встановіть режим сну. Намагайтеся щодня лягати та прокидаючись в один й той самий час.</b>
        </li>
        Так ви посилите вашу потребу у сні та допоможете собі «налаштувати» ваш біологічний годинник сну. На
        встановлення режиму потрібен час, тому що організм має запам’ятати час та поведінку, яка притаманна йому у певну
        годину доби. На формування звички та здібності засинати та прокидатися в один й той самий час може піти декілька
        тижнів.
        <p>
          <b>Порада:</b>
        </p>
        <p>
          <i>
            Переконайтеся, що ви обираєте такий графік сну, який відповідає вашому поточному режиму. Адже ваша поточна
            мета зараз в поглибленні якості сну.
          </i>
        </p>
        <p>
          <i>
            Наприклад, якщо ви рідко лягаєте спати раніше 01:00 ночі, не варто намагатися засинати о 23:00. Сфокусуйтеся
            на задачі налагодити якість сну: легко засинати, не прокидатися вночі, легко вставати зранку, відчувати себе
            енергійно після сну. Коли ці потреби будуть налагоджені, тоді можна спробувати відрегулювати режим засинання
            та пробудження.
          </i>
        </p>
        <li>
          <b>Лягайте в ліжко лише тоді, коли відчуваєте достатню втому, щоб заснути</b>
        </li>
        Якщо зазвичай ви довго засинаєте, відкладіть лягання до того моменту, коли ви зазвичай занурюєтеся в сон.
        Прокидайтеся у звичайний для вас час. Таким чином ви проводитимете менше часу в ліжку безпосередньо перед
        моментом, коли засинаєте – відповідно, ваше тіло та мозок почнуть асоціювати перебування в ліжку зі сном і
        швидкість засинання та якість сну поліпшаться.
        <li>
          <b>Вставайте з ліжка, коли не можете заснути або прокинулись серед ночі</b>
        </li>
        Якщо ви не засинаєте протягом 20 хвилин, підіть в іншу кімнату і спробуйте там розслабитися. Займіться чимось
        заспокійливим – наприклад, послухайте музику, – поки не втомитеся настільки, що зможете повернутися спати. Якщо
        ви не спите тривалий час, повторіть цей процес стільки разів, скільки вам потрібно. Таким чином, ви можете
        зміцнити навчений зв’язок між ліжком і сном і відучити мозок та тіло від асоціювання ліжка з місцем, де можна не
        спати.
        <li>
          <b>Намагайтесь відмовитися від денного сну</b>
        </li>
        Якщо ви пропустили багато сну або взагалі не спали ви можете відчувати потребу у надолужені неотриманого сну. У
        такому випадку, можна поспати на годину-дві довше у вихідний день або виділити годину часу на денний сон. Однак
        важливо намагатися не спати надто довго та часто вдень, оскільки це може змінити ваш режим сну та вплинути на
        те, як добре ви спите вночі. Намагайтеся лягати ненадовго вдень лише тоді, коли це необхідно, наприклад, якщо ви
        надто мало спите, і через це неможливо або небезпечно продовжувати свої звичайні щоденні справи на кшталт
        керування автомобілем.
        <p>
          <i>
            Для денного сну також є правило: намагайтеся спати вдень в один й той самий час та стежте за тривалістю сну
            – як правило, 30-40 хвилин у другій половині дня підходить більшості людей.
          </i>
        </p>
        <li>
          <b>Уникайте діяльності, що стимулює, в спальні</b>
        </li>
        Якщо ви займаєтеся діяльністю, яка збуджує, у своїй спальні, ви можете почати асоціювати спальню з активністю –
        вам буде важко розслабитися і заснути. Уникайте стресових занять у спальні – навчання, роботи чи фізичних вправ.
        Навіть заняття, які нібито розслаблюють, як-от перегляд телевізора або використання ноутбука в ліжку, можуть
        стимулювати розум і ускладнювати заснути.
        <li>
          <b>Регулюйте освітлення</b>
        </li>
        Світло має важливий вплив на якість сну. Наприклад, сон вночі кращий, коли протягом дня ви проводите час на
        вулиці при денному світлі. Якщо ви проводите більшу частину дня у приміщенні, робіть короткі перерви, щоб вийти
        на вулицю та прогулятися. У спальні створіть спокійну та темну атмосферу: уникайте яскравого світла, вимкніть
        електронні пристрої з підсвічуванням (увімкніть нічний режим на телефоні, закрийте ноутбук, відверніть або
        накрийте чимось яскраві лампочки вимикачів), приглушіть світло в кімнаті, коли готуєтеся до сну. Якщо ви
        прокидаєтеся вночі, не вмикайте верхнє світло – воно збудить нервову систему та ускладнить засинання.
        <li>
          <b>Усвідомте та змініть ірраціональні моделі мислення про сон</b>
        </li>
        <p>
          <img src={imageSrc} alt='схема мислення' style={{ float: 'right' }} />
          Тривалі проблеми зі сном можуть бути спричинені негативними думками або асоціаціями про сон, які погіршують
          проблему. Наприклад, ваша схема мислення може виглядати приблизно так:
        </p>
        <p>
          У такому випадку варто навчитися розпізнавати та змінювати свої думки про сон. Когнітивно-поведінкова терапія
          (КПТ) допомагає людям змінити негативні моделі мислення чи поведінки. Ви можете спробувати самостійно замінити
          свої негативні думки на більш реальні та позитивні.
        </p>
        <p>
          <b>Наприклад:</b>
          <p>
            <i>«Я зараз знову буду пів ночі мучитись та намагатись заснути»</i>
          </p>
          <p>можна змінити на </p>
          <p>
            <i>
              «Я вживаю всіх правильних заходів, щоб покращити свій сон. Тепер мені просто потрібно відпустити та бути
              впевненим, що з часом усе покращиться, навіть якщо я погано сплю сьогодні»
            </i>
          </p>
          Складність у зміні способу мислення та потреба в допомозі психотерапевта може виникнути, коли проблеми зі сном
          є наслідком проблем з психічним здоров’ям, серед яких депресія, тривога, посттравматичний стресовий розлад
          (ПТСР) тощо.
        </p>
        <li>
          <b>Дайте собі принаймні одну годину відпочинку перед сном</b>
        </li>
        Перед сном важливо розслабитися та максимально відключитися від повсякденних турбот. За годину-дві до сну
        намагайтеся припинити будь-які активності, які стимулюють: виконання робочих справ, активні фізичні
        навантаження, час за телефоном, комп’ютером, планшетом та іншими гаджетами. Замість цього ви можете присвятити
        час більш заспокійливим заняттям: послухати релаксаційну музику, прийняти ванну, зайнятися творчістю тощо. Якщо
        вам все ще важко заснути, можливо, вам потрібна більш структурована процедура релаксації. Ви можете спробувати
        кілька речей:
        <ul>
          <li>
            Дихальні вправи – перебуваючи в зручному положенні, глибоко вдихніть, потім повільно видихніть, роблячи вдих
            довшим, ніж вдих. Повторюйте, доки не відчуєте розслаблення.
          </li>
          <li>
            Розслаблення м’язів – свідомо напружуйте та розслабляйте м’язи, починаючи з пальців ніг і просуваючись вгору
            по тілу, поки не досягнете маківки.
          </li>
          <li>
            Візуалізація – уявіть сцену або пейзаж, який залишив у вас приємні спогади. Перебувайте у цій картинці, доки
            не відчуєте розслаблення та спокій.
          </li>
          <li>
            Медитація – спостерігайте за своїми думками, але не надавайте їм значення. Стежте як одна думка замінює
            іншу, але не робіть зусиль, щоб обмірковувати ці думки. Просто відпускайте їх, розслабляйте голову.
          </li>
        </ul>
        <p>
          Техніка виконання медитації: <br />
          Потрібно сісти, розслабитися, зосередитися на тілесних відчуттях. Головна ціль – зосереджуватися доти, доки у
          вас не зупиниться потік думок і ви перейдете до відчуттів. Так людина потрапляє у глибокий трансовий стан,
          перезавантажується і відпочиває.
        </p>
        <hr />
        <i>
          Ви не завжди можете самостійно розв&apos;язати проблеми, які впливають на ваш сон. Якщо ви не можете знайти
          вирішення самостійно, можливо, вам потрібна додаткова допомога.
        </i>
        <hr />
      </ol>
    </Article>
  );
}

export default ProblemyZiSnomYakSobiDopomohty;
