import React, { useId, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'src/components/form/search/filters-form/search-input';
import Filters from 'src/components/form/filters/filters';
import { searchInputValidationSchema } from 'src/components/form/search/filters-form/helper';
import API from '../api/api';
import css from './form.module.scss';
import SingleSelectInput from 'src/components/form/select-input/select-input';
import { LanguageSettingsContext } from 'src/utils/language-context';

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
};

export default function SearchForm({ onSubmit, initialValues }) {
  const { msg } = useContext(LanguageSettingsContext);
  const inputId = useId();
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    API.getVendors().then(setVendors);
  }, []);

  return (
    <Filters
      initialFilters={initialValues}
      onSearch={onSubmit}
      validationSchema={searchInputValidationSchema(msg, 'text')}
      fieldsToSubmitOnChange={['text', 'vendor']}
      render={({ errors, control, registerAndClearOnChange, _ }) => (
        <div className={css.educationInputGroup}>
          <SearchInput
            id={inputId}
            name='text'
            placeholder={msg('searchEducationCoursePlaceholder')}
            inputProps={registerAndClearOnChange('text')}
            error={errors?.text?.message}
          />
          <SingleSelectInput
            name='vendor'
            control={control}
            options={[
              { value: '', label: msg('educationCourseVendorFilterPlaceholder') },
              ...vendors.map((v) => ({ value: v, label: v }))
            ]}
          />
        </div>
      )}
    />
  );
}
