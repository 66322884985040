import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import css from './article.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';

const BackLink = ({ homePath, caption }) => {
  return (
    <Link to={-1} className={css.backLink}>
      &lt;&lt; {caption}
    </Link>
  );
};

const Article = ({ children, homePath }) => {
  const { msg } = useContext(LanguageSettingsContext);
  const caption = msg('articleBack');

  return (
    <main className={css.container}>
      <BackLink homePath={homePath} caption={caption} />
      <article>{children}</article>
      <BackLink homePath={homePath} caption={caption} />
    </main>
  );
};

export default Article;

Article.propTypes = {
  children: PropTypes.node.isRequired,
  homePath: PropTypes.string.isRequired
};

BackLink.propTypes = {
  homePath: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired
};
