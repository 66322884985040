import React, { useRef, useState, useContext } from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import css from './search-select-input.module.scss';
import { useOutsideClickTracker } from 'src/hooks/shared-hooks';
import { fireEvent } from 'src/utils/analytics/google';
import { LanguageSettingsContext } from 'src/utils/language-context';

SearchSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  inputWrapper: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  inputProps: PropTypes.object
};

export default function SearchSelectInput({
  id,
  name,
  required,
  placeholder,
  entities,
  onFocus,
  onBlur,
  onSelect,
  onChange,
  inputWrapper,
  onClose,
  onOpen,
  inputProps
}) {
  const containerRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const { field } = useController({ name });
  const selectedValueRef = useRef(field.value);
  const { msg } = useContext(LanguageSettingsContext);

  useOutsideClickTracker(containerRef, () => {
    if (!showPopup) return;

    handleClosePopup();
  });

  function handleSelectItem(item) {
    field.onChange(item.name);
    selectedValueRef.current = item.name;
    onSelect(item);
    handleClosePopup();
    fireEvent('selected_location', { location: item.name });
  }

  function handleOpenPopup() {
    if (!showPopup) {
      setShowPopup(true);
      onOpen?.();
    } else {
      handleClosePopup();
    }
  }

  function handleClosePopup() {
    setShowPopup(false);
    onClose?.();
  }

  function handleClearInput() {
    onSelect(null);
    selectedValueRef.current = '';
    field.onChange('');
    onBlur?.();
    inputProps?.onBlur?.();
    handleClosePopup();
  }

  function handleBlur(e) {
    onBlur?.(e);
    inputProps?.onBlur?.(e);

    if (!e.target.value) {
      selectedValueRef.current = '';
      return;
    }

    field.onChange(selectedValueRef.current);
  }

  function handleFocus(e) {
    onFocus?.(e);
    inputProps?.onFocus?.(e);

    if (e.target.value && !selectedValueRef.current) {
      selectedValueRef.current = e.target.value;
      field.onChange(e.target.value);
    }
  }

  function handleChange(e) {
    onChange?.(e);
    inputProps?.onChange?.(e);
    field.onChange(e.target.value);
  }

  return (
    <div className={css.selectInputPopup} ref={containerRef}>
      <div onClick={handleOpenPopup}>
        {inputWrapper(
          <input
            {...inputProps}
            id={id}
            name={field.name}
            type='text'
            ref={field.ref}
            value={field.value ?? ''}
            required={required}
            placeholder={placeholder}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            autoComplete='off'
          />
        )}
      </div>
      {showPopup && entities && entities?.length !== 0 && (
        <div className={css.modal} role='tooltip'>
          <ul>
            <li key='all-locations' onClick={handleClearInput}>
              {msg('locationInputPlaceholder')}
            </li>
            {entities.map((item) => (
              <li key={item.id} onClick={() => handleSelectItem(item)} className={css.dropdown_item}>
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
