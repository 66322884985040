import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function YakAdaptuvatysDoPovsiakdennohoZhyttiaPisliaPovernenniaDodomu() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Як адаптуватись до повсякденного життя після повернення додому?</h1>
      <p>
        Повернення додому після військової служби може бути періодом значного стресу, як для військового, так і для його
        родини. Буває дуже складно адаптуватись під «цивільне» мислення після раптового повернення до родини, друзів,
        колег на роботі.
      </p>
      <p>
        Цей посібник створений, щоб полегшити процес адаптації вдома та допомогти якнайшвидше розпочати наступний етап
        життя. У ньому ви знайдете необхідну інформацію про те, з якими проблемами ви можете стикнутись під час
        повернення додому. Обґрунтоване розуміння того, що може відбуватися, разом з прикладами, допоможе мати
        реалістичні очікування щодо повернення. Спеціально підібрані техніки допоможуть підготуватися до повернення та
        можливих викликів адаптації.
      </p>
      <h2>Психологічні реакції від повернення додому</h2>
      Серед військовослужбовців поширеною є певна стресова реакція на повернення додому з зони бойових дій. Зміни в
      поведінці є нормальним проявом психіки, особливо протягом перших тижнів знаходження вдома. Більшість військових
      успішно долають симптоми самостійно протягом перших декількох місяців адаптації. Важливо усвідомити, що це
      нормально і може тривати деякий час – але щодня чи щотижня ви відчуватимете себе краще. Під час адаптації можуть
      бути наявними певні фізичні, психологічні та поведінкові реакції.
      <h3>Поширені фізичні реакції:</h3>
      <ul>
        <li>Проблеми зі сном, надмірна втома</li>
        <li>Розлад шлунка, проблеми з апетитом</li>
        <li>Головний біль і пітливість при згадуванні бойових дій</li>
        <li>Прискорене серцебиття або дихання</li>
        <li>Погіршення наявних проблем зі здоров’ям</li>
        <li>Переживання шоку, заціпеніння, неможливість почуватися щасливим</li>
      </ul>
      <h3>Поширені психологічні реакції:</h3>
      <ul>
        <li>Погані сни, кошмари</li>
        <li>Флешбеки або часті небажані спогади</li>
        <li>Гнів</li>
        <li>Почуття нервозності, безпорадності або страху</li>
        <li>Почуття провини, самозвинувачення, сорому</li>
        <li>Почуття смутку та покинутості</li>
        <li>Схвильованість, дратівливість, надчутливість</li>
        <li>Почуття безнадії щодо майбутнього</li>
      </ul>
      <p>
        <i>
          Проблеми зі сном можуть проявлятися як безсонням, так і кошмарами, які сняться, коли ви спите. Також ви можете
          мати спокійний сон, але не отримувати відновлення енергії після та постійно відчувати втому.
        </i>
      </p>
      <p>
        <i>
          Якщо ви втратили товаришів на війні, у вас можуть бути нав’язливі думки про них. Можете відчувати гнів, образу
          або навіть провину, пов’язану з їх смертю. Поширеним є шоковий стан, який проявляється емоційним заціпенінням
          та ошелешеністю.
        </i>
      </p>
      <p>
        <i>
          У цей час вас можуть більше дратувати загальні сімейні проблеми. Ви можете відчувати занепокоєння або
          запальність. Гнів і агресія є поширеними стресовими реакціями в зоні війни, але вони можуть налякати вашого
          партнера, дітей і вас також.
        </i>
      </p>
      <h3>Поширені поведінкові реакції:</h3>
      <li>Проблеми з концентрацією</li>
      <li>Різкість та імпульсивність</li>
      <li>Настороженість та бажання бути напоготові, надто сильна турбота про безпеку</li>
      <li>Уникання людей або місць, пов&apos;язаних з травмою</li>
      <li>Надмірне вживання алкоголю або наркотиків, куріння</li>
      <li>Відсутність фізичної активності, неправильне харчування або надмірний догляд за здоров&apos;ям</li>
      <li>Проблеми з виконанням регулярних завдань на роботі</li>
      <li>Агресивні звички водіння</li>
      <p>
        <i>
          Деяке уникнення є нормальною реакцією на стрес. Але якщо ви постійно уникаєте всього, що нагадує вам про
          досвід із зони військових дій, це може створити серйозні труднощі вдома. Наприклад, ви можете уникати
          зустрічей з іншими людьми через страх, що вони можуть запитати вас про війну. Якщо ви так робите, то є ризик
          повністю ізолюватися від спілкування з людьми, замкнутися в собі, тому таку поведінку треба «помічати» та
          корегувати.
        </i>
      </p>
      <p>
        <i>
          Коли уникливу поведінку не виходить регулювати самостійно, краще відвідати психотерапевта, який допоможе
          пропрацювати цю проблему разом.
        </i>
      </p>
      <h2>Значення родини та спілкування для адаптації</h2>
      <p>
        Бажання усамітнитися є нормальною реакцією, щоб пережити стрес та поступово усвідомити себе в нових реаліях –
        знову вдома. Однак якщо перебування на самоті суттєво перевищує час, який ви проводите в компанії інших людей, і
        ви навмисно уникаєте зустрічей з ними, це може спричинити відчуття ізольованості від соціуму. Для здорової
        адаптації потрібна підтримка людей.
      </p>
      <p>
        Щоб підтримувати спілкування з близькими та при цьому вберегти себе від додаткового стресу, дотримуйтеся такого
        плану:
      </p>
      <ul>
        <li>
          Поговоріть про те, як ви себе почуваєте, з родиною та друзями. Ваші близькі можуть не знати, як запитати про
          ваш досвід, при цьому розмова про ваші почуття може бути важливою частиною процесу адаптації.
        </li>
        <li>Зазначте, що розповідатимете про свій досвід у зоні бойових дій тоді і в такому темпі, які вам зручні.</li>
        <li>
          Встановіть баланс між підтриманням зв’язку з колишніми бойовими товаришами та проведенням особистого часу з
          вашим партнером, дітьми, іншими членами родини та друзями.
        </li>
        <li>Плануйте сумісну діяльність з вашими близькими та разом створюйте позитивні моменти.</li>
      </ul>
      <p>
        <i>
          Після повернення додому може бути потреба заново навчитися відчувати себе комфортно, довірливо та безпечно зі
          своєю родиною або друзями. Потрібно знову пізнати один одного.
        </i>
      </p>
      <p>
        <i>
          Дайте один одному час зрозуміти, через що пройшли ви та як жили ваші близькі без вас. Розмовляючи, уважно
          слухайте один одного. Родина є безпечною, коли є повага один до одного, відкритість та готовність розглядати
          альтернативи.
        </i>
      </p>
      <h3>Вправа для формування близькості в родині:</h3>
      <p>
        <b>
          Уявіть, що ви прокидаєтеся зранку, і все у вашій родині ідеально. Чим ця ідеальна родина відрізняється від
          вашої сьогоднішньої сім’ї?
        </b>
      </p>
      <p>
        Кожна людина з вашої родини має написати відповідь. Далі зберіть усі відповіді та разом прочитайте їх та
        проговоріть вголос. Відповіді допоможуть визначити ваші цілі в родині, розвинути здорові стосунки та зменшити
        конфлікти.
      </p>
      <p>
        Дана вправа є одним з найкращих видів сімейної терапії травми. Вона допомагає сім’ям визначити свої майбутні
        цілі. Вправа відкриває бажання та сподівання кожного члена родини, які рідко обговорюються. Вправу також можна
        адаптувати під різні сфери життя (наприклад, уявіть, що у вас на роботі сьогодні все ідеально. Що змінилося на
        роботі від вчора?)
      </p>
      <h2>Значення рутини для адаптації</h2>
      <p>
        Повернення до рутини та побудови планів допоможе відчути контроль над своїм життям та діями. Спробуйте якомога
        швидше після повернення відновити «звичайні» притаманні вам у цивільному житті звички.
      </p>
      Наприклад:
      <ul>
        <li>Поверніться до звичайного режиму сну та фізичних вправ.</li>
        <li>
          Формуйте нові звички та дотримуйтеся їх. Спробуйте нові хобі та творчі заняття направленні на розслаблення.
        </li>
        <li>
          Обмежте вживання алкоголю. Надмірне вживання алкоголю може заплутати ваше мислення, затьмарити розсудливість
          та погіршити психічний стан.
        </li>
        <li>
          Вам потрібно відновити структуру дня: якщо раніше ваш день складався із приготування їжі, прогулянки та
          прання, поверніть всі ці ритуали до свого списку справ якомога швидше.
        </li>
      </ul>
      <p>
        <i>
          Невдовзі після повернення заплануйте відверту та чесну розмову з родиною про обов’язки. Ви всі повинні
          вирішити, як їх розділити тепер, коли ви вдома. Спочатку вам краще взятися за одну-дві справи, поступово
          беручи на себе більше обов’язків, коли ви звикнете бути вдома. Будьте готові йти на компроміс, щоб і ви, і
          члени вашої родини відчували, що потреби один одного розуміють і поважають.
        </i>
      </p>
      <hr />
      <h2>Значення самопідтримки для адаптації</h2>
      <p>
        Адаптація – це доволі тривалий процес, який має свої виклики. Усвідомлення того, що хвилювання, втомлюваність,
        бажання постійно бути поруч з близькими або навпаки бажання усамітнитися є адаптивними проявами психіки – це
        крок назустріч до швидшого та якісного повернення до цивільного життя.
      </p>
      <p>Звертайте увагу на свої бажання та відчуття та дослухайтеся них.</p>
      <p>Зокрема:</p>
      <ul>
        <li>Плануйте собі достатньо часу для відпочинку та відновлення</li>
        <li>
          Свідомо приділяйте увагу речам, на які ви маєте вплив. Наприклад, що саме ви хочете робити у вільний час.
        </li>
        <li>
          Створюйте позитивні моменти та дозвольте собі відволікатися. Звертайте увагу на те, що приносить вам
          полегшення і що викликає у вас різні відчуття. Ви найкраще зараз знаєте себе і розумієте, чого саме
          потребуєте.
        </li>
        <li>
          Практикуйте техніки релаксації – медитація, дихальні вправи. Вони допоможуть як зменшити стрес і тривожність,
          так і позитивно вплинуть на ваші когнітивні функції, допоможуть краще фокусуватись на задачах та бути
          уважнішим.
        </li>
      </ul>
      <hr />
      <h2>Практика уповільненого дихання</h2>
      <p>
        <i>
          Зробіть вдих, рахуючи від одного до чотирьох (1–2–3–4) — затримайте дихання на дві секунди (пауза на 1–2) —
          зробіть видих, рахуючи від одного до восьми (1–2–3–4–5–6–7–8).
        </i>
      </p>
      <p>
        <i>
          Важливо, щоб видих був удвічі довшим за вдих. Робіть цю вправу від десяти разів поспіль. Можна уявити, що ви
          «видихаєте» напругу.
        </i>
      </p>
      <hr />
      <ul>
        <li>
          Якщо ви тривожитесь та постійно маєте нав’язливі думки та занепокоєння, спробуйте виписувати ваші думки на
          папір щоранку та щовечора. Виписування інформації на папір заспокоює та допомагає визволити голову.
        </li>
        <li>
          Дозвольте собі відчувати різні емоції, навіть складні та незручні. Не тисніть на себе. Дайте собі час, щоб
          розкритися.
        </li>
      </ul>
      <h2>Почуття провини під час адаптації</h2>
      <p>
        Почуття провини тісно пов’язане з думкою про те, що людина вчинила щось, що суперечить її моральним принципам.
        Наприклад, ви можете відчувати провину, якщо ви залишили своїх побратимів в зоні бойових дій та були вимушені
        повернутись додому, або якщо ви втратили на фронті свого побратима та думаєте, що «якби я діяв інакше, він був
        би живий».
      </p>
      <p>
        Однак усвідомте, що вашої вини тут немає. Ви заслуговуєте на час з родиною. Ви заслуговуєте на безпеку. Ви
        заслуговуєте на життя.
      </p>
      <p>Якщо почуття провини дуже сильне, можна поставити собі такі запитання:</p>
      <ul>
        <li>Чи допомагає почуття провини вам та оточенню, чи воно приносить більше шкоди, ніж користі?</li>
        <li>Чи засуджували б ви тих, хто опинився на вашому місці?</li>
        <li>Чи могли ви насправді вплинути на події, що сталися?</li>
      </ul>
      <hr />
      <h2>Гнів та агресія під час адаптації</h2>
      <p>
        Війна потребує від людини негайних рішень та дій, мобілізації усіх сил, щоб вижити – це кардинально змінює
        характер людини, її світосприйняття. На фронті відчуття агресії допомагає долати ворога, проживати складні
        події. Агресія входить у звичку. Однак повернувшись додому воювати вже немає з ким і у звичайному житті агресія
        не знаходить виходу. Тому, адаптуючись до цивільного життя, варто навчитись розпізнавати сплески агресії
        завчасно та контролювати її.
      </p>
      <p>
        Серед способів «розрядки» для випускання гніву та агресії варто знайти активність, яка не завдаватиме шкоди, але
        допомагатиме психологічному розвантаженню. Це може бути енергійна фізична робота на кшталт рубання дров, заняття
        спортом – біг, бокс, заняття в тренажерному залі тощо.
      </p>
      <p>
        Також полегшити стан можуть більш пасивні фізичні дії – відверта розмова про свої почуття з уважним слухачем або
        навіть «розмови із собою» шляхом виписування своїх почуттів, думок та емоцій в зошит.
      </p>
      <p>
        <i>
          Щоб надалі напади люті виникали якомога рідше, постарайтеся простежити, що відбувається всередині та навколо
          вас у момент, коли починає накопичуватися роздратування. Причиною гніву може бути навколишнє оточення, колишні
          бойові рефлекси або поєднання різних факторів. Усвідомлення сторін свого життя, де гнів проявляється
          найчастіше, допоможе вам краще зрозуміти його механізм та як наслідок навчитись його контролювати.
        </i>
      </p>
      <hr />
      <h2>Важливі моменти, про які слід пам’ятати</h2>
      <ol>
        <li>
          Для повернення до цивільного життя потрібен час — це нормально. Не хвилюйтеся через певні труднощі, які
          виникають на шляху. Замість їх уникання, шукайте способи розв&apos;язання цих проблем.
        </li>
        <li>
          Не поспішайте повертати у своє життя обов’язки та діяльність. Варто робити все поступово та не навантажувати
          себе.
        </li>
        <li>
          Відновіть зв’язок зі своєю соціальною підтримкою. Можливо, вам зовсім цього не хочеться, але все одно зробіть
          це. Соціальна підтримка має вирішальне значення для успішної реінтеграції.
        </li>
        <li>
          Нагадуйте своїм близьким, що ви їх любите. Близькі люди мають розуміти, що вам потрібен час на адаптацію.
          Проявляйте до них емоційну близькість, щоб вони відчували себе бажаними.
        </li>
        <li>
          Усвідомте, що вам потрібно поговорити про досвід, який ви отримали. Ви можете робити це поступово. Якщо ви не
          можете поговорити з родиною чи друзями, обов’язково зверніться по кваліфіковану допомогу.
        </li>
      </ol>
    </Article>
  );
}

export default YakAdaptuvatysDoPovsiakdennohoZhyttiaPisliaPovernenniaDodomu;
