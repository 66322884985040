import React from 'react';
import PropTypes from 'prop-types';
import css from './cards.module.scss';
import classNames from 'classnames';

Cards.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool
};

export default function Cards({ children, className, loading }) {
  return (
    <div className={classNames(css.cards, className)} role='list' aria-busy={loading}>
      {children}
    </div>
  );
}
