export const messages = {
  en: {
    toggleCivil: 'Civil',
    toggleMilitary: 'Military',
    tagsFilterTitle: 'Filter by topic',
    pageNotExists: 'This page does not exist',
    workSearchPageSubtitle: 'Find the job that meets your demands, skills, and potential',
    salaryFilterTitle: 'Salary:',
    salaryFilterCurrency: 'UAH',
    salaryFilterCurrencyPrefix: 'From',
    searchVacancyPlaceholder: 'Search for a vacancy',
    locationSelectPlaceholder: 'Select a location',
    employmentTypeFilterTitle: 'Employment:',
    employmentTypeFilterFulltime: 'Full-time',
    employmentTypeFilterParttime: 'Part-time',
    workSearchPagePaginationMsg: 'More vacancies',
    workSearchPageResetFilters: 'Clear all',
    seachServicePlaceholder: 'Search for a service',
    educationCoursesPageSubtitle: 'Learn civil and combine it with military to become unique',
    searchEducationCoursePlaceholder: 'Search for a course',
    searchEventPlaceholder: 'Search for an event',
    educationCourseVendorFilterPlaceholder: 'All providers of courses',
    educationCoursePagePaginationMsg: 'More courses',
    emptyPlaceholderSubtitle: 'Use simple recommendations:',
    emptyPlaceholderMsg1: 'Check the correctness of writing the request',
    emptyPlaceholderMsg2: 'Try using a more general query',
    servicesEmptyPlaceholderTitle: 'There are no services for your request yet',
    servicesEmptyPlaceholderMsg: 'Try to find another service',
    workSearchEmptyPlaceholderTitle: 'There are no vacancies for your request yet',
    workSearchEmptyPlaceholderMsg: 'Try to find another vacancy',
    educationCoursesEmptyPlaceholderTitle: 'There are no courses for your request yet',
    educationCoursesEmptyPlaceholderMsg: 'Try to find another course',
    globalSearchEmptyPlaceholderMsg1: 'Check that all words are spelled correctly.',
    globalSearchEmptyPlaceholderMsg2: 'Try using other keywords.',
    proposeServiceTitle: 'Fill out the form to propose a service',
    proposeServiceTitlePlaceholder: 'Service title',
    formUrlPlaceholder: 'Link to the site',
    formLocationPlaceholder: 'Location',
    formDescriptionPlaceholder: 'Comment',
    proposeEventTitle: 'Fill out the form to propose an event',
    proposeEventTitlePlaceholder: 'Event title',
    eventOrganiserPlaceholder: 'Organizer',
    proposeEventUrlPlaceholder: 'Link to the event site',
    eventLinkMsg: 'Go to the event',
    proposeMapLocationTitle: 'Fill out the form to propose a location',
    proposeMapLocationTitlePlaceholder: 'Location title',
    proposeMapLocationDiscountPlaceholder: '% Discount',
    mapRequirementsPlaceholder: 'Necessary documents',
    proposeMapLocationAddressPlaceholder: 'Address',
    eventsPageSubtitle: 'Events for veteran community',
    mapPageTitle: 'Digital map of businesses loyal to defenders',
    mapPageSubtitle:
      'On this map, you can find businesses that provide services at a discount or for free to veterans, military, and their families',
    mapPageMarkerRoute: 'Directions',
    mapPageMarkerDiscount: 'Discount',
    mapLayerCaption1: 'Barber shops',
    mapLayerCaption2: 'Leisure',
    mapLayerCaption3: 'Shops',
    mapLayerCaption4: 'Medical services',
    mapLayerCaption5: 'Sport',
    mapLayerTitle1: 'Barbershop',
    mapLayerTitle2: 'Leisure',
    mapLayerTitle3: 'Shop',
    mapLayerTitle4: 'Medical facility',
    mapLayerTitle5: 'Gym',
    eventsPagePaginationMsg: 'More events',
    proposeMapLocationSuccess: 'The offer to create a location has been sent!',
    proposeEventSuccess: 'The offer to create an event has been sent!',
    proposeServiceSuccess: 'The offer to create a service has been sent!',
    serviceBannerLinkMsg1: 'Call',
    serviceBannerLinkMsg2: 'View',
    servicesPagePaginationMsg: 'More services'
  },
  default: {
    toggleCivil: 'Цивільні',
    toggleMilitary: 'Військові',
    tagsFilterTitle: 'Фільтруй за темою',
    pageNotExists: 'Такої сторінки не існує',
    workSearchPageSubtitle: 'Знайди роботу, яка відповідає твоїм потребам, навичкам та потенціалу',
    salaryFilterTitle: 'Рівень ЗП:',
    salaryFilterCurrency: 'грн.',
    salaryFilterCurrencyPrefix: 'Від',
    searchVacancyPlaceholder: 'Шукати вакансію',
    locationSelectPlaceholder: 'Обрати локацію',
    employmentTypeFilterTitle: 'Зайнятість:',
    employmentTypeFilterFulltime: 'Повна',
    employmentTypeFilterParttime: 'Часткова',
    workSearchPagePaginationMsg: 'Більше вакансій',
    workSearchPageResetFilters: 'Скинути все',
    seachServicePlaceholder: 'Шукати послугу',
    educationCoursesPageSubtitle: 'Вивчай цивільне та поєднуй з військовим, щоб бути унікальним',
    searchEducationCoursePlaceholder: 'Шукати курс',
    searchEventPlaceholder: 'Шукати подію',
    educationCourseVendorFilterPlaceholder: 'Всі надавачі курсів',
    educationCoursePagePaginationMsg: 'Більше курсів',
    emptyPlaceholderSubtitle: 'Скористайся простими рекомендаціями:',
    emptyPlaceholderMsg1: 'Перевір правильність написання запиту',
    emptyPlaceholderMsg2: 'Спробуй використати більш загальний запит',
    servicesEmptyPlaceholderTitle: 'За твоїм запитом поки немає послуг',
    servicesEmptyPlaceholderMsg: 'Спробуй знайти іншу послугу',
    workSearchEmptyPlaceholderTitle: 'За твоїм запитом поки немає вакансій',
    workSearchEmptyPlaceholderMsg: 'Спробуй знайти іншу вакансію',
    educationCoursesEmptyPlaceholderTitle: 'За твоїм запитом поки немає курсів',
    educationCoursesEmptyPlaceholderMsg: 'Спробуй знайти інший курс',
    globalSearchEmptyPlaceholderMsg1: 'Перевірте, що всі слова написані правильно.',
    globalSearchEmptyPlaceholderMsg2: 'Спробуйте використати інші ключові слова.',
    proposeServiceTitle: 'Заповніть форму для створення послуги',
    proposeServiceTitlePlaceholder: 'Назва послуги',
    formUrlPlaceholder: 'Посилання на сайт',
    formLocationPlaceholder: 'Локація',
    formDescriptionPlaceholder: 'Коментар',
    proposeEventTitle: 'Заповніть форму для створення події',
    proposeEventTitlePlaceholder: 'Назва події',
    eventOrganiserPlaceholder: 'Організатор',
    proposeEventUrlPlaceholder: 'Посилання на подію',
    eventLinkMsg: 'Перейти на подію',
    proposeMapLocationTitle: 'Заповніть форму для створення локації',
    proposeMapLocationTitlePlaceholder: 'Назва локації',
    proposeMapLocationDiscountPlaceholder: '% Знижки',
    mapRequirementsPlaceholder: 'Необхідні документи',
    proposeMapLocationAddressPlaceholder: 'Адреса',
    eventsPageSubtitle: 'Заходи для ветеранської спільноти',
    mapPageTitle: 'Цифрова мапа бізнесів лояльних до захисників',
    mapPageSubtitle:
      'На цій мапі зібрані бізнеси які надають послуги або сервіси зі знижкою або безкоштовно для ветеранів, військовослужбовців та їх сімей',
    mapPageMarkerRoute: 'Прокласти маршрут',
    mapPageMarkerDiscount: 'Знижка',
    mapLayerCaption1: 'Барбершопи',
    mapLayerCaption2: 'Дозвілля',
    mapLayerCaption3: 'Магазини',
    mapLayerCaption4: 'Медичні послуги',
    mapLayerCaption5: 'Спорт',
    mapLayerTitle1: 'Барбершоп',
    mapLayerTitle2: 'Дозвілля',
    mapLayerTitle3: 'Магазин',
    mapLayerTitle4: 'Медичний заклад',
    mapLayerTitle5: 'Спортзал',
    eventsPagePaginationMsg: 'Більше подій',
    proposeMapLocationSuccess: 'Пропозиція для створення локації надіслана!',
    proposeEventSuccess: 'Пропозиція для створення послуги надіслана!',
    proposeServiceSuccess: 'Пропозиція для створення послуги надіслана!',
    serviceBannerLinkMsg1: 'Зателефонувати',
    serviceBannerLinkMsg2: 'Переглянути',
    servicesPagePaginationMsg: 'Більше послуг'
  }
};
