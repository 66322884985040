export const messages = {
  en: {
    feedbackPageSubtitle: 'This will help make the Obrii platform even better',
    feedbackFormHeaderPlaceholder: 'The subject of the letter',
    feedbackFormDescriptionPlaceholder: 'The text of the message',
    feedbackSuccess: 'Thank you! We have received your feedback and will process it.'
  },
  default: {
    feedbackPageSubtitle: 'Це допоможе зробити платформу Обрій ще кращою',
    feedbackFormHeaderPlaceholder: 'Тема листа',
    feedbackFormDescriptionPlaceholder: 'Текст повідомлення',
    feedbackSuccess: 'Дякуємо! Ми отримали Ваш відгук і опрацюємо його.'
  }
};
