import React from 'react';
import PropTypes from 'prop-types';
import css from './services.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

Card.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  className: PropTypes.string.isRequired
};

export default function Card({ icon, title, list, className }) {
  return (
    <div className={css.card}>
      <div className={classNames(css.header, className)}>
        <div className={css.icon}>{icon}</div>
        <div className={css.title}>
          <span>{title}</span>
        </div>
        {icon}
      </div>
      <ul className={css.links}>
        {list.map((item, i) => (
          <Link to={item.url} key={i}>
            <li>{item.title}</li>
          </Link>
        ))}
      </ul>
    </div>
  );
}
