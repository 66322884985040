import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import css from './banner.module.scss';
import classNames from 'classnames';
import { LEFT, POSITIONS, RIGHT } from 'src/constants/positions';
import Description from 'src/components/description-block/description';
import Complaint from 'src/pages/complaint/complaint';
import { ReactComponent as LocationIcon } from 'src/assets/location.svg';
import * as p from 'src/constants/page-classnames';
import { LanguageSettingsContext } from 'src/utils/language-context';

Banner.propTypes = {
  onClick: PropTypes.func.isRequired,
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string,
    phone: PropTypes.string,
    imgUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    locationName: PropTypes.string
  }),
  imagePosition: PropTypes.oneOf([RIGHT, LEFT]).isRequired,
  className: PropTypes.oneOf([
    p.CAREER_INFO,
    p.EDUCATION,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.VETERAN_LOCATIONS
  ]).isRequired
};

export default function Banner({ onClick, service, imagePosition, className }) {
  const { msg } = useContext(LanguageSettingsContext);

  function handleServiceUrlClick(service) {
    onClick({ title: service.title, url: service.url });
  }

  function handleServicePhoneClick(service) {
    onClick({ title: service.title, url: service.url, phone: service.phone });
  }

  return (
    <Complaint
      key={service.id}
      targetTitle={service.title}
      alignRight
      wrapper={(complaint, onMouseEnter, onMouseLeave) => (
        <div
          className={classNames(css.banner, css[className], css[POSITIONS.find((p) => p.id === imagePosition).name])}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          {complaint}
          <div className={css.container}>
            <div className={css.image}>
              <img src={service.imgUrl} alt={service.title} />
            </div>
            <div className={css.content}>
              <div className={css.textGroup}>
                <span className={css.title}>{service.title}</span>
                <Description description={service.description} />
                <div className={css.footer}>
                  {service.phone && (
                    <button onClick={() => handleServicePhoneClick(service)}>{msg('serviceBannerLinkMsg1')}</button>
                  )}
                  {service.url && (
                    <button onClick={() => handleServiceUrlClick(service)}>{msg('serviceBannerLinkMsg2')}</button>
                  )}
                  {service.locationName && (
                    <span className={css.location}>
                      <LocationIcon />
                      <p>{service.locationName}</p>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
}
