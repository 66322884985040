import * as yup from 'yup';
import * as v from 'src/utils/form/validate';

const maxNameLength = 128;
const maxDescriptionLength = 2000;

export default function getValidationSchema(msg) {
  const validationSchema = yup.object({
    title: v.validateName(yup, msg, msg('formValidationTitleMsg2'), maxNameLength),
    url: v.validateUrl(yup, msg),
    description: v.validateDescription(yup, msg, msg('formValidationDescriptionMsg2'), maxDescriptionLength, false)
  });

  return validationSchema;
}
