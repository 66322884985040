export const messages = {
  en: {
    partnershipPageTitle: 'Partners and service providers',
    partnershipPageSubtitle:
      '95 partners were involved, which include central executive authorities, local self-government bodies, public organizations and commercial companies',
    partnershipFilterTagTitle: 'Filter by category',
    becomePartnerPageTitle: 'Have a partnership offer?',
    becomePartnerPageSubtitle:
      'Fill out the contact form, and we will tell you about all the advantages of cooperation with veterans and the Obrii platform',
    becomePartnerFormHeaderPlaceholder: 'The subject of the letter',
    becomePartnerFormProposalPlaceholder: 'Describe your offer',
    becomePartnerFormCompanyPlaceholder: 'The company name',
    partnershipTagCaption0: 'International funds',
    partnershipTagCaption1: 'Educational services',
    partnershipTagCaption2: 'Psychological services',
    partnershipTagCaption3: 'Social services',
    partnershipTagCaption4: 'Sport',
    partnershipTagCaption5: 'Universities',
    partnershipTagCaption6: 'Medical services',
    partnershipTagCaption7: 'Media support',
    partnershipTagCaption8: 'Entrepreneurship',
    partnershipTagCaption9: 'Centers of prosthetics',
    partnershipTagCaption10: 'Legal assistance',
    partnershipTagCaption11: 'Local Government',
    partnershipTagCaption12: 'Career materials',
    partnershipTagCaption13: 'Ministries',
    partnershipSection0: 'Central government and their initiatives',
    partnershipSection1: 'Local self-government and their initiatives',
    partnershipSection2: 'Centers of prosthetics',
    partnershipSection3: 'NGOs',
    partnershipSection4: 'Higher educational institutions',
    partnershipSection5: 'Military units and initiatives',
    partnershipSection6: 'Charitable funds',
    partnershipSection7: 'Business representatives',
    becomePartnerSuccess:
      'Your information has been sent. Our manager will contact you within 24 hours to discuss the details of cooperation.'
  },
  default: {
    partnershipPageTitle: 'Партнери та надавачі послуг',
    partnershipPageSubtitle:
      'Залучено 95 партнерів, до яких належать центральні органи виконавчої влади, органи місцевого самоврядування, громадські організації та комерційні компанії',
    partnershipFilterTagTitle: 'Фільтруй за категорією',
    becomePartnerPageTitle: 'Маєте пропозицію партнерства?',
    becomePartnerPageSubtitle:
      'Заповніть контактну форму, а ми розповімо про всі переваги співпраці з ветеранами та платформою «Обрій»',
    becomePartnerFormHeaderPlaceholder: 'Тема листа',
    becomePartnerFormProposalPlaceholder: 'Опишіть вашу пропозицію',
    becomePartnerFormCompanyPlaceholder: 'Назва компанії',
    partnershipTagCaption0: 'Міжнародні фонди',
    partnershipTagCaption1: 'Освітні послуги',
    partnershipTagCaption2: 'Психологічні послуги',
    partnershipTagCaption3: 'Соціальні послуги',
    partnershipTagCaption4: 'Спорт',
    partnershipTagCaption5: 'Університети',
    partnershipTagCaption6: 'Медичні послуги',
    partnershipTagCaption7: 'Медіа підтримка',
    partnershipTagCaption8: 'Підприємництво',
    partnershipTagCaption9: 'Центри протезування',
    partnershipTagCaption10: 'Юридична допомога',
    partnershipTagCaption11: 'Місцеве самоврядування',
    partnershipTagCaption12: "Кар'єрні матеріали",
    partnershipTagCaption13: 'Міністерства',
    partnershipSection0: 'Центральні органи виконавчої влади та їх ініціативи',
    partnershipSection1: 'Органи місцевого самоврядування та їх ініціативи',
    partnershipSection2: 'Центри протезування',
    partnershipSection3: 'Громадські організації',
    partnershipSection4: 'Вищі навчальні заклади',
    partnershipSection5: 'Військові підрозділи та ініціативи',
    partnershipSection6: 'Благодійні фонди',
    partnershipSection7: 'Представники бізнесу',
    becomePartnerSuccess:
      "Вашу інформацію надіслано. Наш менеджер зв'яжеться з Вами протягом 24 годин для обговорення деталей співпраці."
  }
};
