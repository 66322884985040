import barbershop from 'src/assets/map/barbershop.svg';
import leisure from 'src/assets/map/leisure.svg';
import shop from 'src/assets/map/shop.svg';
import medicalSupport from 'src/assets/map/medical-support.svg';
import sport from 'src/assets/map/sport.svg';

export const BARBERSHOP = 0,
  LEISURE = 1,
  SHOP = 2,
  MEDICAL_SUPPORT = 3,
  SPORT = 4;

export const CATEGORIES = [
  { id: BARBERSHOP, layerCaption: 'mapLayerCaption1', title: 'mapLayerTitle1', iconUrl: barbershop },
  { id: LEISURE, layerCaption: 'mapLayerCaption2', title: 'mapLayerTitle2', iconUrl: leisure },
  { id: SHOP, layerCaption: 'mapLayerCaption3', title: 'mapLayerTitle3', iconUrl: shop },
  { id: MEDICAL_SUPPORT, layerCaption: 'mapLayerCaption4', title: 'mapLayerTitle4', iconUrl: medicalSupport },
  { id: SPORT, layerCaption: 'mapLayerCaption5', title: 'mapLayerTitle5', iconUrl: sport }
];
