import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';
import qs from 'qs';

const educationApiUrl = '/EducationCourses';
const educationVendorsApiUrl = `${educationApiUrl}/vendors`;

const ApiUrls = {
  educationApiUrl,
  educationVendorsApiUrl
};

function GetSearchRequest(page = 1, query = null) {
  return obriyApiAxios
    .get(educationApiUrl, {
      params: { ...query, page },
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { allowDots: true });
        }
      }
    })
    .then((value) => value.data);
}

function GetVendors() {
  return obriyApiAxios.get(educationVendorsApiUrl).then((response) => response.data);
}

const API = {
  search: GetSearchRequest,
  getVendors: GetVendors
};

export { ApiUrls };
export default API;
