import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function HroshovaKompensatsiiaNaPalneRemontTaTransportneObsluhovuvannia() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>Грошова компенсація на пальне, ремонт та транспортне обслуговування</h3>
      <p>
        На бензин, ремонт і техобслуговування автомобіля або мотоколяски передбачено 22% або 16% від прожиткового
        мінімуму щомісяця.
      </p>
      <p>На транспортне обслуговування – 29 % від прожиткового мінімуму щомісяця.</p>
      <p>Дата призначення компенсації визначається датою подання заяви.</p>
      <p>Компенсації виплачуються двічі на рік – у березні та вересні.</p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>Подати заяву в структурний підрозділ з питань соціального захисту населення.</p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>Особи з інвалідністю, законні представники недієздатних осіб з інвалідністю, дітей з інвалідністю:</p>
      <ul>
        <li>Особи, яким надано автомобіль або мотоколяску;</li>
        <li>Ті, хто має право на автомобіль, але користуються власним;</li>
        <li>
          Особи, які мають право на компенсацію транспортного обслуговування (перебувають на обліку для
          безоплатного/пільгового забезпечення автомобілем).
        </li>
      </ul>
    </Article>
  );
}
