import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as Obriy } from 'src/assets/footer_obriy_logo.svg';
import { ReactComponent as ObriyEn } from 'src/assets/footer_obriy_logo_en.svg';
import { ReactComponent as Facebook } from 'src/assets/facebook.svg';
import { ReactComponent as Instagram } from 'src/assets/instagram.svg';
import css from './footer.module.scss';
import * as r from 'src/constants/routes';
import { ReactComponent as MoLogo } from 'src/assets/banner-mo-logo.svg';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { ENGLISH, UKRAINE } from 'src/constants/languages';
import Feedback from 'src/pages/feedback/feedback';

export default function Footer() {
  const { language, msg, link } = useContext(LanguageSettingsContext);
  const year = new Date().getFullYear();

  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <div className={css.topContainer}>
          <div className={css.logoTextContainer}>
            <Link to={link(r.GENERAL.path)}>
              {language === UKRAINE && <Obriy />}
              {language === ENGLISH && <ObriyEn />}
            </Link>
            <MoLogo className={css.moLogo} />
            <p>{msg('footerTitle')}</p>
          </div>
          <div className={css.footerMenuContainer}>
            <div className={css.servicesContainer}>
              <nav>
                <h4>{msg('servicesTitle')}</h4>
                {r.SERVICES_ROUTES.map((service, i) => (
                  <li key={i}>
                    <NavLink to={link(service.path)}>{msg(service.title)}</NavLink>
                  </li>
                ))}
              </nav>
            </div>
            <hr />
            <div className={css.feedbackContainer}>
              <div className={css.questions}>
                <h4>{msg('footerContactsTitle')}</h4>
                <p>{msg('footerContactsEmail')}</p>
                <a href='mailto: support@obrii.mil.gov.ua'>support@obrii.mil.gov.ua</a>
                <Feedback trigger={<p className={css.clickable}>{msg('footerContactsFeedback')}</p>} />
                <NavLink to={link(r.PARTNERSHIP.path)}>{msg('partnershipRoute')}</NavLink>
              </div>
              <div className={css.socialMedia}>
                <a href='https://www.facebook.com/profile.php?id=61555744342745' target='_blank' rel='noreferrer'>
                  <Facebook />
                </a>
                <a href='https://www.instagram.com/obrii.ua/' target='_blank' rel='noreferrer'>
                  <Instagram />
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={css.bottomContainer}>
          <div className={css.copyrightContainer}>
            <p>{msg('footerCustomer')}</p>
            <p>©{year}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
