import React, { useState, useRef, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './popup.module.scss';
import { useOutsideClickTracker } from 'src/hooks/shared-hooks';
import { ReactComponent as Tune } from 'src/assets/tune.svg';
import * as p from 'src/constants/page-classnames';
import { LanguageSettingsContext } from 'src/utils/language-context';

FiltersPopup.propTypes = {
  onReset: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  pageName: PropTypes.oneOf([p.WORK_SEARCH])
};

export default function FiltersPopup({ onReset, children, pageName }) {
  const containerRef = useRef(null);
  const { msg } = useContext(LanguageSettingsContext);
  const [isOpen, setIsOpen] = useState(false);
  const { reset, resetField } = useFormContext();

  useOutsideClickTracker(containerRef, () => {
    if (!isOpen) return;

    resetField('employmentTypes[]');
    resetField('salary');

    setIsOpen(false);
  });

  function handleReset() {
    onReset();
    setIsOpen(false);
  }

  function changePopupState() {
    if (isOpen) reset();
    setIsOpen(!isOpen);
  }

  return (
    <div className={classNames(css.container, css[pageName], isOpen ? css.active : '')} ref={containerRef}>
      <div className={css.filtersButton} onClick={changePopupState}>
        <Tune />
        <span className={css.popupFiltersButtonTitle}>{msg('popupFiltersMsg')}</span>
      </div>
      <div className={classNames(css.modal, isOpen ? css.visible : css.hidden)} role='dialog'>
        {children}
        <div className={css.buttonsGroup}>
          <button type='submit' onClick={() => setIsOpen(false)} className={css.apply}>
            {msg('popupApplyMsg')}
          </button>
          <button onClick={handleReset} className={css.reject}>
            {msg('popupClearMsg')}
          </button>
        </div>
      </div>
    </div>
  );
}
