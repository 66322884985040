import * as yup from 'yup';

export function searchInputValidationSchema(msg, inputName, maxTextLength = 200) {
  const textTooLong = `${msg('formValidationPrefixMsg1')} ${msg('formValidationTextMsg')} ${msg(
    'formValidationLengthMsg1'
  )} ${maxTextLength} ${msg('formValidationLengthMsg2')}.`;
  const validationSchema = yup.object({
    [inputName]: yup.string().max(maxTextLength, textTooLong)
  });

  return validationSchema;
}
