import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function SuitsydalniDumkyChomuVynykaiutTaYakSpravytys() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Суїцидальні думки: чому виникають та як справитись?</h1>У житті людини можуть траплятися нестерпно важкі
      ситуації, коли здається, що єдиний можливий вихід зі становища — суїцид. Наш мозок у такі моменти шукає
      найпростіші способи подолання проблеми, до яких і відноситься самогубство.
      <p>
        Мене звати Світлана Тімофєєва. Я — психотерапевтка платформи Rozmova. Понад 30 років займаюсь психотерапією,
        досліджую причинно-наслідкові зв’язки та поведінку людей. За роки моєї роботи працювала з різними віковими
        категоріями та історіями життя людей, а також проводила власні дослідження суїцидальної поведінки. Тому в цій
        статті хочу розкрити тему суїцидальних думок, їх впливу на життя та що робити, коли вони виникають.
      </p>
      <h2>Суїцидальні думки — норма?</h2>
      <p>
        <b>Це нормальна реакція психіки на ненормальні події.</b> Людина може не відчувати підтримки близьких або
        друзів, не вміти знаходити рішення в критичних ситуаціях та не мати моральних сил переживати складне становище
        знову і знову.
      </p>
      <p>
        Важливо зрозуміти, які причини виникнення суїцидальних думок, що блокують здатність людини знайти вихід із
        критичних ситуацій. До них відносяться:
      </p>
      <ul>
        <li>травматичні події, як-от сексуальне, фізіологічне, психологічне насилля;</li>
        <li>емоційна або фізична депривація — неможливість протягом довгого часу задовольнити необхідні потреби;</li>
        <li>стресові зміни у житті, сильний зовнішній та внутрішній тиски;</li>
        <li>відчуття, що зайвий, і після самогубства життя стане кращим;</li>
        <li>почуття провини та сорому;</li>
        <li>занижена самооцінка;</li>
        <li>зовнішні події, такі як війна.</li>
      </ul>
      <p>
        Варто зауважити, що ми живемо в країні, де відбуваються активні бойові дії. За теперішніх обставин я чую багато
        різних історій, коли навіть досвідчені та сильні духом говорять про суїцид або навіть скоюють його.
      </p>
      <h2>Що робити з цим станом?</h2>
      <p>Ми вже розібралися, що суїцидальні думки виникають не просто так.</p>
      <p>
        <b>По-перше</b>, проаналізуйте: це тільки думка, чи ви вже почали складати план дій?
      </p>
      <p>
        <b>По-друге</b>, розкажіть про це близькій вам людині, але без очікувань отримати професійну допомогу.
      </p>
      <p>
        Часто ми боїмося говорити про свої проблеми близьким, вважаючи, що перенавантажимо їх негативом. Можливо, це
        так, але ви можете попередити людину, що не очікуєте почути від неї розв&apos;язання своєї проблеми. Наразі все,
        що вам потрібно — це людина поруч, яка вислухає та емоційно підтримає. У такий спосіб ви знімете напругу з
        розмови та насправді допоможете собі.
      </p>
      <p>
        <b>Третій крок</b> — знайти варіанти виходу з важкої ситуації. Спробуйте скласти план розв’язання проблеми:
        згадайте власний досвід, досвід своїх знайомих; порадьтесь з близькими для вас людьми; увімкніть фантазію, адже
        навіть найбільш, на вашу думку, нереалістичний план може стати розв’язанням проблеми.
      </p>
      <p>
        Якщо ви розумієте, що не можете самостійно впоратися зі своїм станом, тоді необхідно звернутися до фахівця.
        Вміння проживати важкі ситуації та знаходити з них вихід залежить від сформованих у нас навичок мислення,
        вольових рис та організації особистості. Тому це нормально потребувати допомоги спеціаліста.
      </p>
      <p>
        А якщо ви ще на першому етапі зрозуміли, що почали складати план скоєння суїциду — не зволікайте зі зверненням
        до фахівця. Будь-яка нестерпна ситуація має початок і кінець, і це точно не про кінець життя.
      </p>
      <h2>Як впоратись зі своїми відчуттями?</h2>
      <p>
        У своїй роботі з військовими я часто зіштовхуюся з безвихіддю, нерозумінням, несправедливістю, провиною
        вцілілого. Ці та багато інших відчуттів можуть викликати нестерпний душевний біль, через який з’являються думки
        залишити цей світ.
      </p>
      <p>
        Суїцидальні думки є дуже руйнівними, адже не дають мозку опрацювати інші варіанти рішення. Так, емоції керують
        нами, тому часто в стані афекту ми приймаємо такі рішення. Виникає питання: що робити і як собі допомогти?
      </p>
      <p>
        Спробуйте змінити фокус уваги. Тут і зараз. Це може бути прохолодний душ, ходьба, розмова, будь-яка фізична
        активність, творча діяльність. Не знаєте, чим хотілося б зайнятися? Починайте перелічувати все навколо. Будь-яка
        активність переведе ваші думки в інший напрямок.
      </p>
      <p>
        Якщо вживаєте психоактивні речовини, не виняток, що вони можуть впливати на ваш стан. У такому випадку
        старайтеся завжди мати поруч людину, яка зможе вас вчасно зупинити. Спробуйте розібратися у причинно-наслідкових
        зв’язках надмірного або неконтрольованого вживання психоактивних речовин. Можливо, варто звернутися до
        спеціаліста.
      </p>
      <p>
        Стан афекту також викликають емоційні конфлікти. Конфлікт — це зіткнення інтересів, внаслідок якого виникає
        відчуття гніву. Є два варіанти розвитку подій: проявлення гніву назовні або впадання у внутрішню захисну
        позицію. У конструктивній ситуації ви проявляєте гнів назовні та намагаєтеся знайти кроки вирішення конфліктної
        ситуації. У захисній — злитеся, ображаєтеся, заморожуєте конфлікт і прагнете помсти. Спробуйте чесно собі
        відповісти, де ви.
      </p>
      <p>
        У будь-якому разі пам’ятайте: жоден конфлікт не вартий вашого життя. Усе минає і стан афекту теж. За ніччю
        завжди приходить день.
      </p>
      <h2>Демонстративна поведінка: як це працює?</h2>
      <p>
        За суїцидальними думками та спробами піти з життя може стояти демонстрація як засіб привернути увагу, щось
        довести.
      </p>
      <p>
        Якщо ви зрозуміли, що це про вас, подумайте, чи існує інший засіб привернути увагу. І головне — чи дійсно воно
        варте ціни вашого життя, чи матиме потім це сенс?
      </p>
      <p>
        Можна виписати свої думки на папері, щоб подивитися зі сторони на ситуацію, обміркувати можливі варіанти
        розв&apos;язання своєї проблеми.
      </p>
      <h2>Війна та суїцид</h2>
      <p>
        На жаль, наразі йде війна. Це ненормальні життєві обставини, тим паче для тих, хто у гарячих точках і бачить
        речі, які не має бачити людина. Ми втрачаємо те, що надавало життю сенсу: рідних, близьких, домівки, частини
        тіла. Особливо важко, коли свій біль немає з ким розділити, щоб отримати підтримку, хоча б мовчазну, та просто
        побути поруч із кимось для нас важливим.
      </p>
      <p>
        Психіка може не витримувати такого навантаження та гостро реагувати на події. Тому цілком можливим є виникнення
        думок про смерть, щоб не бачити, не відчувати, не переживати увесь фізичний і моральний біль, що несе війна.
      </p>
      <h2>Що можна зробити?</h2>
      <p>
        Подумайте, якій людині ви довіряєте і можете розказати про свої почуття. Це може бути хтось із вашого кола
        спілкування або фахівець. Наступним кроком потрібно опрацювати свої емоції та почуття. Звичайно, у важкому
        психологічному стані складно опановувати такі речі, але постарайтеся проаналізувати:
      </p>
      <ol>
        <li>Це думки чи в мене є план скоєння суїциду?</li>
        <li>Чи знаходжуся я на стадії здійснення спроби?</li>
        <li>Чи насправді я хочу себе вбити?</li>
      </ol>
      <p>Якщо ви маєте хоча б одну відповідь «так», тоді:</p>
      <ol>
        <li>Зверніться до спеціаліста.</li>
        <li>Розкажіть про це близьким або друзям.</li>
        <li>Структуруйте свої думки, шукайте причинно-наслідкові зв’язки.</li>
        <li>Знайдіть альтернативні варіанти розв&apos;язання своєї проблеми.</li>
      </ol>
      <p>
        Врешті-решт згадайте про свої мрії, цілі, близьких, тих, хто поруч та для кого так важливі ви, ваша увага чи
        допомога. Зачепіться за щось дійсно важливе. Завжди пам’ятайте, психіка шукає найпростіші варіанти, щоб уникнути
        важких переживань. Ви здатні знайти інші, можливо, складніші в реалізації, але точно кращі способи
        розв&apos;язання проблем. Усе мине. Афект піде. Життя буде врятоване.
      </p>
      <h2>Профілактика суїцидальних думок</h2>
      Важких і стресових життєвих ситуацій часом не уникнути, але суїцидальних думок — можна. Існує багато варіантів їх
      профілактики, тому ви точно знайдете оптимальний для себе. Ось декілька з них:
      <ul>
        <li>знайдіть сенс життя</li>
        <li>оберіть свою місію</li>
        <li>складіть план на 3, 5, 10 чи навіть 50 років свого життя</li>
        <li>поставте цілі та подумайте, як будете їх досягати.</li>
      </ul>
      <p>
        Пам’ятайте: про суїцидальні думки не треба мовчати. Не залишайтеся з ними на самоті. Важливо говорити,
        розбиратися, щоб зрештою знайти рішення.
      </p>
      <p>
        Шукайте підтримку у близьких і рідних, а краще — звертайтеся на гарячі лінії, до фахівців, які знають, як із цим
        працювати.
      </p>
      <p>
        <b>
          <i>Світлана Тімофєєва — психотерапевтка платформи Rozmova</i>
        </b>
      </p>
    </Article>
  );
}

export default SuitsydalniDumkyChomuVynykaiutTaYakSpravytys;
