export const NONE = 0,
  TOP = 1,
  RIGHT = 2,
  BOTTOM = 3,
  LEFT = 4;

export const POSITIONS = [
  {
    id: NONE,
    name: ''
  },
  {
    id: TOP,
    name: 'top'
  },
  {
    id: RIGHT,
    name: 'right'
  },
  {
    id: BOTTOM,
    name: 'bottom'
  },
  {
    id: LEFT,
    name: 'left'
  }
];
