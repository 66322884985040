import Event from './item';
import PropTypes from 'prop-types';
import css from './daily-group.module.scss';
import { FULL_WEEK_DAYS, MONTHS_G_CASE } from 'src/constants/calendar';
import classNames from 'classnames';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { useContext } from 'react';

DailyGroup.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  groups: PropTypes.array.isRequired,
  groupKey: PropTypes.string.isRequired
};

export default function DailyGroup({ currentDate, groups, groupKey }) {
  const { msg } = useContext(LanguageSettingsContext);
  const locale = 'uk-UA';
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const date = new Date(groupKey);
  const groupYear = date.getFullYear();
  const groupMonth = date.getMonth();
  const groupDay = date.getDate();
  const dateString = date.toLocaleDateString(locale, { dateStyle: 'long' });
  const isToday = year === groupYear && month === groupMonth && day === groupDay;
  const isTomorrow = year === groupYear && month === groupMonth && day + 1 === groupDay;

  return (
    <div className={css.container}>
      {(isToday || isTomorrow) && (
        <div className={classNames(css.hint, isToday ? css.today : '', isTomorrow ? css.tomorrow : '')}>
          {isToday && <p>{msg('today')}</p>}
          {isTomorrow && <p>{msg('tomorrow')}</p>}
        </div>
      )}
      <div className={css.dateContainer} title={dateString}>
        <p>{msg(FULL_WEEK_DAYS.find((_, i) => date.getDay() === i))},&nbsp;</p>
        <time className={css.day} dateTime={dateString}>
          {date.getDate()}&nbsp;
        </time>
        <time className={css.month}>{msg(MONTHS_G_CASE[date.getMonth()])}</time>
      </div>
      <div className={css.eventsGroupContainer}>
        {groups.map((event) => (
          <Event event={event} key={event.id} />
        ))}
      </div>
    </div>
  );
}
