import generalBannerSoldier from '../assets/general/general-banner-soldier.webp';

export const GENERAL = {
    path: '/',
    relativePath: '',
    title: 'generalRoute',
    meta: {
      title: 'Інформаційна платформа для захисників | Офіційний сайт Обрій',
      description:
        'Отримайте інформацію та безкоштовні послуги для військовослужбовців, ветеранів та їхніх родин в єдиному місці на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  WORK = {
    path: '/work',
    relativePath: 'work'
  },
  WORK_SEARCH = {
    path: '/work/work-search',
    relativePath: 'work-search',
    title: 'workPageTitle',
    meta: {
      title: 'Пошук роботи для військовослужбовців | Офіційний сайт Обрій',
      description:
        'Отримайте інформацію та підтримку щодо пошуку роботи для військовослужбовців, ветеранів та їхніх родин на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  CAREER_INFO = {
    path: '/work/career-info',
    relativePath: 'career-info',
    title: 'careerMaterialsPageTitle',
    meta: {
      title: "Інформація про кар'єрні можливості | Офіційний сайт Обрій",
      description:
        "Дізнайтеся про кар'єрні можливості та професійні переваги для військовослужбовців та ветеранів на офіційному веб-сайті Обрій.",
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  EDUCATION = {
    path: '/education',
    relativePath: 'education'
  },
  EDUCATION_COURSES = {
    path: '/education/courses',
    relativePath: 'courses',
    title: 'educationPageTitle',
    meta: {
      title: 'Освітні курси для військовослужбовців | Офіційний сайт Обрій',
      description:
        'Ознайомтеся з доступними освітніми курсами та навчальними можливостями для військовослужбовців та ветеранів на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  EDUCATION_INFO = {
    path: '/education/info',
    relativePath: 'info',
    title: 'educationMaterialsPageTitle',
    meta: {
      title: 'Освітні матеріали для військовослужбовців та ветеранів | Обрій',
      description:
        'Ознайомтеся з освітніми матеріалами для військовослужбовців та ветеранів на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  PSYCHOLOGICAL_HELP = {
    path: '/psychological-help',
    relativePath: 'psychological-help',
    title: 'psychologicalHelpPageTitle',
    meta: {
      title: 'Інформація про психологічну підтримку | Офіційний сайт Обрій',
      description:
        'Отримайте інформацію та консультування щодо психологічної підтримки для військовослужбовців та їхніх родин на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  LEGAL_HELP = {
    path: '/legal-help',
    relativePath: 'legal-help',
    title: 'legalHelpPageTitle',
    meta: {
      title: 'Правова допомога для військовослужбовців | Офіційний сайт Обрій',
      description:
        'Дізнайтеся про доступні правові послуги та консультації для військовослужбовців та ветеранів на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  USEFUL_INFO = {
    path: '/useful-info',
    relativePath: 'useful-info',
    title: 'usefulInfoPageTitle',
    meta: {
      title: 'Корисна інформація для захисників | Офіційний сайт Обрій',
      description:
        'Отримайте корисну інформацію та поради для військовослужбовців, ветеранів та їхніх родин на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  EVENTS = {
    path: '/events',
    relativePath: 'events',
    title: 'eventsPageTitle',
    meta: {
      title: 'Події та заходи для захисників | Офіційний сайт Обрій',
      description:
        'Дізнайтеся про події та заходи, організовані для захисників та їхніх родин на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  PARTNERSHIP = {
    path: '/partnership',
    relativePath: 'partnership',
    title: 'partnershipRoute',
    meta: {
      title: 'Партнерство зі спільнотою Обрій | Офіційний сайт Обрій',
      description: 'Дізнайтеся про можливості партнерства та співпраці з громадськістю Обрій на офіційному веб-сайті.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  VETERAN = {
    path: '/veteran',
    relativePath: 'veteran'
  },
  BUSINESS_MAP = {
    path: '/veteran/map',
    relativePath: 'map',
    title: 'businessMapPageTitle',
    meta: {
      title: 'Цифрова мапа бізнесів лояльних до захисників | Офіційний сайт Обрій',
      description:
        'Бізнеси які надають послуги або сервіси зі знижкою або безкоштовно для військовослужбовців та їхніх родин на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  VETERAN_LOCATIONS = {
    path: '/veteran/locations',
    relativePath: 'locations',
    title: 'veteranLocationsPageTitle',
    meta: {
      title: 'Ветеранські локації | соціальна підтримка на Обрій',
      description: 'Ветеранські локації на Обрій. Отримайте соціальну підтримку та консультації.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  MEDICAL_SERVICES = {
    path: '/medical-services',
    relativePath: 'medical-services',
    title: 'medicalServicesPageTitle',
    meta: {
      title: 'Інформація про медичні послуги | Офіційний сайт Обрій',
      description:
        'Отримайте інформацію та консультування щодо медичних послуг для військовослужбовців та їхніх родин на офіційному веб-сайті Обрій.',
      openGraph: {
        img: generalBannerSoldier,
        type: 'website'
      }
    }
  },
  GLOBAL_SEARCH = {
    path: '/global-search',
    relativePath: 'global-search',
    title: 'globalSearchRoute'
  };

const routes = [
    GENERAL,
    WORK_SEARCH,
    CAREER_INFO,
    EDUCATION_COURSES,
    EDUCATION_INFO,
    PSYCHOLOGICAL_HELP,
    MEDICAL_SERVICES,
    LEGAL_HELP,
    USEFUL_INFO,
    EVENTS,
    PARTNERSHIP,
    BUSINESS_MAP,
    VETERAN_LOCATIONS,
    GLOBAL_SEARCH
  ],
  SERVICES_ROUTES = [
    WORK_SEARCH,
    EDUCATION_COURSES,
    PSYCHOLOGICAL_HELP,
    MEDICAL_SERVICES,
    LEGAL_HELP,
    USEFUL_INFO,
    BUSINESS_MAP,
    EVENTS
  ];

export { routes, SERVICES_ROUTES };
