import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LanguageSettingsContext } from 'src/utils/language-context';
import * as p from 'src/constants/page-classnames';
import { CAREER_INFO, EDUCATION_INFO } from 'src/constants/routes';
import PageHeader from 'src/components/page-header/page-header';
import ToggleInline from 'src/components/toggle/toggle-inline';
import ServicesFilters from './services-filters';
import css from './services-page.module.scss';
import API from './api/api';
import Services from './services';

ServicesPage.propTypes = {
  pageName: PropTypes.string.isRequired,
  className: PropTypes.oneOf([
    p.CAREER_INFO,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.EDUCATION,
    p.VETERAN_LOCATIONS
  ]).isRequired
};

export default function ServicesPage({ pageName, className }) {
  const { language, msg } = useContext(LanguageSettingsContext);
  const location = useLocation();
  const text = location.state?.text;

  const [filterValues, setFilterValues] = useState({
    military: false,
    locationId: '',
    text
  });

  const [category, isLoading] = useServices(pageName, language, msg);

  function handleFiltersChange(filters) {
    setFilterValues({ ...filterValues, ...filters });
  }

  const filters = { ...filterValues, categoryId: category?.id };
  const servicesKey = Object.entries(filters).join('-');

  return (
    <main className={css.servicesPage}>
      {!isLoading && (
        <PageHeader className={className} title={category?.name} description={category?.description}>
          <ServicesFilters pageName={pageName} initialFilters={filterValues} onSearch={handleFiltersChange} />
        </PageHeader>
      )}
      {isLoading && <span className={css.skeletonHeader} />}
      {!isLoading && (msg(CAREER_INFO.title) === pageName || msg(EDUCATION_INFO.title) === pageName) && (
        <div className={css.toggleBlock}>
          <ToggleInline
            labelLeft={msg('toggleCivil')}
            labelRight={msg('toggleMilitary')}
            onToggle={(military) => handleFiltersChange({ military })}
          />
        </div>
      )}
      {!isLoading && <Services key={servicesKey} filters={filters} className={className} />}
    </main>
  );
}

function useServices(pageName, language, msg) {
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    API.getCategories().then((categories) => {
      const category = categories?.find((c) => c.name === pageName);
      if (!category) {
        return Promise.reject(msg('pageNotExists'));
      }

      setCategory(category);
      setIsLoading(false);
    });
  }, [pageName, language, msg]);

  return [category, isLoading];
}
