import React, { useContext, useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { EVENTS } from 'src/constants/page-classnames';
import PageHeader from 'src/components/page-header/page-header';
import PaginatedFooter from 'src/components/paginated-footer/paginated-footer';
import EmptyPlaceholder from './list/empty-placeholder';
import DailyGroup from './list/daily-group';
import Filters from './filters';
import EventsCalendar from './calendar/calendar';
import API from './events-api';
import css from './events.module.scss';

export default function Events() {
  const { msg } = useContext(LanguageSettingsContext);
  const currentDate = new Date();
  const [selectedPage, setSelectedPage] = useState(1);
  const [filterValues, setFilterValues] = useState({
    text: '',
    locationId: '',
    dateFrom: currentDate.getTime()
  });
  const events = useEvents(selectedPage, filterValues);

  const hidePaginatedFooter = events.count === 0 || events.totalCount === 0 || events.count === events.totalCount;

  function handleFiltersChange(data) {
    setFilterValues({ ...filterValues, ...data });
    setSelectedPage(1);
  }

  return (
    <main className={css.events}>
      <div className={css.eventsContainer}>
        <PageHeader className={EVENTS} title={msg('eventsPageTitle')} description={msg('eventsPageSubtitle')}>
          <Filters initialFilters={filterValues} onSearch={handleFiltersChange} />
        </PageHeader>
        <div className={css.list}>
          <EventsCalendar currentDate={currentDate} onDateSelect={handleFiltersChange} />
          {events?.count === 0 && <EmptyPlaceholder />}
          {events?.count !== 0 &&
            Object.keys(events.items).map((date) => (
              <DailyGroup currentDate={currentDate} groups={events.items[date]} groupKey={date} key={date} />
            ))}
          {!hidePaginatedFooter && (
            <div className={css.pagerRow}>
              <PaginatedFooter
                caption={msg('eventsPagePaginationMsg')}
                onClick={() => setSelectedPage(selectedPage + 1)}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

function useEvents(selectedPage, filters) {
  const [events, setEvents] = useState({
    count: 0,
    totalCount: 0,
    items: []
  });

  useEffect(() => {
    const data = {
      text: filters.text,
      locationId: filters.locationId,
      dateFrom: filters.dateFrom
    };

    API.getEvents(selectedPage, data).then((response) => {
      if (!response || !response.items || response.items.length === 0) {
        setEvents({
          count: 0,
          totalCount: 0,
          items: []
        });
        return;
      }

      const groupByDayPredicate = (event) => {
        const scheduledAt = new Date(event.scheduledAt);
        return new Date(scheduledAt.getFullYear(), scheduledAt.getMonth(), scheduledAt.getDate());
      };

      setEvents((prev) => {
        if (selectedPage > 1) {
          const events = Object.keys(prev.items)
            .map((group) => prev.items[group])
            .flat();

          const ids = new Set(events.map((e) => e.id));
          const filteredEvents = response.items.filter((e) => !ids.has(e.id));

          return {
            items: groupBy([...events, ...filteredEvents], groupByDayPredicate),
            count: events.length + filteredEvents.length,
            totalCount: response.totalCount
          };
        }

        return {
          items: groupBy(response.items, groupByDayPredicate),
          count: response.items.length,
          totalCount: response.totalCount
        };
      });
    });
  }, [selectedPage, filters]);

  return events;
}
