import React, { useContext, useId } from 'react';
import PropTypes from 'prop-types';
import Filters from 'src/components/form/filters/filters';
import { ONLINE } from 'src/constants/locations';
import LocationsInput from 'src/components/form/search-select-input/locations-input';
import SearchInput from 'src/components/form/search/filters-form/search-input';
import css from './events.module.scss';
import { searchInputValidationSchema } from 'src/components/form/search/filters-form/helper';
import { LanguageSettingsContext } from 'src/utils/language-context';

EventsFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.shape({
    text: PropTypes.string,
    locationId: PropTypes.string
  }).isRequired
};

export default function EventsFilters({ onSearch, initialFilters }) {
  const { msg } = useContext(LanguageSettingsContext);
  const locationId = useId();
  const inputId = useId();

  return (
    <Filters
      initialFilters={initialFilters}
      onSearch={onSearch}
      validationSchema={searchInputValidationSchema(msg, 'text')}
      fieldsToSubmitOnChange={['text', 'locationId']}
      render={({ errors, _, registerAndClearOnChange }) => (
        <div className={css.filtersGroup}>
          <SearchInput
            id={inputId}
            name='text'
            placeholder={msg('searchEventPlaceholder')}
            error={errors?.text?.message}
            inputProps={registerAndClearOnChange('text')}
          />
          <LocationsInput
            id={locationId}
            name='locationId'
            defaultLocation={ONLINE}
            placeholder={msg('locationInputPlaceholder')}
          />
        </div>
      )}
    />
  );
}
