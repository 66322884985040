import { ReactComponent as MinEcon } from 'src/assets/partners/mecon.svg';
import { ReactComponent as MinSoc } from 'src/assets/partners/msoc.svg';
import { ReactComponent as MinVet } from 'src/assets/partners/mvet.svg';
import { ReactComponent as Pryncyp } from 'src/assets/partners/pryncyp.svg';
import { ReactComponent as InTarShev } from 'src/assets/partners/InTarShev.svg';
import { ReactComponent as Vartozhyty } from 'src/assets/partners/vartozhyty.svg';
import { ReactComponent as Prometheus } from 'src/assets/partners/prometheus.svg';
import { ReactComponent as Uridichnasotna } from 'src/assets/partners/uridichnasotna.svg';
import { ReactComponent as Baza } from 'src/assets/partners/baza.svg';
import { ReactComponent as Kse } from 'src/assets/partners/kse.svg';
import { ReactComponent as Unbroken } from 'src/assets/partners/unbroken.svg';
import { ReactComponent as Edra } from 'src/assets/partners/edra.svg';
import { ReactComponent as Invicgames } from 'src/assets/partners/invicgames.svg';
import { ReactComponent as Tuyak } from 'src/assets/partners/tuyak.svg';
import { ReactComponent as Veteranhub } from 'src/assets/partners/veteranhub.svg';
import { ReactComponent as Vilnyvibir } from 'src/assets/partners/vilnyvibir.svg';
import { ReactComponent as Bezobmezh } from 'src/assets/partners/bezobmezh.svg';
import { ReactComponent as Mateacademy } from 'src/assets/partners/mateacademy.svg';
import { ReactComponent as Happymonday } from 'src/assets/partners/happymonday.svg';
import { ReactComponent as Vesta } from 'src/assets/partners/vesta.svg';
import { ReactComponent as Rozmova } from 'src/assets/partners/rozmova.svg';
import { ReactComponent as Ukrvetfound } from 'src/assets/partners/ukrvetfound.svg';
import { ReactComponent as Protezhub } from 'src/assets/partners/protezhub.svg';
import { ReactComponent as Asocvetato } from 'src/assets/partners/asocvetato.svg';
import { ReactComponent as Lifelineukr } from 'src/assets/partners/lifelineukr.svg';
import { ReactComponent as Czvl } from 'src/assets/partners/czvl.svg';
import { ReactComponent as Forestglade } from 'src/assets/partners/forestglade.svg';
import { ReactComponent as Prostirmozh } from 'src/assets/partners/prostirmozh.svg';
import { ReactComponent as Newhorizhub } from 'src/assets/partners/newhorizhub.svg';
import { ReactComponent as Epam } from 'src/assets/partners/epam.svg';
import { ReactComponent as Mentoly } from 'src/assets/partners/mentoly.svg';
import { ReactComponent as Mindyfoundation } from 'src/assets/partners/mindyfoundation.svg';
import { ReactComponent as Pleso } from 'src/assets/partners/pleso.svg';
import { ReactComponent as Linesuppmens } from 'src/assets/partners/linesuppmens.svg';
import { ReactComponent as Superhumans } from 'src/assets/partners/superhumans.svg';
import { ReactComponent as AfterFront } from 'src/assets/partners/afterfront.svg';
import { ReactComponent as Lmr } from 'src/assets/partners/lmr.svg';
import { ReactComponent as WorkUa } from 'src/assets/partners/workua.svg';
import { ReactComponent as Dignitas } from 'src/assets/partners/dignitas.svg';
import { ReactComponent as Ai } from 'src/assets/partners/ai.svg';
import { ReactComponent as Uku } from 'src/assets/partners/uku.svg';
import { ReactComponent as Wp } from 'src/assets/partners/wp.svg';
import { ReactComponent as Turbota } from 'src/assets/partners/turbota.svg';
import { ReactComponent as Boryviter } from 'src/assets/partners/boryviter.svg';
import { ReactComponent as Lmb } from 'src/assets/partners/lmb.svg';
import { ReactComponent as Pfru } from 'src/assets/partners/pfru.svg';
import { ReactComponent as Kmh } from 'src/assets/partners/kmh.svg';
import { ReactComponent as Kruk } from 'src/assets/partners/kruk.svg';
import { ReactComponent as Azov } from 'src/assets/partners/azov.svg';
import { ReactComponent as Vum } from 'src/assets/partners/vum.svg';
import { ReactComponent as Helpcenter } from 'src/assets/partners/helpcenter.svg';
import { ReactComponent as Diiatime } from 'src/assets/partners/diiatime.svg';
import { ReactComponent as Beetroot } from 'src/assets/partners/beetroot.svg';
import { ReactComponent as AcademyKava } from 'src/assets/partners/academyKava.svg';
import { ReactComponent as KharkivItCluster } from 'src/assets/partners/kharkivItCluster.svg';
import { ReactComponent as MvsLogo } from 'src/assets/partners/mvs.svg';
import { ReactComponent as CkVymirLogo } from 'src/assets/partners/ckvymir.svg';
import { ReactComponent as OovaLogo } from 'src/assets/partners/oova.svg';
import { ReactComponent as OmrLogo } from 'src/assets/partners/omr.svg';
import { ReactComponent as HovaLogo } from 'src/assets/partners/hova.svg';
import { ReactComponent as UPlusLogo } from 'src/assets/partners/uPlus.svg';
import { ReactComponent as UkrSurgeonAsoc } from 'src/assets/partners/ukrSurgeonAsoc.svg';
import { ReactComponent as Ingenius } from 'src/assets/partners/ingenius.svg';
import { ReactComponent as Veteranka } from 'src/assets/partners/veteranka.svg';
import { ReactComponent as VetMedia } from 'src/assets/partners/vetMedia.svg';
import { ReactComponent as VetCyberCluster } from 'src/assets/partners/vetCyberCluster.svg';
import { ReactComponent as AsocInovEd } from 'src/assets/partners/asocInovEd.svg';
import { ReactComponent as HowAreYouBrother } from 'src/assets/partners/howAreYouBrother.svg';
import { ReactComponent as AsocPsych } from 'src/assets/partners/asocPsych.svg';
import { ReactComponent as Krisenchat } from 'src/assets/partners/krisenchat.svg';
import { ReactComponent as OpenDoors } from 'src/assets/partners/openDoors.svg';
import { ReactComponent as AiPsyHelp } from 'src/assets/partners/aiPsyHelp.svg';
import { ReactComponent as BlueBird } from 'src/assets/partners/blueBird.svg';
import { ReactComponent as VhOdesa } from 'src/assets/partners/vhOdesa.svg';
import { ReactComponent as Auk } from 'src/assets/partners/auk.svg';
import { ReactComponent as Mim } from 'src/assets/partners/mim.svg';
import { ReactComponent as Kpi } from 'src/assets/partners/kpi.svg';
import { ReactComponent as Pdau } from 'src/assets/partners/pdau.svg';
import { ReactComponent as Hospitaliery } from 'src/assets/partners/hospitaliery.svg';
import { ReactComponent as VictoryDrons } from 'src/assets/partners/victoryDrons.svg';
import { ReactComponent as Uaemt } from 'src/assets/partners/uaemt.svg';
import { ReactComponent as Irex } from 'src/assets/partners/irex.svg';
import { ReactComponent as Dostup } from 'src/assets/partners/dostup.svg';
import { ReactComponent as Its } from 'src/assets/partners/its.svg';
import { ReactComponent as LobbyX } from 'src/assets/partners/lobbyx.svg';
import { ReactComponent as Helsi } from 'src/assets/partners/helsi.svg';
import { ReactComponent as Choice31 } from 'src/assets/partners/choice31.svg';
import { ReactComponent as Silpo } from 'src/assets/partners/silpo.svg';
import { ReactComponent as Youcontrol } from 'src/assets/partners/youcontrol.svg';

export const PARTNERS = [
  { logo: <MinEcon />, url: 'https://www.me.gov.ua/' },
  { logo: <MinSoc />, url: 'https://www.msp.gov.ua/' },
  { logo: <MinVet />, url: 'https://mva.gov.ua/ua' },
  { logo: <Pryncyp />, url: 'https://navigator.pryncyp.com/' },
  { logo: <InTarShev />, url: 'https://knu.ua/' },
  { logo: <Vartozhyty />, url: 'https://vartozhyty.com.ua/' },
  { logo: <Prometheus />, url: 'https://prometheus.org.ua/' },
  { logo: <Uridichnasotna />, url: 'https://legal100.org.ua/' },
  { logo: <Baza />, url: 'https://www.vvybir.org.ua/мобільний-застосунок-база/' },
  { logo: <Kse />, url: 'https://kse.ua/ua/' },
  { logo: <Unbroken />, url: 'https://unbroken.org.ua/ua' },
  { logo: <Edra />, url: 'https://ed-era.com/' },
  { logo: <Invicgames />, url: 'https://invictusgames.in.ua/' },
  { logo: <Tuyak />, url: 'https://www.howareu.com/' },
  { logo: <Veteranhub />, url: 'https://veteranhub.com.ua/' },
  { logo: <Vilnyvibir />, url: 'https://www.vvybir.org.ua/' },
  { logo: <Bezobmezh />, url: 'https://www.bez-obmezhen.com.ua/' },
  { logo: <Mateacademy />, url: 'https://mate.academy/' },
  { logo: <Happymonday />, url: 'https://happymonday.ua/' },
  { logo: <AfterFront />, url: 'https://afterfront.com.ua/' },
  { logo: <Rozmova />, url: 'https://www.rozmova.me/' },
  { logo: <Lmr />, url: 'https://city-adm.lviv.ua/' },
  { logo: <Protezhub />, url: 'https://protezhub.com/' },
  { logo: <Asocvetato />, url: 'https://apva.com.ua/' },
  { logo: <Lifelineukr />, url: 'https://lifelineukraine.com/' },
  { logo: <Czvl />, url: 'https://www.czvl.com.ua/' },
  { logo: <Forestglade />, url: 'https://www.lisovapoliana.com.ua/' },
  { logo: <Prostirmozh />, url: 'https://pm.in.ua/' },
  { logo: <Newhorizhub />, url: 'https://www.horizonshub.org/ua' },
  { logo: <Epam />, url: 'https://www.epam.com/' },
  { logo: <Mentoly />, url: 'https://mentoly.com.ua/' },
  { logo: <Mindyfoundation />, url: 'https://www.instagram.com/mindy_foundation/' },
  { logo: <Pleso />, url: 'https://pleso.me/ua' },
  { logo: <Linesuppmens />, url: 'https://2345.in.ua/' },
  { logo: <Superhumans />, url: 'https://superhumans.com/' },
  { logo: <WorkUa />, url: 'https://www.work.ua/zsu/' },
  { logo: <Ukrvetfound />, url: 'https://veteranfund.com.ua/' },
  { logo: <Dignitas />, url: 'https://dignitas.fund/uk/' },
  { logo: <Ai />, url: 'https://aide.one/' },
  { logo: <Uku />, url: 'https://ucu.edu.ua/' },
  { logo: <Wp />, url: 'https://www.pidtrymka.in.ua/' },
  { logo: <Turbota />, url: 'https://turbota.mil.gov.ua' },
  { logo: <Boryviter />, url: 'https://boryviter.org.ua/' },
  { logo: <Lmb />, url: 'https://medcluster.lviv.ua/' },
  { logo: <Pfru />, url: 'https://pfru.org.ua/' },
  { logo: <Vesta />, url: 'https://www.instagram.com/vo_vesta/' },
  { logo: <Kmh />, url: 'https://www.facebook.com/kyivCDUATO/' },
  { logo: <Kruk />, url: 'https://kruk.in.ua/' },
  { logo: <Azov />, url: 'https://azovangels.com/' },
  { logo: <Vum />, url: 'https://vumonline.ua/' },
  { logo: <Helpcenter />, url: 'https://www.facebook.com/ooveterancentr/' },
  { logo: <Diiatime />, url: 'https://www.actiontime.in.ua/' },
  { logo: <Beetroot />, url: 'https://test.beetroot.academy/' },
  { logo: <AcademyKava />, url: 'https://akademiiakavy.wog.ua/' },
  { logo: <KharkivItCluster />, url: 'https://it-kharkiv.com/' }
];

export const INTERNATIONAL_FUNDS = 'partnershipTagCaption0',
  EDUCATIONAL_SERVICES = 'partnershipTagCaption1',
  PSYCHOLOGICAL_SERVICES = 'partnershipTagCaption2',
  SOCIAL_SERVICES = 'partnershipTagCaption3',
  SPORT = 'partnershipTagCaption4',
  UNIVERSITIES = 'partnershipTagCaption5',
  MEDICAL_SERVICES = 'partnershipTagCaption6',
  MEDIA_SUPPORT = 'partnershipTagCaption7',
  ENTREPRENEURSHIP = 'partnershipTagCaption8',
  PROSTHETICS_CENTERS = 'partnershipTagCaption9',
  LEGAL_ASSISTANCE = 'partnershipTagCaption10',
  LOCAL_GOVERNMENT = 'partnershipTagCaption11',
  CAREER_MATERIALS = 'partnershipTagCaption12',
  MINISTRY = 'partnershipTagCaption13';

export const CATEGORIZED_PARTNERS = [
  {
    category: 'partnershipSection0',
    logos: [
      { tag: MINISTRY, logo: <MinEcon /> },
      { tag: MINISTRY, logo: <MinSoc /> },
      { tag: MINISTRY, logo: <MinVet /> },
      { tag: MINISTRY, logo: <Turbota /> },
      { tag: MINISTRY, logo: <MvsLogo /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Forestglade /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Ukrvetfound /> }
    ]
  },
  {
    category: 'partnershipSection1',
    logos: [
      { tag: LOCAL_GOVERNMENT, logo: <CkVymirLogo /> },
      { tag: LOCAL_GOVERNMENT, logo: <Kmh /> },
      { tag: LOCAL_GOVERNMENT, logo: <OovaLogo /> },
      { tag: LOCAL_GOVERNMENT, logo: <OmrLogo /> },
      { tag: LOCAL_GOVERNMENT, logo: <Lmr /> },
      { tag: LOCAL_GOVERNMENT, logo: <HovaLogo /> }
    ]
  },
  {
    category: 'partnershipSection2',
    logos: [
      { tag: PROSTHETICS_CENTERS, logo: <Superhumans /> },
      { tag: PROSTHETICS_CENTERS, logo: <Mindyfoundation /> },
      { tag: PROSTHETICS_CENTERS, logo: <Protezhub /> },
      { tag: PROSTHETICS_CENTERS, logo: <Bezobmezh /> },
      { tag: PROSTHETICS_CENTERS, logo: <UPlusLogo /> },
      { tag: PROSTHETICS_CENTERS, logo: <Unbroken /> }
    ]
  },
  {
    category: 'partnershipSection3',
    logos: [
      { tag: MEDICAL_SERVICES, logo: <UkrSurgeonAsoc /> },
      { tag: MEDICAL_SERVICES, logo: <Lmb /> },
      { tag: MEDICAL_SERVICES, logo: <Ingenius /> },
      { tag: MEDIA_SUPPORT, logo: <Veteranka /> },
      { tag: MEDIA_SUPPORT, logo: <VetMedia /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Edra /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Newhorizhub /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Prometheus /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Vum /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Prostirmozh /> },
      { tag: EDUCATIONAL_SERVICES, logo: <VetCyberCluster /> },
      { tag: EDUCATIONAL_SERVICES, logo: <AsocInovEd /> },
      { tag: ENTREPRENEURSHIP, logo: <Asocvetato /> },
      { tag: ENTREPRENEURSHIP, logo: <Czvl /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Lifelineukr /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Vesta /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Veteranhub /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Vartozhyty /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Baza /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Linesuppmens /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <HowAreYouBrother /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <AsocPsych /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Krisenchat /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Wp /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <OpenDoors /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <AiPsyHelp /> },
      { tag: SOCIAL_SERVICES, logo: <AfterFront /> },
      { tag: SOCIAL_SERVICES, logo: <BlueBird /> },
      { tag: SOCIAL_SERVICES, logo: <VhOdesa /> },
      { tag: LEGAL_ASSISTANCE, logo: <Pryncyp /> },
      { tag: LEGAL_ASSISTANCE, logo: <Uridichnasotna /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Tuyak /> },
      { tag: EDUCATIONAL_SERVICES, logo: <KharkivItCluster /> }
    ]
  },
  {
    category: 'partnershipSection4',
    logos: [
      { tag: UNIVERSITIES, logo: <Kse /> },
      { tag: UNIVERSITIES, logo: <Uku /> },
      { tag: UNIVERSITIES, logo: <Auk /> },
      { tag: UNIVERSITIES, logo: <Mim /> },
      { tag: UNIVERSITIES, logo: <InTarShev /> },
      { tag: UNIVERSITIES, logo: <Kpi /> },
      { tag: UNIVERSITIES, logo: <Pdau /> }
    ]
  },
  {
    category: 'partnershipSection5',
    logos: [
      { tag: EDUCATIONAL_SERVICES, logo: <Boryviter /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Kruk /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Hospitaliery /> },
      { tag: EDUCATIONAL_SERVICES, logo: <VictoryDrons /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Uaemt /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Azov /> }
    ]
  },
  {
    category: 'partnershipSection6',
    logos: [
      { tag: INTERNATIONAL_FUNDS, logo: <Irex /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Dignitas /> },
      { tag: SOCIAL_SERVICES, logo: <Dostup /> },
      { tag: SPORT, logo: <Invicgames /> },
      { tag: INTERNATIONAL_FUNDS, logo: <Its /> }
    ]
  },
  {
    category: 'partnershipSection7',
    logos: [
      { tag: CAREER_MATERIALS, logo: <LobbyX /> },
      { tag: CAREER_MATERIALS, logo: <WorkUa /> },
      { tag: CAREER_MATERIALS, logo: <Happymonday /> },
      { tag: MEDICAL_SERVICES, logo: <Helsi /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Epam /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Mateacademy /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Choice31 /> },
      { tag: ENTREPRENEURSHIP, logo: <Silpo /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Mentoly /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Pleso /> },
      { tag: PSYCHOLOGICAL_SERVICES, logo: <Rozmova /> },
      { tag: MEDIA_SUPPORT, logo: <Youcontrol /> },
      { tag: EDUCATIONAL_SERVICES, logo: <Beetroot /> }
    ]
  }
];

export const TAGS = [
  INTERNATIONAL_FUNDS,
  EDUCATIONAL_SERVICES,
  PSYCHOLOGICAL_SERVICES,
  SOCIAL_SERVICES,
  SPORT,
  UNIVERSITIES,
  MEDICAL_SERVICES,
  MEDIA_SUPPORT,
  ENTREPRENEURSHIP,
  PROSTHETICS_CENTERS,
  LEGAL_ASSISTANCE,
  LOCAL_GOVERNMENT,
  CAREER_MATERIALS,
  MINISTRY
];
