export const messages = {
  en: {
    footerTitle: 'Help defenders get access to effective solutions in a single place',
    footerContactsTitle: 'Do you have any questions?',
    footerContactsEmail: "Platform's e-mail:",
    footerContactsFeedback: 'Leave feedback',
    footerCustomer: 'Created on the initiative of the Ministry of Defence of Ukraine'
  },
  default: {
    footerTitle: 'Допомагаємо захисникам отримати доступ до працюючих рішень в єдиному місці',
    footerContactsTitle: 'Маєте запитання?',
    footerContactsEmail: 'Email платформи:',
    footerContactsFeedback: 'Залишити відгук',
    footerCustomer: 'Створено за ініціативи Міністерства оборони України'
  }
};
