import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { PSYCHOLOGICAL_HELP } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

function BorotbaZiSmutkomAboHoremPisliaVtraty() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(PSYCHOLOGICAL_HELP.path)}>
      <h1>Боротьба зі смутком або горем після втрати</h1>
      <p>
        Завжди важко втрачати близьких людей. Навіть коли ви впевнені, що готові до втрати, відчуття смутку, гніву,
        тривоги або горя все одно буде проживатися психікою. Однак інтенсивність реакції на втрату може коливатися в
        залежності від значення, яке відігравала людина (або діяльність, річ, вірування тощо) в вашому житті.
      </p>
      <p>
        Поглиблення знань про типи втрат, поширені реакції на втрати та способи самопідтримки під час переживання втрати
        є важливою складовою для турботи про власний психоемоційний стан у важкі часи. Нижче ділимося корисною
        теоретичною та практичною інформацією, яка допоможе підтримати себе під час втрати.
      </p>

      <p>
        <i>
          <b>Важливо розуміти:</b>
        </i>
      </p>
      <p>
        <i>
          <b>
            Люди горюють з різних причин. Горе не завжди означає втрату людини. Горювати також можна через втрату
            минулого образу життя, втрату домівки тощо.
          </b>
        </i>
      </p>

      <p>
        <b>Деякі приклади цього враховують втрату:</b>
      </p>
      <ul>
        <li>Коханої людини, родича, друзів;</li>
        <li>Тварини;</li>
        <li>Улюбленого громадського діяча, лідера або наставника;</li>
        <li>Життєвої ролі: наприклад, різка зміна діяльності – перехід з армії до цивільної роботи або навпаки;</li>
        <li>Фізичної здатності: втрата кінцівки, втрата здатності ходити/бачити/чути тощо;</li>
        <li>Відносин: розлучення, втрата коханого;</li>
        <li>Дому, сусідства або друзів під час переїзду.</li>
      </ul>

      <p>
        <b>Для ветеранів горе може виникнути через:</b>
      </p>
      <ul>
        <li>
          Втрату військового товариша, який загинув у бою. Така втрата може супроводжуватися відчуттям провини та
          докорами сумління через здатність продовжувати жити, коли товариші загинули;
        </li>
        <li>Втрату відчуття близькості з товаришами по службі;</li>
        <li>Відсутність ідентичності, як члена збройних сил;</li>
        <li>
          Сильні фізичні травми: інвалідність, отримана під час служби, черепно-мозкова травма, суттєві травми кінцівок,
          обмеженість в здатності самостійно доглядати за собою;
        </li>
        <li>Порушення в психічному стані: ПТСР, втрата почуття безпеки, безсоння</li>
      </ul>
      <p>
        <b>Як проживається скорбота – зовні та всередині</b>
      </p>
      <p>
        Горе – сильне та складне почуття, для проживання якого немає правил. Кожна людина реагує на втрату по-різному:
        хтось проживає горе більш явно, що його скорботу можуть відчути та побачити люди навколо, а хтось проживає горе
        всередині, не виказуючи жодних зовнішніх проявів скорботи. Кожен спосіб проживання горя є нормальним і не має
        засуджуватися оточенням.
      </p>

      <p>
        <b>Які є типові реакції на горе? </b>
      </p>

      <ul>
        <li>
          <p>
            <b>Фізичні: </b>
            біль у животі, головний біль, біль навколо серця, безсоння, втома, запаморочення, тремтіння, панічні атаки.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            <b>Емоційні: </b>
            шок, недовіра, заціпеніння, тривога, заплутане мислення, розчарування, депресія, відчуття провини,
            самотність, гнів, відчуженість.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <p>
            <b>Поведінкові: </b>
            плач, крокування, витріщання в одну точку, необачливість, втрата інтересу, погана концентрація, занадто
            багато або занадто мало сну, ізоляція, одержимість втратою, занепокоєння про власне здоров’я.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <p>
            <b>Духовні: </b>
            гнів на Бога або вищу силу, втрата віри, пошук віри, задумливість або філософія та постійна саморефлексія.
          </p>
        </li>
      </ul>

      <p>
        <i>
          <b>Горе — це процес</b>
        </i>
      </p>
      <p>
        <i>
          Горе є процесом, під час якого ви усвідомлюєте втрату, приймаєте її та розумієте, що можете змиритися з нею.
          Але це може бути тривалий та болючий процес.
        </i>
      </p>
      <p>
        <i>
          Переживаючи горе, почуття можуть накривати, а можуть навпаки залишати наодинці зі своїми думками. Ви можете
          усвідомити, що заперечуєте втрату або відчути сильний смуток. Ви можете помічати, що відчуваєте злість на
          причину своєї втрати, злість на себе чи на когось іншого.
        </i>
      </p>
      <p>
        <i>
          Також ви можете відчувати полегшення, мати моменти щастя та радості. Вам може бути важко прийняти втрату і
          ваші думки будуть формуватися навколо гадання «Якби я…». Ви можете відчувати провину через те, що
          посміхаєтеся.
        </i>
      </p>
      <p>
        <i>
          Варто зрозуміти, що немає правильного способу сумувати. З часом більшість людей виявляють, що здатні змиритися
          зі своєю втратою.
        </i>
      </p>

      <p>
        <b>Самодопомога під час скорботи</b>
      </p>

      <p>
        Переживаючи втрату, важливо вміти підтримати себе – допомогти собі подолати процес горя, залишаючись на зв’язку
        з потребами власного тіла та психіки. Ось декілька порад:
      </p>

      <ul>
        <li>
          <p>
            <b>Дозвольте собі сумувати</b>
          </p>
        </li>
      </ul>
      <p>
        Виділіть собі час, щоб відчути та прожити всі почуття, пов’язані з втратою, які вас наповнюють. Дайте собі
        визволити емоції. Робіть це будь-яким комфортним для вас шляхом: пишіть, малюйте, розмовляйте, плачте, кричіть.
      </p>
      <p>
        Вивільнення усіх емоцій, спричинених втратою, є основним етапом зцілення. Ваш емоційний біль може бути дуже
        важким та неприємним, тому надважливо замість ігнорувати, випустити його з себе. Постарайтеся спостерігати за
        тим, як ваші емоції спочатку накривають вас, а потім відходять і стає легше. Це і є зцілення, яке може тривати
        стільки часу, скільки вам потрібно.
      </p>
      <p>Для вивільнення емоцій та прожиття горя спробуйте робити вправи, які підтримають ваш стан.</p>

      <p>
        <i>
          <b>Вправа «Намалюйте горе»</b>
        </i>
      </p>

      <p>
        Спробуйте намалювати своє горе – малюйте те, що відчуваєте сьогодні. Ви можете дати собі декілька хвилин
        подумати, що б ви хотіли намалювати: це може бути автопортрет; місце, де ви зараз знаходитеся; певні речі з
        вашого оточення; абстрактні елементи та лінії, які символізують вашу втрату та ваші відчуття та думки через неї.
      </p>
      <p>Поставте таймер на 30 хвилин і малюйте. Якщо ви закінчите малюнок раніше – добре.</p>
      <p>Маючи перед собою готовий малюнок, приділіть декілька хвилин відповідям на питання:</p>
      <ul>
        <li>Як вам було виконувати цю вправу?</li>
        <li>З яких елементів складається ваш малюнок? Що ці різні елементи означають для вас?</li>
        <li>Які почуття та емоції виникли у вас під час малювання?</li>
        <li>Як ви зараз почуваєтесь?</li>
        <li>Як ви думаєте, що ваш малюнок говорить про вас і про те, де ви перебуваєте під час свого горя?</li>
      </ul>
      <p>
        <i>
          Ця вправа допоможе налаштувати зв’язок із тим собою, який опинився в тіні через біль втрати. Малюючи свої
          внутрішні відчуття, ви зможете усвідомити на якому етапі проживання горя знаходитеся та розпочати новий етап
          зцілення та повернення до себе.
        </i>
      </p>

      <ul>
        <li>
          <p>
            <b>Розкажіть про свій досвід</b>
          </p>
        </li>
      </ul>

      <p>
        Дуже важливо випускати та проживати свої емоції та відчуття, особливо це стосується почуття горя, страху,
        агресії, злості – складних емоцій, які часто замовчуються в суспільстві. Психологи радять ділитися своїми
        відчуттями про втрату, розмовляти про неї, згадувати спільні приємні моменти, ділитись своїм горем з іншими.
        Такі розмови допоможуть зіштовхнутись з реальністю та швидше прийняти та прожити втрату.
      </p>
      <p>
        Часто навіть найближче оточення може не знати про емоційний біль, який ви проживаєте. Натомість проходження
        через скорботу на самоті може бути тривалим процесом, який тільки відтягне зцілення. Поговоріть з людьми, яким
        ви довіряєте. Поясніть, якої підтримки потребуєте.
      </p>
      <p>
        Для розмови обирайте людину або людей, які вислухають вас без засудження та порад. Це може бути хтось з вашої
        родини, близький друг, духовний наставник, бойовий товариш, психотерапевт, група підтримки – будь-хто, з ким ви
        відчуваєте себе у безпеці.
      </p>
      <ul>
        <li>
          <p>
            <b>Записуйте свої думки в щоденник</b>
          </p>
        </li>
      </ul>

      <p>
        Якщо ви схильні до рефлексії й вам притаманно занотовувати якісь думки та ідеї, допоміжним засобом у проживанні
        горя може стати ведення щоденника. Однак, якщо після запису своїх думок та відчуттів вам стає гірше, спробуйте
        інший спосіб висловлення почуттів у щоденнику – напишіть лист покійному.
      </p>

      <p>
        <i>
          <b>Вправа «Лист»</b>
        </i>
      </p>

      <p>
        Неперероблена травма може зашкодити та перешкодити процес переживання горя. Психологи радять написати листа
        померлому, щоб визнати свою втрату. Також ця вправа може допомогти закінчити незавершені справи та висловити те,
        що залишилося непромовленим між вами та покійним.
      </p>

      <p>
        Виконуючи вправу, проявіть співчуття до себе, будьте чесними та відкритими. Пишіть те, що ви відчуваєте, без
        жодних таємниць та хвилювань, що хтось може вас засудити. Ви можете написати про те, як сумуєте та сумуватимете
        за цією людиною, про значення цих стосунків для вас, поділитися гнівом на те, що ви залишилися самі, що
        відчуваєте себе покинуто. Вираження всіх емоцій, які ви відчуваєте через втрату, може призвести до зменшення
        складних почуттів та відчуттю полегшення.
      </p>

      <p>
        <i>
          <b>Вправа «Якби я міг поговорити з тобою востаннє…»</b>
        </i>
      </p>

      <p>
        Ця письмова вправа також дуже схожа на лист, проте трохи відрізняється задачею – вона спонукає людей думати та
        згадувати найкраще про людину, яка пішла з життя. Хоча це може бути болісно, важливо подумати про речі, які
        покійна людина любила найбільше, щоб зберегти позитивні спогади.
      </p>

      <p>
        <i>
          Згадуючи радісні та приємні моменти, які пов’язані з людиною, втрату якої ви переживаєте, ви зможете відчути
          вдячність та радість від того, що ви були знайомі, що ця людина була у вашому житті. Також вправа допоможе
          усвідомити свій стан зараз й наблизить до зцілення.
        </i>
      </p>

      <p>
        <i>
          <b>Вправа «Як я можу змінити своє майбутнє»</b>
        </i>
      </p>

      <p>
        Зосередженість на майбутньому є важливою для людей, щоб рухатися вперед і змінювати своє життя. Ця письмова
        вправа створена для запису та планування майбутнього з урахуванням при цьому точки зору людини, яка нещодавно
        померла.
      </p>

      <p>
        <i>
          Таким чином, людина, втрату якої проживають, присутня у майбутніх планах. Натомість людина, яка тужить,
          отримує здатність планувати життя далі та рухатися вперед.
        </i>
      </p>

      <ul>
        <li>
          <p>
            <b>Спробуйте групову терапію</b>
          </p>
        </li>
      </ul>

      <p>
        Розуміння, що ви не самотні у боротьбі з горем, і є інші люди, які відчувають або відчували те саме, може значно
        змінити ваш шлях до зцілення. Відвідування групи підтримки або групової терапії може бути дуже корисним та
        якісно вплинути на проживання скорботи.
      </p>

      <p>
        Також групова підтримка є одним з важливих факторів для адаптації та переживання втрат та бойового досвіду для
        ветеранів, які після повернення до цивільного життя часто проходять через сильне відчуття провини. Ветерани
        можуть відчувати жах від своєї поведінки в зонах бойових дій, навіть якщо вони виконували накази, це вважалося
        героїзмом і характерною рисою хорошого солдата.
      </p>

      <p>
        Почуття провини можливо пропрацювати за допомогою зізнання, створивши безпечне середовище, в якому ветеран може
        поділитися будь-якими скоєними діями, які можуть бути причиною почуття провини, сорому або горя.
      </p>

      <ul>
        <li>
          <p>
            <b>Догляд за собою</b>
          </p>
        </li>
      </ul>
      <p>
        Відношення до себе під час проживання горя є одним з основних показників того, наскільки травмувала вас втрата
        близької людини. Переживаючи складні почуття надважливо продовжувати піклуватися про себе, наскільки б
        безсенсовним це не здавалося та наскільки б важко не було себе змусити.
      </p>
      <p>Важливо:</p>
      <ul>
        <li>Дотримуватися розпорядку дня;</li>
        <li>Регулярно займатися спортом, навіть якщо це швидка 15-хвилинна руханка зранку;</li>
        <li>Добре харчуватися;</li>
        <li>Обмежити вживання алкоголю;</li>
        <li>Практикувати медитації;</li>
        <li>Намагатися підтримувати хобі та заняття, які приносять вам радість.</li>
      </ul>
      <p>
        <i>
          За потреби зверніться за професійною допомогою. Якщо ви виявите, що протягом певного періоду часу ваше горе
          продовжує заважати вашій здатності рухатися вперед у своєму житті, зверніться за допомогою.
        </i>
      </p>
    </Article>
  );
}

export default BorotbaZiSmutkomAboHoremPisliaVtraty;
