import { isValid } from 'date-fns';

const maxEmailStringLength = 255;
const maxUrlLength = 2000;

export const validateName = (yup, msg, alias, maxLength, isRequired = true) => {
  let result = yup.string().trim();

  if (isRequired) {
    result = result.required(msg('formValidationRequired'));
  }

  return result.max(
    maxLength,
    `${msg('formValidationPrefixMsg1')} ${alias} ${msg('formValidationLengthMsg1')} ${maxLength} ${msg(
      'formValidationLengthMsg2'
    )}.`
  );
};

export const validateDescription = (yup, msg, alias, maxLength, isRequired = true) => {
  let result = yup.string().trim();

  if (isRequired) {
    result = result.required(msg('formValidationRequired'));
  }

  return result.max(
    maxLength,
    `${msg('formValidationPrefixMsg1')} ${alias} ${msg('formValidationLengthMsg1')} ${maxLength} ${msg(
      'formValidationLengthMsg2'
    )}.`
  );
};

export const validatePhone = (yup, msg, isRequired = true) => {
  let result = yup.string().trim();

  if (isRequired) {
    result = result.required(msg('formValidationRequired'));
  }

  return result.test('is-valid-phone', `${msg('formValidationPhone')} 380ххххххххх.`, (value) => {
    const pattern = RegExp(/^380\d{2}\d{7}$/);

    return !value?.trim() || pattern.test(value);
  });
};

export const validateEmail = (yup, msg) =>
  yup
    .string()
    .required(msg('formValidationRequired'))
    .email(msg('formValidationEmailMsg2'))
    .max(
      maxEmailStringLength,
      `${msg('formValidationPrefixMsg1')} ${msg('formValidationEmailMsg1')} ${msg(
        'formValidationLengthMsg1'
      )} ${maxEmailStringLength} ${msg('formValidationLengthMsg2')}.`
    );

export const validateDateTime = (yup, msg) =>
  yup
    .string()
    .required(msg('formValidationRequired'))
    .test('is-valid-datetime', msg('formValidationDateTime'), (value) => isValid(new Date(value)));

export const validateUrl = (yup, msg, isRequired = true) => {
  let result = yup.string().trim();

  if (isRequired) {
    result = result.required(msg('formValidationRequired'));
  }

  return result
    .test('is-valid-url', msg('formValidationUrlMsg2'), isValidUrl)
    .max(
      maxUrlLength,
      `${msg('formValidationPrefixMsg1')} ${msg('formValidationUrlMsg1')} ${msg(
        'formValidationLengthMsg1'
      )} ${maxUrlLength} ${msg('formValidationLengthMsg2')}.`
    );
};

export function isValidUrl(url) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+:]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );

  return !url?.trim() || pattern.test(url);
}
