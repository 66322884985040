import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function BezoplatnyiProizdTransportom() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>Безоплатний проїзд транспортом</h3>
      <p>
        Передбачається безоплатний проїзд міським пасажирським транспортом, автомобільним транспортом загального
        користування в сільській місцевості, а також залізничним і водним транспортом приміського сполучення та
        автобусами приміських і міжміських маршрутів.
      </p>
      <p>
        Учасникам бойових дій безоплатні та зі знижкою проїзні квитки видає низка державних установ (Мін’юст, органи
        Міноборони, МВС, Мінветеранів, Національної поліції, Національної гвардії, ДСНС, СБУ, Служби зовнішньої
        розвідки, Управління державної охорони, ДФС, Адміністрації Державної прикордонної служби, Держспецзв&apos;язку,
        а також Держспецтрансслужба за місцем реєстрації ветерана). Особам з інвалідністю внаслідок війни – служби
        соціального захисту населення. Інші пільговики користуються знижкою за своїм посвідченням. Електронний квиток
        видають органи місцевого самоврядування (в разі автоматизації системи проїзду).
      </p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>
        Учасник бойових дій, особа з інвалідністю внаслідок війни, постраждалий учасник Революції Гідності, ветерани
        силових структур, особа з інвалідністю, інвалідність якої настала внаслідок захворювання, одержаного в період
        проходження військової служби, батьки загиблого військовослужбовця.{' '}
      </p>
    </Article>
  );
}
