import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';

const complaintApiUrl = '/Complaint';

const PostComplaintRequest = (data) => obriyApiAxios.post(complaintApiUrl, data);

const API = {
  complaint: PostComplaintRequest
};

export default API;
