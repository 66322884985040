export const messages = {
  en: {
    globalSearchResultsMsg: 'Materials found for your request',
    globalSearchFilterTagTitle: 'Filter by type of services and information',
    globalSearchEmptyPlaceholderTitle1: 'No results were found for your request',
    globalSearchEmptyPlaceholderTitle2: 'Short search query',
    globalSearchPaginationMsg: 'See all values'
  },
  default: {
    globalSearchResultsMsg: 'За вашим запитом знайдено матеріалів',
    globalSearchFilterTagTitle: 'Фільтруй за типом послуг та матеріалів',
    globalSearchEmptyPlaceholderTitle1: 'За Вашим запитом нічого не знайдено',
    globalSearchEmptyPlaceholderTitle2: 'Закороткий пошуковий запит',
    globalSearchPaginationMsg: 'Дивитися всі'
  }
};
