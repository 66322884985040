import React, { useContext } from 'react';
import { ReactComponent as Accessibility } from 'src/assets/accessibility.svg';
import { ReactComponent as Efficiency } from 'src/assets/efficiency.svg';
import { ReactComponent as Respect } from 'src/assets/respect.svg';
import css from './project-values.module.scss';
import ProjectValue from './project-value';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function ProjectValues() {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.projectValues}>
      <div className={css.container}>
        <h2>{msg('projectValuesTitle')}</h2>
        <div className={css.values}>
          <ProjectValue icon={<Accessibility />} title={msg('projectValue1Title')}>
            <p>{msg('projectValue1Msg')}</p>
          </ProjectValue>
          <ProjectValue icon={<Efficiency />} title={msg('projectValue2Title')}>
            <p>{msg('projectValue2Msg')}</p>
          </ProjectValue>
          <ProjectValue icon={<Respect />} title={msg('projectValue3Title')}>
            <p>{msg('projectValue3Msg')}</p>
          </ProjectValue>
        </div>
      </div>
    </div>
  );
}
