import React, { useContext, useEffect, useId, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { LanguageSettingsContext } from 'src/utils/language-context';
import Input from 'src/components/form/floating-label/input';
import Modal from 'src/components/common/modal/modal';
import Spinner from 'src/components/spinner/spinner';
import { ReactComponent as Lock } from 'src/assets/lock.svg';
import { env } from 'src/constants/env';
import API from './api/feedback-api';
import getValidationSchema from './validate';
import css from './feedback.module.scss';

Feedback.propTypes = {
  trigger: PropTypes.element
};

export default function Feedback({ trigger }) {
  const { msg } = useContext(LanguageSettingsContext);
  const validationSchema = getValidationSchema(msg);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      header: '',
      description: ''
    }
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    formState: { errors, isSubmitSuccessful, defaultValues }
  } = methods;
  const values = getValues();
  const isEdited = Object.keys(values).some((key) => values[key] !== defaultValues[key]);

  const registerAndClearOnChange = function (inputName) {
    return register(inputName, {
      onChange: () => {
        if (errors[inputName]) {
          clearErrors(inputName);
        }
      }
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      captchaRef.current?.reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onFormSubmit = async (data, close) => {
    setIsLoading(true);
    try {
      const response = await API.feedback(data);

      if (response.status === 201) {
        toast.success(msg('feedbackSuccess'));
        close();
      }
    } catch (error) {
      toast.error(msg('submitFail'));
    }
    setIsLoading(false);
  };

  const nameInputId = useId();
  const emailInputId = useId();
  const headerInputId = useId();
  const descriptionInputId = useId();

  return (
    <Modal trigger={trigger} onClose={reset} nested closeOnDocumentClick={!isEdited}>
      {(close) => (
        <div className={css.feedback}>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(async (data) => {
                const token = await captchaRef.current?.executeAsync();
                onFormSubmit({ ...data, captcha: token }, close);
              })}
              className={css.form}
              autoComplete='off'>
              <legend>
                <h1>{msg('feedbackPageTitle')}</h1>
                <p>{msg('feedbackPageSubtitle')}</p>
              </legend>
              <fieldset>
                <Input
                  id={nameInputId}
                  label={msg('formName')}
                  error={errors?.name?.message}
                  {...registerAndClearOnChange('name')}
                />
                <Input
                  id={emailInputId}
                  label={msg('formEmailPlaceholder')}
                  placeholder='example@email.com'
                  error={errors?.email?.message}
                  required
                  {...registerAndClearOnChange('email')}
                />
                <Input
                  id={headerInputId}
                  label={msg('feedbackFormHeaderPlaceholder')}
                  error={errors?.header?.message}
                  {...registerAndClearOnChange('header')}
                />
                <Input
                  id={descriptionInputId}
                  label={msg('feedbackFormDescriptionPlaceholder')}
                  multiline
                  error={errors?.description?.message}
                  required
                  {...registerAndClearOnChange('description')}
                />
              </fieldset>
              <div className={css.confidentiality}>
                <div className={css.column}>
                  <Lock />
                </div>
                <div className={css.column}>
                  <div className={css.textBlock}>{msg('formPrivacyWarningMsg1')}</div>
                  <div className={css.textBlock}>{msg('formPrivacyWarningMsg2')}</div>
                </div>
              </div>

              <button className={css.button} type='submit' disabled={!!isLoading}>
                {isLoading ? <Spinner /> : msg('formSubmitCaption')}
              </button>
            </form>
            <ReCAPTCHA
              ref={captchaRef}
              className={css.captcha}
              sitekey={env.REACT_APP_OBRII_RECAPTCHA_SITE_KEY}
              hl='uk'
              size='invisible'
            />
          </FormProvider>
          <button className={css.close} onClick={close} tabIndex={7}>
            &times;
          </button>
        </div>
      )}
    </Modal>
  );
}
