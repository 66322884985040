import React, { useContext } from 'react';
import css from 'src/components/empty-placeholder/empty-placeholder.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function EmptyPlaceholder() {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.placeholder}>
      <span className={css.title}>{msg('workSearchEmptyPlaceholderTitle')}</span>
      <span className={css.recommendation}>{msg('emptyPlaceholderSubtitle')}</span>
      <ul>
        <li>{msg('emptyPlaceholderMsg1')}</li>
        <li>{msg('emptyPlaceholderMsg2')}</li>
        <li>{msg('workSearchEmptyPlaceholderMsg')}</li>
      </ul>
    </div>
  );
}
