import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './navbar.module.scss';
import classNames from 'classnames';

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      logo: PropTypes.node,
      logoActive: PropTypes.node,
      reloadDocument: PropTypes.bool
    })
  ).isRequired,
  inversedVertically: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
};

export default function Dropdown({ items, inversedVertically, fullScreen, isOpen = false, onClick }) {
  const location = useLocation();

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        css.dropdown,
        isOpen ? css.visible : css.hidden,
        fullScreen ? css.fullscreen : '',
        inversedVertically ? css.inversedVertically : ''
      )}>
      <ul>
        {items.map((item, i) => (
          <li key={i} onClick={onClick}>
            <NavLink to={item.path} reloadDocument={item.reloadDocument}>
              {item.logo && (location.pathname === item.path && item.logoActive ? item.logoActive : item.logo)}
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
