import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import css from './filters-group.module.scss';
import classNames from 'classnames';

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  maxLength: PropTypes.number
};

export default function InputNumber({ name, title, prefix, postfix, maxLength = 9 }) {
  const { register, setValue, watch, getValues } = useFormContext();
  const watchetValue = watch(name);
  const previousValueRef = useRef(getValues()[name]);

  useEffect(() => {
    if (previousValueRef.current === watchetValue) return;

    if (watchetValue && !/^[1-9]\d*$/.test(watchetValue)) {
      setValue(name, previousValueRef.current);
      return;
    }

    previousValueRef.current = watchetValue;
  }, [setValue, name, watchetValue]);

  return (
    <div className={classNames(css.filtersGroup, css.inputGap)}>
      <span>{title}</span>
      <div className={css.filter}>
        <label>
          {prefix}
          <input type='text' inputMode='numeric' id={name} name={name} maxLength={maxLength} {...register(name)} />
          {postfix}
        </label>
      </div>
    </div>
  );
}
