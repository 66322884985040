import React from 'react';
import PropTypes from 'prop-types';
import css from './filter-tags.module.scss';
import classNames from 'classnames';
import * as p from 'src/constants/page-classnames';

FilterTags.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedTag: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.oneOf([p.CAREER_INFO, p.PSYCHOLOGICAL_HELP, p.MEDICAL_SERVICES, p.LEGAL_HELP, p.USEFUL_INFO]),
  calcName: PropTypes.func
};

export default function FilterTags({ title, tags, selectedTag, onClick, className, calcName }) {
  return (
    <div className={classNames(css.filterTags, css[className])}>
      <p className={css.title}>{title}</p>
      <div className={css.tags}>
        {tags.map((tag, i) => (
          <button
            key={i}
            onClick={() => onClick(tag)}
            className={classNames(css.tag, tag === selectedTag ? css.active : '')}>
            {calcName ? calcName(tag) : tag}
          </button>
        ))}
      </div>
    </div>
  );
}
