import React, { useContext, useId } from 'react';
import PropTypes from 'prop-types';
import Filters from 'src/components/form/filters/filters';
import LocationsInput from 'src/components/form/search-select-input/locations-input';
import SearchInput from 'src/components/form/search/filters-form/search-input';
import { VETERAN_LOCATIONS } from 'src/constants/routes';
import { ONLINE } from 'src/constants/locations';
import { searchInputValidationSchema } from 'src/components/form/search/filters-form/helper';
import css from './services-page.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';

ServicesFilters.propTypes = {
  pageName: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.shape({
    text: PropTypes.string,
    locationId: PropTypes.string
  }).isRequired
};

export default function ServicesFilters({ pageName, onSearch, initialFilters }) {
  const { msg } = useContext(LanguageSettingsContext);

  const locationId = useId();
  const inputId = useId();

  return (
    <Filters
      initialFilters={initialFilters}
      onSearch={onSearch}
      validationSchema={searchInputValidationSchema(msg, 'text')}
      fieldsToSubmitOnChange={['text', 'locationId']}
      render={({ errors, _, registerAndClearOnChange }) => (
        <div className={css.filtersGroup}>
          <SearchInput
            id={inputId}
            name='text'
            placeholder={msg('seachServicePlaceholder')}
            error={errors?.text?.message}
            inputProps={registerAndClearOnChange('text')}
          />
          {msg(VETERAN_LOCATIONS.title) === pageName && (
            <LocationsInput
              id={locationId}
              name='locationId'
              defaultLocation={ONLINE}
              placeholder={msg('locationInputPlaceholder')}
            />
          )}
        </div>
      )}
    />
  );
}
