import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './toggle.module.scss';
import { useLocation } from 'react-router-dom';
import * as p from 'src/constants/page-classnames';

ToggleRoute.propTypes = {
  className: PropTypes.oneOf([
    p.WORK_SEARCH,
    p.CAREER_INFO,
    p.EDUCATION,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.BUSINESS_MAP,
    p.VETERAN_LOCATIONS,
    p.EVENTS
  ]),
  onToggle: PropTypes.func.isRequired,
  labelLeft: PropTypes.string.isRequired,
  pathLeft: PropTypes.string.isRequired,
  labelRight: PropTypes.string.isRequired,
  pathRight: PropTypes.string.isRequired
};

export default function ToggleRoute({ onToggle, labelLeft, pathLeft, labelRight, pathRight, className }) {
  const location = useLocation().pathname;
  const [value, setValue] = useState(location === pathLeft);

  function handleToggle(path) {
    if (value && path === pathLeft) return;

    setValue(path === pathLeft);
    onToggle(path);
  }

  return (
    <div className={classNames(css.toggle, css[className])}>
      <button onClick={() => handleToggle(pathLeft)} className={classNames(css.left, value ? css.active : '')}>
        <p>{labelLeft}</p>
      </button>
      <button onClick={() => handleToggle(pathRight)} className={classNames(css.right, !value ? css.active : '')}>
        <p>{labelRight}</p>
      </button>
    </div>
  );
}
