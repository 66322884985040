import React from 'react';
import PropTypes from 'prop-types';
import { fireEvent } from 'src/utils/analytics/google';
import { openInNewTab } from 'src/utils/helper';
import Card from 'src/components/form/search/cards/card';
import CardHeader from 'src/components/form/search/cards/card-header';
import CardBody from 'src/components/form/search/cards/card-body';
import CardFooter from 'src/components/form/search/cards/card-footer';
import Complaint from 'src/pages/complaint/complaint';
import Description from 'src/components/description-block/description';
import css from './course.module.scss';

Course.propTypes = {
  course: PropTypes.object.isRequired
};

export default function Course({ course }) {
  function handleCourseClick() {
    fireEvent('view_course', {
      title: course.title,
      url: course.url
    });
    openInNewTab(course.url);
  }

  return (
    <Complaint
      key={course.id}
      targetTitle={course.title}
      wrapper={(complaint, onMouseEnter, onMouseLeave) => (
        <Card
          key={course.id}
          className={css.course}
          onClick={handleCourseClick}
          hoverBorder='bottom'
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          <CardHeader className={css.imageContainer}>
            {complaint}
            <img src={course.imgUrl} alt={course.title} />
          </CardHeader>
          <CardBody className={css.textContainer} overflowEllipsis>
            <div className={css.title}>{course.title}</div>
            <Description description={course.description} />
          </CardBody>
          <CardFooter className={css.logoFooter}>
            <img src={course.logoUrl} alt={course.vendor} />
            <span>{course.vendor}</span>
          </CardFooter>
        </Card>
      )}
    />
  );
}
