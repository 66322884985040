export const messages = {
  en: {
    titleBanner1: 'Information platform for defenders',
    subtitleBanner1:
      'Information and free services for military personnel, veterans, and their families in a single place',
    linkBanner1: 'To services',
    roadmapTitle: 'Start with three simple steps',
    roadmapMsg1: 'Explore the platform',
    roadmapMsg2: 'Select a service',
    roadmapMsg3: 'Use opportunities',
    projectValuesTitle: 'Our values',
    projectValue1Title: 'Availability',
    projectValue1Msg:
      'No complications, interaction with any elements is clear at each stage. We focus on solving specified problems via simple solutions.',
    projectValue2Title: 'Efficiency',
    projectValue2Msg:
      'The purpose of an information platform is to become a benchmark of effective state digital solutions provided for defenders',
    projectValue3Title: 'Respect',
    projectValue3Msg:
      'Respect is the foundation of balanced relationships in a society promoting development and reconstruction of independent Ukraine',
    titleBanner2: 'The platform created to support defenders',
    subtitleBanner2:
      'Obrii is the information platform developed by the Ministry of Defence of Ukraine to let defenders and their families get access to education, new profession, successful rehabilitation, legal support, as well as find unique opportunities in a single place',
    servicesCard3Link1: 'Materials to improve mental health',
    servicesCard4Link1: 'Center for prosthetics and social support',
    servicesCard5Link1: 'Useful legal materials and services',
    servicesCard6Link1: 'Family, prosthetics, business, etc.',
    titleBanner3: 'The platform created to support defenders',
    subtitleBanner3:
      'The Ministry of Defence of Ukraine has formed the team of military personnel with expertise in the field of information technologies who are now using their skills to develop the platform Obrii for comrades and their families',
    linkBanner3: 'Leave feedback',
    customersTitle1: 'Created',
    customersTitle2: 'on the initiative of',
    titleBanner4: 'Unite a state, public organizations, and business to help defenders',
    subtitleBanner4msg1: 'Interact with unique audience',
    subtitleBanner4msg2: 'Build a socially responsible brand',
    subtitleBanner4msg3: 'Contribute to changes',
    partnersTitle: 'Partners',
    partnersLink: 'Show all partners'
  },
  default: {
    titleBanner1: 'Інформаційна платформа для захисників',
    subtitleBanner1:
      'Інформація та безоплатні послуги для військовослужбовців, ветеранів та їхніх родин в єдиному місці',
    linkBanner1: 'Перейти до послуг',
    roadmapTitle: 'Почни з трьох простих кроків',
    roadmapMsg1: 'Знайомся з платформою',
    roadmapMsg2: 'Обирай послугу',
    roadmapMsg3: 'Використовуй можливості',
    projectValuesTitle: 'Наші цінності',
    projectValue1Title: 'Доступність',
    projectValue1Msg:
      "Жодних ускладнень, взаємодія з будь-якими елементами зрозуміла на кожному етапі. Фокусуємось на розв'язання конкретних проблем використовуючи прості рішення",
    projectValue2Title: 'Ефективність',
    projectValue2Msg:
      'Якщо створювати інформаційну платформу, то тільки ту, яка стане еталоном дієвих цифрових рішень від держави для захисників та захисниць',
    projectValue3Title: 'Повага',
    projectValue3Msg:
      'Лише завдяки повазі можливо побудувати гармонійні відносини в суспільстві, які стимулюватимуть розвиток та відбудову незалежної України',
    titleBanner2: 'Платформа створена для допомоги захисникам',
    subtitleBanner2:
      "Обрій - це інформаційна платформа від Міністерства оборони України, яка надасть можливість захисникам, захисницям та їхнім сім'ям отримати доступ до освіти, нової професії, успішної реабілітації, юридичної допомоги та дізнаватись про унікальні пропозиції в єдиному місці",
    servicesCard3Link1: 'Матеріали для покращення психічного здоров’я',
    servicesCard4Link1: 'Центри протезування та соціальна підтримка',
    servicesCard5Link1: 'Корисні юридичні матеріали та послуги',
    servicesCard6Link1: 'Сім’я, протезування, бізнес та інше',
    titleBanner3: 'Створено захисниками для захисників',
    subtitleBanner3:
      'Міністерство оборони України сформувало команду з військовослужбовців, які мали попередній досвід у сфері інформаційних технологій, а зараз реалізовують свої навички, щоб створити платформу Обрій для побратимів, посестер та їхніх сімей',
    linkBanner3: 'Залишити відгук',
    customersTitle1: 'Створено',
    customersTitle2: 'за ініціативи',
    titleBanner4: "Об'єднуємо державу, громадські організації та бізнес заради допомоги захисникам",
    subtitleBanner4msg1: 'Взаємодійте з унікальною аудиторією',
    subtitleBanner4msg2: 'Будуйте соціально відповідальний бренд',
    subtitleBanner4msg3: 'Будьте дотичними до змінотворчих процесів',
    partnersTitle: 'Партнери',
    partnersLink: 'Показати всіх партнерів'
  }
};
