import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';
import qs from 'qs';

const servicePropositionsApiUrl = '/ServicePropositions';
const serviceApiUrl = '/Services';
const serviceCategoriesApiUrl = '/Services/categories';
const serviceTagsApiUrl = `${serviceApiUrl}/tags`;

const ApiUrls = {
  serviceApiUrl,
  serviceCategoriesApiUrl,
  servicePropositionsApiUrl,
  serviceTagsApiUrl
};

const PostServicePropositionRequest = (data) => obriyApiAxios.post(servicePropositionsApiUrl, data);

function GetServicesCategories() {
  return obriyApiAxios.get(serviceCategoriesApiUrl).then((response) => response.data);
}

function GetServicesTags(query = null) {
  return obriyApiAxios
    .get(serviceTagsApiUrl, {
      params: query,
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { allowDots: true });
        }
      }
    })
    .then((response) => response.data);
}

function GetServicesBlocksRequest(page = 1, query = null) {
  return obriyApiAxios
    .get(serviceApiUrl, {
      params: { ...query, page },
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { allowDots: true });
        }
      }
    })
    .then((value) => value.data);
}

const API = {
  proposeService: PostServicePropositionRequest,
  getServicesBlocks: GetServicesBlocksRequest,
  getCategories: GetServicesCategories,
  getTags: GetServicesTags
};

export { ApiUrls };
export default API;
