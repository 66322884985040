import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function BezoplatneOderzhanniaMedykamentivZaRetseptamyLikarivTaPershocherhoveZuboprotezuvannia() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>
        Безоплатне одержання медикаментів за рецептами лікарів та першочергове зубопротезування (за винятком
        протезування з дорогоцінних металів)
      </h3>
      <p>
        Розмір пільг для учасників війни та осіб, які мають особливі заслуги перед Батьківщиною, визначається залежно
        від середньомісячного сукупного доходу сім&apos;ї.
      </p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>
        Учасники війни та особи, які мають особливі заслуги перед Батьківщиною, для отримання пільг звертаються до
        Пенсійного фонду, інші пільговики – до медзакладів.
      </p>
      <p>
        Пенсійний фонд в 5-денний строк отримує відомості про доходи за запитом від ДПС, про соцдопомогу – від
        структурних підрозділів соціального захисту.
      </p>
      <p>Пільговики отримують довідку, яка протягом року дає право на пільги залежно від доходу сім&apos;ї.</p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>
        Учасник війни, учасник бойових дій, особа з інвалідністю внаслідок війни, сім&apos;ї померлих (загиблих)
        ветеранів війни, захисників і захисниць України, постраждалий учасник Революції Гідності, особа з особливими
        заслугами перед Батьківщиною, ветерани силових структур.
      </p>
    </Article>
  );
}
