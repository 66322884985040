import React, { useContext } from 'react';
import { LanguageSettingsContext } from 'src/utils/language-context';
import AccessibilitySettings from './accessibility/accessibility-settings';
import css from './bar.module.scss';

function Bar() {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <div className={css.bar}>
      <div className={css.container}>
        <div className={css.bump} />
        <div className={css.testModeMessageContainer}>
          <div className={css.testModeMessage}>
            <p>{msg('testModeMsg')}</p>
          </div>
        </div>
        <div className={css.accessibilitySettingsContainer}>
          <AccessibilitySettings />
        </div>
      </div>
    </div>
  );
}

export default Bar;
