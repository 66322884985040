import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';
import { ReactComponent as RouteIcon } from 'src/assets/map/route.svg';
import { ReactComponent as DiscountIcon } from 'src/assets/map/discount.svg';
import { ReactComponent as RequirementsIcon } from 'src/assets/map/requirements.svg';
import { ReactComponent as UrlIcon } from 'src/assets/map/url.svg';
import { ReactComponent as PhoneIcon } from 'src/assets/map/phone.svg';
import css from './marker-popup.module.scss';
import classNames from 'classnames';
import Complaint from 'src/pages/complaint/complaint';
import complaintCss from './complaint.module.scss';
import { LanguageSettingsContext } from 'src/utils/language-context';

MarkerPopup.propTypes = {
  data: PropTypes.shape({
    coords: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }).isRequired,
    category: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired,
    requirements: PropTypes.string.isRequired,
    url: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  category: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired
};

export default function MarkerPopup({ data, category }) {
  const { msg } = useContext(LanguageSettingsContext);
  const popupRef = useRef();

  return (
    <Popup className={css.markerPopup} closeButton={false} keepInView ref={popupRef} closeOnClick>
      <Complaint
        key={data.coords.x + data.coords.y}
        targetTitle={data.title + `(${data.coords.x}, ${data.coords.y})`}
        triggerVisible
        className={complaintCss.businessMapComplaint}
        wrapper={(complaint, onMouseEnter, onMouseLeave) => (
          <div className={css.popupHeader} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {complaint}
            <span onClick={() => popupRef.current.close()}>×</span>
          </div>
        )}
      />
      <div className={css.popupContentContainer}>
        <h1>{data.title}</h1>
        <p>{msg(category.title)}</p>
        <a
          href={`https://www.google.com/maps/dir/Current+Location/${data.coords.x},${data.coords.y}/@${data.coords.x},${data.coords.y},17z`}
          target='_blank'
          rel='noopener, noreferrer'>
          <RouteIcon />
          <p>{msg('mapPageMarkerRoute')}</p>
        </a>
        <div className={css.group}>
          <DiscountIcon />
          <p>
            {msg('mapPageMarkerDiscount')}: {data.discount}
          </p>
        </div>
        <div className={css.group}>
          <RequirementsIcon />
          <span>
            <p>{msg('mapRequirementsPlaceholder')}:&nbsp;</p>
            <p>{data.requirements}</p>
          </span>
        </div>
        {data.url && (
          <div className={classNames(css.group, css.clickable)}>
            <UrlIcon />
            <a href={data.url} target='_blank' rel='noopener, noreferrer'>
              {data.url}
            </a>
          </div>
        )}
        {data.phone && (
          <div className={classNames(css.group, css.clickable)}>
            <PhoneIcon />
            <a href={'tel:' + data.phone.replace(/\s/g, '')} className={css.phone}>
              {data.phone}
            </a>
          </div>
        )}
      </div>
    </Popup>
  );
}
