import React, { useContext, useEffect, useRef, useState } from 'react';
import css from './business-map.module.scss';
import { LayersControl, MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-minimap/dist/Control.MiniMap.min.css';
import API from './api';
import PageHeader from 'src/components/page-header/page-header';
import { BUSINESS_MAP } from 'src/constants/page-classnames';
import Marker from './marker';
import { CATEGORIES } from 'src/constants/business-map-categories';
import 'leaflet.markercluster';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import MiniMap from 'leaflet-minimap';
import MapFilters from './filters';
import { ReactComponent as FullscreenOpen } from 'src/assets/map/fullscreen-open.svg';
import { ReactComponent as FullscreenClose } from 'src/assets/map/fullscreen-close.svg';
import classNames from 'classnames';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function BusinessMap() {
  const { msg } = useContext(LanguageSettingsContext);
  const [fullscreen, setFullscreen] = useState(false);
  const [groupedMapData, setGroupedMapData] = useState([]);
  const [searchData, setSearchData] = useState({
    filters: {
      text: ''
    }
  });

  useEffect(() => {
    const data = {
      text: searchData.filters.text
    };

    API.getBusinessMap(data).then((data) => {
      const groupedByCategory = data.reduce((rv, x) => {
        (rv[x.category] = rv[x.category] || []).push(x);
        return rv;
      }, {});

      setGroupedMapData(groupedByCategory);
    });

    return () => {
      const overlay = document.getElementById('complaint-overlay');
      if (overlay) {
        overlay.remove();
      }
    };
  }, [searchData]);

  const westBound = [48.468301, 19.622063];
  const northBound = [60.361835, 36.290689];
  const eastBound = [49.247834, 43.507104];
  const southBound = [41.817795, 32.486602];
  const center = [48.383022, 31.1828699];

  const tileUrl = 'https://tiles.openstreetmap.org.ua/osm/{z}/{x}/{y}.png';
  const attribution =
    'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://opendatacommons.org/licenses/odbl/">ODbL</a>';

  const mapRef = useRef(null);

  const setRef = (node) => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }

    if (!mapRef.current && node) {
      mapRef.current = node;

      const layer = new L.TileLayer(tileUrl, {
        minZoom: 0,
        maxZoom: 13,
        attribution: attribution
      });
      const miniMap = new MiniMap(layer, { toggleDisplay: true });
      miniMap.addTo(node);
    }
  };

  function handleFiltersChange(filters) {
    setSearchData({ ...searchData, filters: { ...searchData.filters, ...filters } });
  }

  function onFullScreen() {
    setFullscreen((fullscreen) => !fullscreen);
    window.scrollTo(0, 0);
  }

  return (
    <main className={classNames(css.businessMap, fullscreen ? css.fullscreen : '')}>
      {!fullscreen && (
        <PageHeader className={BUSINESS_MAP} title={msg('mapPageTitle')} description={msg('mapPageSubtitle')}>
          <MapFilters initialFilters={searchData.filters} onSearch={handleFiltersChange} />
        </PageHeader>
      )}
      <div className={css.container}>
        <MapContainer
          ref={setRef}
          center={center}
          zoom={6}
          minZoom={6}
          maxZoom={18}
          maxBounds={[westBound, northBound, eastBound, southBound]}
          scrollWheelZoom
          zoomControl={false}
          className={css.map}>
          <TileLayer attribution={attribution} url={tileUrl} />
          <LayersControl position='topright'>
            {Object.keys(groupedMapData).map((key) => {
              const category = CATEGORIES.find((c) => c.id === parseInt(key));

              return (
                <LayersControl.Overlay key={category.id} name={msg(category.layerCaption)} checked>
                  <MarkerClusterGroup
                    chunkedLoading
                    showCoverageOnHover={false}
                    removeOutsideVisibleBounds
                    iconCreateFunction={(cluster) => {
                      const childCount = cluster.getChildCount();
                      let c = ' marker-cluster-';
                      if (childCount < 10) {
                        c += 'small';
                      } else if (childCount < 100) {
                        c += 'medium';
                      } else {
                        c += 'large';
                      }

                      return new L.divIcon({
                        html: `<div><span>${childCount}</span></div>`,
                        className: `category-${category.id} marker-cluster${c}`,
                        iconSize: new L.Point(40, 40)
                      });
                    }}>
                    {groupedMapData[key].map((d, i) => (
                      <Marker
                        key={i}
                        data={d}
                        category={category}
                        onPopupOpen={() => mapRef.current?.setMaxBounds(null)}
                        onPopupClose={() =>
                          mapRef.current?.setMaxBounds([westBound, northBound, eastBound, southBound])
                        }
                      />
                    ))}
                  </MarkerClusterGroup>
                </LayersControl.Overlay>
              );
            })}
          </LayersControl>
          <div className={css.fullscreenToggle} onClick={onFullScreen}>
            {fullscreen && <FullscreenClose />}
            {!fullscreen && <FullscreenOpen />}
          </div>
          <ZoomControl position='topleft' zoomInText='' zoomOutText='' />
        </MapContainer>
      </div>
    </main>
  );
}
