import React from 'react';
import PropTypes from 'prop-types';
import css from './service-card.module.scss';
import classNames from 'classnames';
import * as p from 'src/constants/page-classnames';
import Description from 'src/components/description-block/description';
import Complaint from 'src/pages/complaint/complaint';

ServiceCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string,
    imgUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string
  }),
  className: PropTypes.oneOf([
    p.CAREER_INFO,
    p.EDUCATION,
    p.PSYCHOLOGICAL_HELP,
    p.MEDICAL_SERVICES,
    p.LEGAL_HELP,
    p.USEFUL_INFO,
    p.VETERAN_LOCATIONS
  ]).isRequired
};

export default function ServiceCard({ onClick, service, className }) {
  const showTitleInHeader = !service.imgUrl;

  function handleServiceClick() {
    onClick({ title: service.title, url: service.url });
  }

  return (
    <Complaint
      key={service.id}
      targetTitle={service.title}
      wrapper={(complaint, onMouseEnter, onMouseLeave) => (
        <div
          id={service.id}
          className={classNames(css.card, css[className])}
          onClick={handleServiceClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          {complaint}
          <div className={css.header}>
            {showTitleInHeader && <div className={css.title}>{service.title}</div>}
            {!showTitleInHeader && <img src={service.imgUrl} alt={service.title} />}
          </div>
          <div className={css.content}>
            {!showTitleInHeader && <div className={css.title}>{service.title}</div>}
            <Description description={service.description} />
          </div>
        </div>
      )}
    />
  );
}
