import { messages as gpMsgs } from 'src/pages/general/messages';
import { messages as layoutMsgs } from 'src/components/layout/messages';
import { messages as phMsgs } from 'src/components/page-header/messages';
import { messages as servicesMsgs } from 'src/pages/services/messages';
import { messages as partnerMsgs } from 'src/pages/partnership/messages';
import { messages as feedbackMsgs } from 'src/pages/feedback/messages';
import { messages as eventsMsgs } from 'src/pages/events/messages';
import { messages as gsMsgs } from 'src/pages/global-search/messages';
import { messages as complaintMsgs } from 'src/pages/complaint/messages';
import l from 'lodash';

export const messages = l.merge(
  {
    en: {
      testModeMsg: 'The platform works in test mode',
      accessibilityMsg1: 'Accessibility settings',
      accessibilityMsg2: 'Standard version',
      articleBack: 'back',
      pageNotFoundTitle: 'The page you are trying to find is not available',
      pageNotFoundSubTitle: 'Please make sure the URL is entered correctly or go to the',
      pageNotFoundLink: 'Home page',
      disclaimerWarningMsg: (
        <p>
          Obrii is an information aggregator and is not responsible for the final provision of services. If you notice
          an error, please report it by clicking the “<strong>three dots</strong>” and “<strong>report an error</strong>
          ” icons
        </p>
      ),
      diclaimerCloseMsg: 'Close Context Window',
      popupFiltersMsg: 'Filters',
      popupApplyMsg: 'Apply',
      popupClearMsg: 'Clear',
      dateTimePickerLabel: 'Date and time of the event',
      locationInputPlaceholder: 'All locations',
      globalSearchPlaceholder: 'Search',
      generalRoute: 'General',
      partnershipRoute: 'View partners',
      feedbackPageTitle: 'Leave your feedback',
      confidentialRoute: '',
      globalSearchRoute: '',
      servicesTitle: 'Services',
      workPageTitle: 'Work',
      educationPageTitle: 'Education',
      workSearchPageTitle: 'Search for vacancies',
      careerMaterialsPageTitle: 'Career materials',
      educationCoursesPageTitle: 'Catalogue of courses',
      educationMaterialsPageTitle: 'Educational materials',
      psychologicalHelpPageTitle: 'Psychological support',
      medicalServicesPageTitle: 'Healthcare services',
      legalHelpPageTitle: 'Legal support',
      usefulInfoPageTitle: 'Useful information',
      businessMapPageTitle: 'Map',
      veteranLocationsPageTitle: 'Veteran locations',
      eventsPageTitle: 'Events',
      formName: 'Name',
      tagAllCaption: 'All',
      formPrivacyWarningMsg1: 'The data you leave on the site is strictly confidential',
      formPrivacyWarningMsg2: 'By clicking the ‘Send’ button, you consent to the processing of personal data',
      formSubmitCaption: 'Send',
      formPhonePlaceholder: 'Phone',
      formEmailPlaceholder: 'Email',
      formValidationTitleMsg1: 'name',
      formValidationTitleMsg2: 'name',
      formValidationDiscountMsg: 'discount',
      formValidationRequirements: 'necessary documents',
      formValidationAddress: 'address',
      formValidationDescriptionMsg1: 'comment',
      formValidationRequired: 'mandatory',
      formValidationDescriptionMsg2: 'description',
      formValidationHeader: 'subject',
      formValidationDescriptionMsg3: 'text',
      formValidationCompany: 'company',
      formValidationPrefixMsg1: 'Please enter',
      formValidationLengthMsg1: 'length of up to',
      formValidationLengthMsg2: 'characters',
      formValidationTextMsg: 'a query',
      formValidationUrlMsg1: 'a link',
      formValidationUrlMsg2: 'The specified link does not conform to the link format',
      formValidationEmailMsg1: 'an email address',
      formValidationEmailMsg2: 'The specified email address is not valid',
      formValidationPhone: 'The specified phone number does not correspond to the format',
      formValidationDateTime: 'The specified date and time do not correspond to the format dd.mm.yyyy',
      submitSuccess: 'The moderator will process your request',
      submitFail: 'An error occurred. Please try again later.'
    },
    default: {
      testModeMsg: 'Платформа працює в тестовому режимі',
      accessibilityMsg1: 'Людям із порушеннями зору',
      accessibilityMsg2: 'Стандартна версія',
      articleBack: 'назад',
      pageNotFoundTitle: 'Отакої! Сторінка, яку ти намагаєшся знайти, недоступна',
      pageNotFoundSubTitle: 'Будь ласка переконайся, що URL введено правильно або перейди на',
      pageNotFoundLink: 'Домашню сторінку',
      disclaimerWarningMsg: (
        <p>
          Обрій є агрегатором інформації та не несе відповідальність за кінцевого надавача послуг. Якщо Ви помітили
          помилку, будь ласка повідомте про неї по натисканню іконки “<strong>три крапки</strong>” та “
          <strong>повідомити про помилку</strong>”
        </p>
      ),
      diclaimerCloseMsg: 'Зрозуміло, дякую',
      popupFiltersMsg: 'Фільтри',
      popupApplyMsg: 'Застосувати',
      popupClearMsg: 'Скинути',
      locationInputPlaceholder: 'Всі локації',
      dateTimePickerLabel: 'Дата і час події',
      globalSearchPlaceholder: 'Пошук',
      generalRoute: 'Головна',
      partnershipRoute: 'Переглянути партнерів',
      feedbackPageTitle: 'Залиш свій відгук',
      confidentialRoute: 'Положення про конфіденційність',
      globalSearchRoute: 'Глобальний пошук',
      servicesTitle: 'Послуги',
      workPageTitle: 'Робота',
      educationPageTitle: 'Освіта',
      workSearchPageTitle: 'Пошук вакансій',
      careerMaterialsPageTitle: 'Кар’єрні матеріали',
      educationCoursesPageTitle: 'Каталог курсів',
      educationMaterialsPageTitle: 'Освітні матеріали',
      psychologicalHelpPageTitle: 'Психологічна підтримка',
      medicalServicesPageTitle: 'Медичні послуги',
      legalHelpPageTitle: 'Юридична допомога',
      usefulInfoPageTitle: 'Корисна інформація',
      businessMapPageTitle: 'Мапа',
      veteranLocationsPageTitle: 'Ветеранські локації',
      eventsPageTitle: 'Події',
      formName: "Ім'я",
      tagAllCaption: 'Усі',
      formPrivacyWarningMsg1: 'Дані, які ви залишаєте на сайті є суворо конфіденційними',
      formPrivacyWarningMsg2: 'Натискаючи кнопку ‘Надіслати’, Ви даєте згоду на обробку персональних даних',
      formSubmitCaption: 'Надіслати',
      formPhonePlaceholder: 'Контактний тел.',
      formEmailPlaceholder: 'Ел. пошта',
      formValidationTitleMsg1: 'назву',
      formValidationTitleMsg2: "ім'я",
      formValidationDiscountMsg: 'знижку',
      formValidationRequirements: 'необхідні документи',
      formValidationAddress: 'адресу',
      formValidationDescriptionMsg1: 'коментар',
      formValidationRequired: "обов'язково",
      formValidationDescriptionMsg2: 'опис',
      formValidationHeader: 'тема листа',
      formValidationDescriptionMsg3: 'текст',
      formValidationCompany: 'назву компанії',
      formValidationPrefixMsg1: 'Будь ласка, введіть',
      formValidationLengthMsg1: 'довжиною до',
      formValidationLengthMsg2: 'символів',
      formValidationTextMsg: 'запит',
      formValidationUrlMsg1: 'посилання',
      formValidationUrlMsg2: 'Вказане посилання не відповідає формату посилань',
      formValidationEmailMsg1: 'електронну адресу',
      formValidationEmailMsg2: 'Вказана електронна адреса не є вірною',
      formValidationPhone: 'Вказаний номер телефону не відповідає формату',
      formValidationDateTime: 'Вказані дата та час не відповідають формату дд.мм.рррр',
      submitSuccess: 'Модератор опрацює Ваш запит',
      submitFail: 'Виникла помилка. Спробуйте, будь ласка, пізніше.'
    }
  },
  gpMsgs,
  layoutMsgs,
  phMsgs,
  servicesMsgs,
  partnerMsgs,
  feedbackMsgs,
  eventsMsgs,
  gsMsgs,
  complaintMsgs
);
