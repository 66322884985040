import React, { useContext, useId, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'src/components/form/floating-label/input';
import { ReactComponent as WarningIcon } from 'src/assets/warning-icon.svg';
import { useOutsideClickTracker } from 'src/hooks/shared-hooks';
import { env } from 'src/constants/env';
import css from './complaint.module.scss';
import API from './api';
import { createPortal } from 'react-dom';
import { LanguageSettingsContext } from 'src/utils/language-context';

Complaint.propTypes = {
  targetTitle: PropTypes.string.isRequired,
  alignRight: PropTypes.bool,
  triggerVisible: PropTypes.bool,
  className: PropTypes.string,
  wrapper: PropTypes.func
};

export default function Complaint({ targetTitle, alignRight = false, triggerVisible, className, wrapper }) {
  const { msg } = useContext(LanguageSettingsContext);
  const complaintTypes = [
    { value: 'Граматична помилка', label: msg('complaintTypeMsg1') },
    { value: 'Дані не відповідають дійсності', label: msg('complaintTypeMsg2') },
    { value: 'Інше', label: msg('complaintTypeMsg3') }
  ];
  const parentRef = useRef(null);
  const [showComplaint, setShowComplaint] = useState(false);
  const [showDescriptionInput, setShowDescriptionInput] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const descriptionInputId = useId();
  const captchaRef = useRef(null);
  const containerRef = useRef(null);
  const validationSchema = yup.object({
    description: yup
      .string()
      .trim()
      .required(msg('formValidationRequired'))
      .max(
        2000,
        `${msg('formValidationPrefixMsg1')} ${msg('formValidationDescriptionMsg2')} ${msg(
          'formValidationLengthMsg1'
        )} 2000 ${msg('formValidationLengthMsg2')}.`
      )
  });

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      complaintType: '',
      description: ''
    }
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = methods;
  const watchedDescription = watch('description');
  const [hover, setHover] = useState(false);
  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  const registerAndClearOnChange = function (inputName) {
    return register(inputName, {
      onChange: () => {
        if (errors[inputName]) {
          clearErrors(inputName);
        }
      }
    });
  };

  useOutsideClickTracker(containerRef, () => {
    if (!showPopup || watchedDescription?.length !== 0) return;

    handleClose();
  });

  function handleComplaintShowed(isOpen) {
    if (isOpen) {
      const overlay = document.createElement('div');
      overlay.id = 'complaint-overlay';
      document.body.appendChild(overlay);
      document.body.style.overflowY = 'hidden';
      const portalRoot = document.getElementById('complaint-popup');
      portalRoot.style.zIndex = 1002;
      const topOffset = 60;
      window.scrollTo({
        top: window.scrollY + parentRef.current.getBoundingClientRect().top - topOffset,
        behavior: 'instant'
      });
      return;
    }

    document.body.style.overflowY = 'auto';
    const overlay = document.getElementById('complaint-overlay');
    if (overlay) {
      overlay.remove();
    }
  }

  function handleClose() {
    setShowPopup(false);
    handleComplaintShowed(false);
    setShowDescriptionInput(false);
    setShowComplaint(false);
    reset();
    const scrollArrow = document.getElementById('scroll-arrow');
    if (scrollArrow) {
      scrollArrow.style.display = 'flex';
    }
  }

  function handleComplaintSubmit(data) {
    API.complaint({
      pageUrl: window.location.href,
      description: data.description,
      complaintType: data.complaintType,
      captcha: data.captcha,
      targetTitle
    })
      .then(() => {
        handleClose();
        toast.success(msg('complaintSuccess'));
      })
      .catch(() => toast.error(msg('complaintFail')));
  }

  const complaintNode = (
    <div
      className={classNames(
        css.complaint,
        alignRight ? css.alignRight : '',
        showPopup ? css.visible : css.hidden,
        showComplaint ? css.fullScreen : ''
      )}
      onClick={(e) => e.stopPropagation()}
      ref={containerRef}>
      {!showComplaint && (
        <button
          type='button'
          onClick={() => {
            handleComplaintShowed(!showComplaint);
            setShowComplaint((isOpen) => !isOpen);
            const scrollArrow = document.getElementById('scroll-arrow');
            if (scrollArrow) {
              scrollArrow.style.display = 'none';
            }
          }}>
          <WarningIcon />
          <p>{msg('complaintReportError')}</p>
        </button>
      )}
      {showComplaint && (
        <div className={css.container}>
          <button className={css.close} onClick={handleClose}>
            &times;
          </button>
          <div className={css.complaintGroup}>
            <h1>{msg('complaintReportError')}</h1>
            {!showDescriptionInput && (
              <div className={css.group}>
                {complaintTypes.map((complaintType, i) => (
                  <button
                    key={i}
                    type='button'
                    onClick={() => {
                      setValue('complaintType', complaintType.value);
                      setShowDescriptionInput(true);
                    }}>
                    {complaintType.label}
                  </button>
                ))}
              </div>
            )}
            {showDescriptionInput && (
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(async (data) => {
                    const token = await captchaRef.current?.executeAsync();
                    handleComplaintSubmit({ ...data, captcha: token });
                  })}
                  className={css.form}
                  autoComplete='off'>
                  <div className={css.group}>
                    <Input
                      id={descriptionInputId}
                      label={msg('complaintDescriptionPlaceholder')}
                      error={errors?.description?.message}
                      multiline
                      required
                      {...registerAndClearOnChange('description')}
                    />
                    <button type='submit' className={css.submit}>
                      {msg('formSubmitCaption')}
                    </button>
                  </div>
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      )}
    </div>
  );

  function getComplaint() {
    if (!showPopup) return null;

    const rect = parentRef.current.getBoundingClientRect();
    return createPortal(
      <div id='complaint-popup'>
        <div
          style={{
            width: `${rect.width}px`,
            top: `${window.scrollY + rect.top}px`,
            left: `${rect.left}px`
          }}>
          {complaintNode}
        </div>
        {showDescriptionInput && (
          <ReCAPTCHA
            ref={captchaRef}
            className={css.captcha}
            sitekey={env.REACT_APP_OBRII_RECAPTCHA_SITE_KEY}
            hl='uk'
            size='invisible'
          />
        )}
      </div>,
      document.body
    );
  }

  const complaint = (
    <div className={classNames(css.complaintContainer, hover ? css.hover : '', className ?? '')} ref={parentRef}>
      <div
        className={classNames(css.trigger, showPopup ? css.active : '', triggerVisible ? css.visible : '')}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopup(true);
        }}>
        <span className={css.dot} />
        <span className={css.dot} />
        <span className={css.dot} />
      </div>
      {getComplaint()}
    </div>
  );

  return wrapper(complaint, onMouseEnter, onMouseLeave);
}
