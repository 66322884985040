const WEEK_DAYS = [
  'weekDayShort0',
  'weekDayShort1',
  'weekDayShort2',
  'weekDayShort3',
  'weekDayShort4',
  'weekDayShort5',
  'weekDayShort6'
];
const FULL_WEEK_DAYS = [
  'weekDayFull6',
  'weekDayFull0',
  'weekDayFull1',
  'weekDayFull2',
  'weekDayFull3',
  'weekDayFull4',
  'weekDayFull5'
];

const MONTHS = [
  'month0',
  'month1',
  'month2',
  'month3',
  'month4',
  'month5',
  'month6',
  'month7',
  'month8',
  'month9',
  'month10',
  'month11'
];

const MONTHS_G_CASE = [
  'monthGcase0',
  'monthGcase1',
  'monthGcase2',
  'monthGcase3',
  'monthGcase4',
  'monthGcase5',
  'monthGcase6',
  'monthGcase7',
  'monthGcase8',
  'monthGcase9',
  'monthGcase10',
  'monthGcase11'
];

export { WEEK_DAYS, FULL_WEEK_DAYS, MONTHS, MONTHS_G_CASE };
