import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { fireEvent } from 'src/utils/analytics/google';
import SearchForm from './form/form';
import PageHeader from 'src/components/page-header/page-header';
import PaginatedFooter from 'src/components/paginated-footer/paginated-footer';
import Cards from 'src/components/form/search/cards/cards';
import Course from './course';
import EmptyPlaceholder from './empty-placeholder';
import * as p from 'src/constants/page-classnames';
import css from './courses.module.scss';
import API from './api/api';

export default function Education() {
  const { msg, language } = useContext(LanguageSettingsContext);
  const state = useLocation().state;
  const skeletons = [...Array(6).keys()].map((i) => <span key={i} className={css.skeleton} />);
  const [filterValues, setFilterValues] = useState({ text: state?.text ?? '', vendor: '' });
  const [selectedPage, setSelectedPage] = useState(1);
  const [courses, isLoading] = useCourses(filterValues, selectedPage, language);

  const hidePaginatedFooter =
    courses.items.length === 0 || courses.totalCount === 0 || courses.items.length === courses.totalCount;

  function handleFilterValuesChange(data) {
    setFilterValues({ ...filterValues, ...data });
    setSelectedPage(1);
  }

  return (
    <main className={css.courses}>
      <PageHeader
        className={p.EDUCATION}
        title={msg('educationCoursesPageTitle')}
        description={msg('educationCoursesPageSubtitle')}>
        <div className={css.header}>
          <SearchForm initialValues={filterValues} onSubmit={handleFilterValuesChange} />
        </div>
      </PageHeader>
      <div className={css.body}>
        <Cards className={css.list} loading={isLoading}>
          {!isLoading && courses.items.length === 0 && <EmptyPlaceholder />}
          {courses.items.map((course) => (
            <Course key={course.id} course={course} />
          ))}
          {isLoading && skeletons}
          {!hidePaginatedFooter && (
            <div className={css.pagerRow}>
              <PaginatedFooter
                caption={msg('educationCoursePagePaginationMsg')}
                onClick={() => setSelectedPage(selectedPage + 1)}
              />
            </div>
          )}
        </Cards>
      </div>
    </main>
  );
}

function useCourses(filters, selectedPage, language) {
  const [courses, setCourses] = useState({
    totalCount: 0,
    items: []
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fireEvent('education_form_submit', { text: filters.text });
    API.search(selectedPage, { text: filters.text, vendor: filters.vendor })
      .then((response) => {
        setCourses((prev) => {
          if (selectedPage > 1) {
            const ids = new Set(prev.items.map((v) => v.id));
            return {
              totalCount: response.totalCount,
              items: [...prev.items, ...response.items.filter((v) => !ids.has(v.id))]
            };
          }

          return response;
        });
      })
      .finally(() => setIsLoading(false));
  }, [filters, selectedPage, language]);

  return [courses, isLoading];
}
