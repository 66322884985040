import { obriyApiAxios } from 'src/utils/obriy-axios/obriy-axios';

const becomePartnerApiUrl = '/BecomePartner';

const PostBecomePartnerRequest = (data) => obriyApiAxios.post(becomePartnerApiUrl, data);

const API = {
  becomePartner: PostBecomePartnerRequest
};

export default API;
