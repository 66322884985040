import React from 'react';
import PropTypes from 'prop-types';
import css from './search-input.module.scss';
import { ReactComponent as SearchIcon } from 'src/assets/search_icon.svg';

SearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputProps: PropTypes.object.isRequired,
  error: PropTypes.string
};

export default function SearchInput(props) {
  return (
    <div className={css.inputGroup}>
      <div className={css.container}>
        <SearchIcon className={css.searchIcon} />
        <input placeholder={props.placeholder} type='search' id={props.id} name={props.name} {...props.inputProps} />
      </div>
      <label className={css.errorMessage} htmlFor={props.id}>
        {props.error}
      </label>
    </div>
  );
}
