import React, { useContext, useId } from 'react';
import PropTypes from 'prop-types';
import Popup from 'src/components/form/search/filters-popup/popup';
import CheckboxGroup from 'src/components/form/search/filters-popup/checkbox-group';
import { WORK_EMPLOYMENT_TYPE } from 'src/constants/filters';
import SearchInput from 'src/components/form/search/filters-form/search-input';
import InputNumber from 'src/components/form/search/filters-popup/input-number';
import LocationsInput from 'src/components/form/search-select-input/locations-input';
import Filters from 'src/components/form/filters/filters';
import { searchInputValidationSchema } from 'src/components/form/search/filters-form/helper';
import css from './form.module.scss';
import { REMOTE } from 'src/constants/locations';
import * as p from 'src/constants/page-classnames';
import { LanguageSettingsContext } from 'src/utils/language-context';

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onReset: PropTypes.func.isRequired
};

export default function SearchForm({ onSubmit, initialValues, onReset }) {
  const { msg } = useContext(LanguageSettingsContext);
  const locationId = useId();
  const inputId = useId();

  return (
    <Filters
      initialFilters={initialValues}
      onSearch={onSubmit}
      validationSchema={searchInputValidationSchema(msg, 'text')}
      fieldsToSubmitOnChange={['text', 'locationId']}
      render={({ errors, _, registerAndClearOnChange, getValues }) => (
        <div className={css.workInputGroup}>
          <SearchInput
            id={inputId}
            name='text'
            placeholder={msg('searchVacancyPlaceholder')}
            inputProps={registerAndClearOnChange('text')}
            error={errors?.text?.message}
          />
          {!getValues().showMilitaryVacancies && (
            <React.Fragment>
              <div className={css.locationInput}>
                <LocationsInput
                  id={locationId}
                  name='locationId'
                  defaultLocation={REMOTE}
                  placeholder={msg('locationSelectPlaceholder')}
                />
              </div>
              <Popup onReset={onReset} pageName={p.WORK_SEARCH}>
                <CheckboxGroup
                  entities={WORK_EMPLOYMENT_TYPE.map((t) => ({ id: t.id, name: msg(t.name) }))}
                  name='employmentTypes[]'
                  title={msg('employmentTypeFilterTitle')}
                />
                <InputNumber
                  name='salary'
                  title={msg('salaryFilterTitle')}
                  prefix={msg('salaryFilterCurrencyPrefix')}
                  postfix={msg('salaryFilterCurrency')}
                />
              </Popup>
            </React.Fragment>
          )}
        </div>
      )}
    />
  );
}
