import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function OtrymanniaNapravlenniaDoReabilitatsiinykhUstanov() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>Отримання направлення до реабілітаційних установ</h3>
      <p>
        Реабілітацію можна пройти в державних та комунальних закладах під контролем, що перебувають в управлінні
        Мінсоцполітики, а також у приватних установах з державним фінансуванням.
      </p>
      <p>
        Із переліком державних реабілітаційних закладів можна ознайомитися тут. Такий заклад має протягом п’яти днів із
        моменту отримання заяви дати відповідь щодо можливості надання реабілітаційних послуг (із зазначенням дати
        початку курсу реабілітації) в порядку черговості.
      </p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>
        Подати заяву до структурного підрозділу з питань соціального захисту населення (перевіряє заяву протягом трьох
        днів, реєструє її, створює електронну картку і відправляє запит до реабілітаційного закладу).
      </p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>
        Учасники війни, особи з інвалідністю внаслідок війни, сім&apos;ї померлих (загиблих) захисників і захисниць,
        постраждалі учасники Революції Гідності, вдови і вдівці ветеранів силових структур.
      </p>
    </Article>
  );
}
