import React, { useContext } from 'react';
import Article from 'src/components/article/article';
import { USEFUL_INFO } from 'src/constants/routes';
import { LanguageSettingsContext } from 'src/utils/language-context';

export default function OtrymanniaAvtomobiliaDliaOsibZInvalidnistiu() {
  const { link } = useContext(LanguageSettingsContext);

  return (
    <Article homePath={link(USEFUL_INFO.path)}>
      <h3>
        Отримання направлення на проходження медико-соціальної експертної комісії, щоб стати на облік для забезпечення
        автомобілем (для осіб з інвалідністю)
      </h3>
      <p>Медичні показання для видачі автомобілів особам з інвалідністю встановлює МОЗ.</p>
      <p>Для отримання авто потрібно перебувати на обліку у відділі соціального захисту населення.</p>
      <p>
        ОблМСЕК (ЦМСЕК, КрМСЕК) видає висновок про наявність в особи з інвалідністю медичних показань для забезпечення
        автомобілем і наявність / відсутність протипоказань до керування ним.
      </p>
      <p>
        Незалежно від медичних показань мають право на забезпечення автомобілем особи з інвалідністю внаслідок війни (I
        групи по зору або без обох рук), особи з інвалідністю з куксами обох ніг і рук.
      </p>
      <p>
        Рішення про забезпечення автомобілем ухвалюється протягом трьох днів із дня отримання всіх необхідних
        документів.
      </p>
      <p>
        <b>Як отримати?</b>
      </p>
      <p>Звернутися до органу соціального захисту.</p>
      <p>
        <b>Хто може отримати?</b>
      </p>
      <p>Особи з інвалідністю, які:</p>
      <ul>
        <li>
          Не мають в особистому користуванні авто, що експлуатується менше 10 років (крім випадків викрадення чи
          неможливості відновлення);
        </li>
        <li>Ті, хто має право на автомобіль, але користуються власним;</li>
        <li>
          Не отримували машину як дарунок протягом 7 років перед тим, як стати на облік, або не реєстрували новий
          автомобіль зі строком експлуатації менше 5 років.
        </li>
      </ul>
    </Article>
  );
}
