import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { UKRAINE } from 'src/constants/languages';
import { messages } from 'src/constants/messages';

const LanguageSettingsContext = createContext(null);
const LanguageSettings = function ({ children }) {
  const [language, setLanguage] = useState(UKRAINE);
  const routePrefix = language === UKRAINE ? '' : `/${language}`;

  function switchLanguage(language) {
    setLanguage(language);
  }

  function msg(name) {
    const message = messages[language]?.[name] ?? messages.default?.[name];

    if (message === undefined) throw new Error(`Message for '${name}' not found`);

    return message;
  }

  function link(route) {
    return routePrefix + route;
  }

  return (
    <LanguageSettingsContext.Provider value={{ language, switchLanguage, msg, link }}>
      {children}
    </LanguageSettingsContext.Provider>
  );
};

LanguageSettings.propTypes = {
  children: PropTypes.node.isRequired
};

export { LanguageSettings, LanguageSettingsContext };
