import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import css from './filters-group.module.scss';
import classNames from 'classnames';

CheckboxGroup.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default function CheckboxGroup({ entities, name, title }) {
  const { register } = useFormContext();

  return (
    <div className={classNames(css.filtersGroup, css.checkboxGap)}>
      <span>{title}</span>
      {entities.map((e) => (
        <div key={`${name}.${e.id}`} className={css.filter}>
          <label>
            <input type='checkbox' id={`${name}-${e.id}`} name={name} value={e.id} {...register(name)} />
            {e.name}
          </label>
        </div>
      ))}
    </div>
  );
}
