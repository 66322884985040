export const WORK_SEARCH = 'work',
  CAREER_INFO = 'career',
  EDUCATION = 'education',
  PSYCHOLOGICAL_HELP = 'psych',
  LEGAL_HELP = 'legal',
  USEFUL_INFO = 'info',
  BUSINESS_MAP = 'map',
  VETERAN_LOCATIONS = 'vet',
  EVENTS = 'events',
  MEDICAL_SERVICES = 'med',
  PARTNERSHIP = 'partnership';
