import React, { useContext } from 'react';
import css from './disclaimer.module.scss';
import { ReactComponent as WarningIcon } from 'src/assets/warning-icon.svg';
import Modal from 'src/components/common/modal/modal';
import { LanguageSettingsContext } from 'src/utils/language-context';
import { ReactComponent as Like } from 'src/assets/like.svg';

export default function Disclaimer() {
  const { msg } = useContext(LanguageSettingsContext);

  return (
    <Modal
      isOpen={window.sessionStorage?.getItem('HideDisclaimer') !== 'true'}
      onClose={() => window.sessionStorage?.setItem('HideDisclaimer', 'true')}
      closeOnDocumentClick={false}>
      {(close) => (
        <div className={css.disclaimerContainer}>
          <div className={css.disclaimer}>
            <div className={css.warning}>
              <WarningIcon />
              {msg('disclaimerWarningMsg')}
            </div>
            <button onClick={close}>
              <Like />
              {msg('diclaimerCloseMsg')}
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}
