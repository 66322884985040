import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getNameById } from 'src/utils/helper';
import { WORK_VENDORS } from 'src/constants/filters';
import { ReactComponent as LocationIcon } from 'src/assets/location.svg';
import Card from 'src/components/form/search/cards/card';
import CardBody from 'src/components/form/search/cards/card-body';
import CardFooter from 'src/components/form/search/cards/card-footer';
import css from './vacancy.module.scss';

Vacancy.propTypes = {
  vacancy: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Vacancy({ vacancy, isLoading, onClick }) {
  return (
    <Card className={css.vacancy} onClick={onClick} hoverBorder='left' loading={isLoading}>
      <CardBody className={css.textContainer}>
        <div className={classNames(css.textGroup, css.titleGroup)}>
          <span className={css.title}>{vacancy.title}</span>
        </div>
        <div className={css.textGroup}>
          {(vacancy.salaryFrom || vacancy.salaryTo) && (
            <span className={css.salary}>
              {vacancy.salaryFrom}
              {vacancy.salaryTo ? ` - ${vacancy.salaryTo}` : ''}
            </span>
          )}
          {vacancy.location && (
            <span className={css.location}>
              <LocationIcon /> {vacancy.location}
            </span>
          )}
        </div>
        <span className={css.description}>{vacancy.description}</span>
      </CardBody>
      <CardFooter className={css.logoFooter}>
        <img src={vacancy.logoUrl} alt={getNameById(WORK_VENDORS, vacancy.vendor)} />
        <span>{getNameById(WORK_VENDORS, vacancy.vendor)}</span>
      </CardFooter>
    </Card>
  );
}
