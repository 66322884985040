import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useDebounce } from 'src/hooks/shared-hooks';

Filters.propTypes = {
  initialFilters: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  fieldsToSubmitOnChange: PropTypes.arrayOf(PropTypes.string.isRequired)
};

export default function Filters({ initialFilters, onSearch, render, validationSchema, fieldsToSubmitOnChange }) {
  const defaultValues = useMemo(() => initialFilters, [initialFilters]);
  const previousFilter = usePreviousFilter(initialFilters);
  const debounce = useDebounce();
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const {
    register,
    clearErrors,
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors }
  } = methods;

  const registerAndClearOnChange = function (inputName) {
    return register(inputName, {
      onChange: () => {
        if (errors[inputName]) {
          clearErrors(inputName);
        }
      }
    });
  };

  const handleChange = useCallback(
    (newFilter, name, value) => {
      const previousFilterValue = previousFilter.current;
      previousFilter.current = newFilter;

      if (!fieldsToSubmitOnChange) return;

      if (fieldsToSubmitOnChange.some((vn) => vn === name) && previousFilterValue[name] !== value) {
        debounce(handleSubmit(onSearch));
      }
    },
    [debounce, handleSubmit, onSearch, previousFilter, fieldsToSubmitOnChange]
  );

  useEffect(() => {
    watch((values, { name, value }) => {
      handleChange(values, name, value);
    });
  }, [handleChange, watch]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSearch)}>
        {render({
          errors,
          control,
          registerAndClearOnChange,
          getValues
        })}
      </form>
    </FormProvider>
  );
}

function usePreviousFilter(initialFilter) {
  const previousFilterRef = useRef(initialFilter);
  const initialFilterRef = useRef(initialFilter);

  if (initialFilterRef.current !== initialFilter) {
    previousFilterRef.current = initialFilter;
    initialFilterRef.current = initialFilter;
  }

  return previousFilterRef;
}
