import React from 'react';
import PropTypes from 'prop-types';
import css from './card-footer.module.scss';
import classNames from 'classnames';

CardFooter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default function CardFooter({ children, className }) {
  return (
    <div className={classNames(css.cardFooter, className)}>
      <div className={css.container}>{children}</div>
    </div>
  );
}
