import * as yup from 'yup';
import * as v from 'src/utils/form/validate';

export default function getValidationSchema(msg) {
  const validationSchema = yup.object({
    title: v.validateName(yup, msg, msg('formValidationTitleMsg1'), 128),
    discount: v.validateName(yup, msg, msg('formValidationDiscountMsg'), 64),
    requirements: v.validateName(yup, msg, msg('formValidationRequirements'), 80),
    phone: v.validatePhone(yup, msg, false),
    address: v.validateName(yup, msg, msg('formValidationAddress'), 2000, false),
    url: v.validateUrl(yup, msg, false),
    description: v.validateDescription(yup, msg, msg('formValidationDescriptionMsg1'), 2000, false)
  });

  return validationSchema;
}
